/* eslint-disable */
/// @ts-nocheck -- Bulk rename to enable TypeScript validation

import * as dompack from 'dompack';
import * as dialog from '../../components/dialog';
import './context.lang.json';
import getTid from "@mod-tollium/js/gettid";

import LiveSiteClient from '../livesiteclient';
import ChatPlaneContext from "@mod-live_api/libliveapi/js/chatplanecontext";
import { ensurePlayer } from '../player/playerstructure';

function createChoice(choicetype) {
  if (typeof choicetype != "string")
    return choicetype;

  switch (choicetype) {
    case "yes":
      return { result: "yes", title: getTid("live_api:frontend.js.yes") };
    case "no":
      return { result: "no", title: getTid("live_api:frontend.js.no") };
  }
  throw new Error(`Unrecognized default choice type '${choiectype}'`);
}

/* The Context implements the 'global visitor token' concept
   @param options.dialogtheme Class for dialogs. Defaults to `whlive-dialog--theme-whlive`
*/
export default class Context extends ChatPlaneContext {
  constructor(options) {
    super(options);
    this._dialogtheme = options?.dialogtheme ?? "whlive-dialog--theme-whlive";
    this._dialogcallback = options?.dialogcallback ?? dialog.runMessageBox;
  }

  async fetchJSON(url) {
    return await (await fetch(url)).json();
  }

  _createClient(opts) {
    return new LiveSiteClient(this, opts);
  }

  ensurePlayer(node, opts) {
    return ensurePlayer(node, opts);
  }

  getCommonMessageBoxTexts(type, options) {
    switch (type) {
      case "deletemessage":
        return {
          question: getTid("live_api:frontend.js.chatconfirmation-deletemessage"),
          choices: ['yes', 'no']
        };

      case "banvisitor":
        return {
          question: getTid("live_api:frontend.js.chatconfirmation-banvisitor"),
          choices: ['yes', 'no']
        };

      case "unbanvisitor":
        return {
          question: getTid("live_api:frontend.js.chatconfirmation-unbanvisitor"),
          choices: ['yes', 'no']
        };

      case "externallink":
        return {
          question: getTid("live_api:frontend.js.chatconfirmation-externallink", options.host),
          choices: ['yes', 'no']
        };

      case "closeconversation":
        return {
          question: getTid("live_api:frontend.js.chatconfirmation-closeconversation"),
          choices: ['yes', 'no']
        };

      case "responsivenesscheck":
        return {
          question: getTid("live_api:frontend.js.chatconfirmation-responsivenesscheck"),
          choices: ['yes']
        };

      default:
        throw new Error(`Unrecognized message box type '${type}'`);
    }
  }

  /** @param question - if a string, will be wrapped as textContent into a <p> and presented as the question
                      - if a html node, will appear as the question (allowing you to insert html)
                      - if an array of nodes, all these nodes will be inserted
      @param choices An array of choice objects and/or the string 'yes' or 'no', which will be the buttons this dialog will provide
      @cell choices.title Title for the choice
      @cell choices.result Override result to return if clicked (otherwise you'll just receive the title)
      @cell(boolean) options.allowcancel Allow the dialog to be cancelled by clicking outside the dialog. Defaults to true if no choices are specified
      @cell(object) options.focusonclose Element to focus on closing the dialog
      @cell(string) options.theme Additional class to set on the dialog
  */
  async runMessageBox(question, choices, options) {
    choices = choices.map(createChoice);
    return await this._dialogcallback(question, choices, {
      theme: this._dialogtheme,
      ...options
    });
  }

  async _runCommonMessageBox(type, options) {
    const info = this.getCommonMessageBoxTexts(type, options);
    return await this.runMessageBox(info.question, info.choices, { allowcancel: true, ...options });
  }
}
