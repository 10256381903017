/* eslint-disable */
/// @ts-nocheck -- Bulk rename to enable TypeScript validation

import * as dompack from 'dompack';
import * as bindings from '../util/bindings';
import "./userstatus.scss";

export default class UserStatusHandler extends bindings.BindableBase {
  constructor(livesiteclient, element, options) {
    super(livesiteclient, element);

    this._buildDom();

    this.listeners = [this._livesiteclient.on("userstatus", () => this._gotUserStatusChange())];
    this._refreshStatus();
  }

  _buildDom() {
    this._select =
      <select onchange={evt => this._gotUserStatusSelect(evt)}>
        <option value="cannotaccept">🔴 no new conversations</option>
        <option value="canaccept">🟢 available</option>
      </select>;
    this._element.append(this._select);
  }

  _gotUserStatusChange() {
    this._refreshStatus();
  }

  _gotUserStatusSelect() {
    const newuserstatus =
    {
      ...this._livesiteclient.userstatus,
      canaccept: this._select.value == "canaccept"
    };

    this._livesiteclient.setUserStatus(newuserstatus);
  }

  _refreshStatus() {
    this._updateStatus(this._livesiteclient.userstatus ?? {});
  }

  _updateStatus({ canaccept }) {
    this._select.value = canaccept ? "canaccept" : "cannotaccept";
  }
}
