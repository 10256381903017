/* eslint-disable */
/// @ts-nocheck -- Bulk rename to enable TypeScript validation

import * as dompack from 'dompack';
import getTid from "@mod-tollium/js/gettid";
import ChatFrame from "./chatframe";

function sortByNewest(lhs, rhs) {
  if (lhs.message.createdat > rhs.message.createdat)
    return -1;
  if (lhs.message.createdat < rhs.message.createdat)
    return 1;
  return lhs.message.id < rhs.message.id ? -1 : 1;
}

function sortByUpvotes(lhs, rhs) {
  const lhsvotes = lhs.message.votes ? lhs.message.votes.like : 0;
  const rhsvotes = rhs.message.votes ? rhs.message.votes.like : 0;
  if (lhsvotes != rhsvotes)
    return rhsvotes - lhsvotes;

  return sortByNewest(lhs, rhs);
}

function filterUnanswered(msg) {
  return !(msg.message.replies && msg.message.replies.length);
}

export default class QuestionsChatFrame extends ChatFrame {
  constructor(container, chatbackend, url, visitor, chatroominfoinfo, opts) {
    super(chatbackend, url, visitor, chatroominfoinfo, opts);

    this.buildDom(container);
    this.chatnode.classList.add("whlive-chat--questions");
    this._setSortOrder("newest");
  }

  buildDom(container) {
    super.buildDom(container, { allowstarring: false });

    this.messagearea.before(
      <div class="whlive-chat__tabarea whlive-chat__questiontabs">
        <div class="whlive-chat__tab whlive-chat__questiontab whlive-chat__questiontab__newest" data-tab="newest" onClick={() => this._setSortOrder("newest")}><span class="whlive-chat__tab__text whlive-chat__questiontab__text">{getTid('live_api:frontend.js.chat.tab_newest')}</span></div>
        <div class="whlive-chat__tab whlive-chat__questiontab whlive-chat__questiontab__upvotes" data-tab="upvotes" onClick={() => this._setSortOrder("upvotes")}><span class="whlive-chat__tab__text whlive-chat__questiontab__text">{getTid('live_api:frontend.js.chat.tab_upvotes')}</span></div>
        <div class="whlive-chat__tab whlive-chat__questiontab whlive-chat__questiontab__unanswered" data-tab="unanswered" onClick={() => this._setSortOrder("unanswered")}><span class="whlive-chat__tab__text whlive-chat__questiontab__text">{getTid('live_api:frontend.js.chat.tab_unanswered')}</span></div>
        <div class="whlive-chat__tab whlive-chat__questiontab whlive-chat__questiontab__myquestions" data-tab="myquestions" onClick={() => this._setSortOrder("myquestions")}><span class="whlive-chat__tab__text whlive-chat__questiontab__text">{getTid('live_api:frontend.js.chat.tab_myquestions')}</span></div>
        {this.getModeratorControls()}
      </div>);
  }

  _setSortOrder(neworder) {
    dompack.qSA(this.chatnode, ".whlive-chat__tab").forEach(node => {
      node.classList.toggle("whlive-chat__tab--active", node.dataset.tab == neworder);
      node.classList.toggle("whlive-chat__questiontab--active", node.dataset.tab == neworder);
    });

    this.sortorder = neworder;
    this._applySortOrder();
  }

  _applySortOrder() {
    let bubbles = Array.from(this.bubblemap.values());

    if (this.sortorder == "newest")
      bubbles = bubbles.sort(sortByNewest);
    else if (this.sortorder == "upvotes")
      bubbles = bubbles.sort(sortByUpvotes);
    else if (this.sortorder == "unanswered")
      bubbles = bubbles.filter(filterUnanswered).sort(sortByNewest);
    else if (this.sortorder == "myquestions")
      bubbles = bubbles.filter(msg => msg.message.mine).sort(sortByNewest);

    const currentorder = dompack.qSA(this.nodes.messages, ".whlive-chat__bubble");
    let matchorder = currentorder.length == bubbles.length;
    for (let i = 0; matchorder && i < currentorder.length; ++i)
      matchorder = currentorder[i] == bubbles[i].bubble;

    if (matchorder) //don't bother the DOM
      return;

    bubbles.forEach(bubble => this.nodes.messages.appendChild(bubble.bubble));
    while (this.nodes.messages.firstChild && (!bubbles.length || this.nodes.messages.firstChild != bubbles[0].bubble)) //old node must still present there
      this.nodes.messages.removeChild(this.nodes.messages.firstChild);
  }

  _updatedChatFrame(appended_my_message) {
    this._applySortOrder();
  }

  _buildMessageBubble(message) {
    //Using groupchat bubble as basis, wouldn't be surprised if we need to move them further apart though
    message.replies = this.backend.getMessageReplies(message.id); //FIXME can't chatbackend arrange for this? shouldn't be our problem OR be redone everytime we render?
    const bubblenode =
      <div class={{
        "whlive-chat__bubble": true,
        "whlive-chat__bubble--mine": message.mine,
        "whlive-chat__bubble--hasreply": message.replies.length
      }}>
        <div class="whlive-chat__bubble-original">
          {this._buildBubbleMessage(message, true)}
        </div>
        {message.replies.map(reply => (
          <div class="whlive-chat__bubble-reply">
            {this._buildBubbleMessage(reply)}
          </div>))
        }
      </div>;

    if (message.myvote) //i voted!
      bubblenode.classList.add("whlive-chat__bubble--hasvoted");

    if (message.visitor.screenflags)
      message.visitor.screenflags.forEach(flag => bubblenode.classList.add("whlive-chat__screenflag-" + flag));

    return bubblenode;
  }

  _processEmbeddedChat(type, message) {
    if (message && typeof message.inreplyto == "object") //fix old history/archive format
      message.inreplyto = message.inreplyto.id;

    if (type == "message" && message.inreplyto) {
      //in groupchats, we combine a response into its original node. so redirect the update to the original
      const original = this.backend.getMessageById(message.inreplyto);
      if (!original) {
        console.error(`Cannot process message ${message.id} - cannot find quoted mesage ${message.inreplyto}`);
        return;
      }
      return super._processEmbeddedChat("message", original);
    }
    return super._processEmbeddedChat(type, message);
  }

  _getInputPlaceholder() {
    return this.state.mode == "moderatoronly"
      ? getTid('live_api:frontend.js.chat.inputplaceholder-moderatoronly')
      : getTid('live_api:frontend.js.chat.inputplaceholder-questions');
  }
}

