/* eslint-disable */
/// @ts-nocheck -- Bulk rename to enable TypeScript validation

import * as frame from './frame';
import CueManager from "@mod-live_api/libliveapi/js/cuemanager.mjs";


/* ondemand.es drives the cuemanager using a predefined playlist. it does this
   by using the schedule to generate cues (a local equivalent of what the
   video pubsub channels do for broadcasts)

   conceptually:
   - a connection.es connection downloads the channel information
   - if it sees a schedule, we (VODPlayer) are initialized to transmit this to the cuemanager

   also:
   - we play the schedule on our 'own time'
   - however, a video does not necessarily play in real time. even with no user pauzes and connections being perfect, an
     audio card may not actually play at the 44.1/48 sample rate and slightly slower/faster. The video player should follow
     the actual audio playback timing, and thus *we* should follow the video player for any 'time' calculation.

   TODO: Consider whether we should merge wit the cue manager and build an ScheduledCueManager so that we can internalize the video processing ?
         Also because the VIdeoBinder already negotiates the current cue with the manager (through getCurrentCues) and we need to find video players
         for time calculations */

export class VODPlayer {
  constructor(playlist, cuemgr) {
    this.playlist = {
      ...playlist,
      schedule: []
    };
    this.scheduleidx = 0;
    this._cuemgr = cuemgr;

    // enrich each schedule item with their relative staring time
    let relativestart_ms = 0;
    for (let entry of playlist.schedule) {
      const relativeduration_ms = relativestart_ms + (entry.estimate_ms || 0);
      entry = {
        ...entry,
        relativestart_ms,
        relativeduration_ms
      };

      relativestart_ms = relativeduration_ms;
      this.playlist.schedule.push(entry);
    }

    //the cuemanager needs absolute times.
    //TODO deal with user seeking
    //TODO deal with buffering/preload times (video seeking) requiring recalculation. don't get confused if a video is so short and network slow that the next cue is already appearing
  }

  play() { //TODO once we started playing this should act more like a 'pause' feature
    this._cueScheduledVideo(0);
  }

  _cueScheduledVideo(startat_ms) {
    if (!this.playlist.schedule[this.scheduleidx])
      return;

    //realign playstart based on when we 'should' be playing the curent schedule entry
    //TODO we need to redo our calculations as soon as the video started playing. perhaps we shouldn't even be processing (video)cues as long as the video isn't actually playing?
    this.playstart = Date.now() - (this.playlist.schedule[this.scheduleidx].relativestart_ms - startat_ms);

    const cues = this.playlist.cues.map(cue => ({
      ...cue,
      start: this.playstart + (cue.start || 0)
    }));
    cues.push({
      ...this.playlist.schedule[this.scheduleidx],
      data: {
        ...this.playlist.schedule[this.scheduleidx].data,
        instanceid: `video:${this.scheduleidx}`,
        offset_ms: startat_ms,
        onVideoEnd: () => this._playNextVideo()
      },
      start: this.playstart + (this.playlist.schedule[this.scheduleidx].relativestart_ms - startat_ms)
    });

    this._cuemgr.update(cues); //, { onMainVideoEnd: () => this._playNextVideo() });
  }

  _playNextVideo() {
    if ((this.scheduleidx + 1) >= this.playlist.schedule.length) //hitting end of schedule, dont advance
      return;

    ++this.scheduleidx;
    this._cueScheduledVideo(0);
  }

  //reports the playlist relative time.
  getCurrentTime() {
    if (this.playlist.schedule.length == 0)
      return null;

    if (this.scheduleidx >= this.playlist.schedule.length) //we're behind the this.playlist
    {
      const lastentry = this.playlist.schedule[this.playlist.schedule.length - 1];
      return {
        time: (lastentry.relativestart_ms + (lastentry.duration_ms || lastentry.estimate_ms || 0)) / 1000,
        addcurrentclock: false
      };
    }
    return {
      time: (this.playlist.schedule[this.scheduleidx].relativestart_ms / 1000),
      addcurrentclock: true
    };
  }

  setCurrentTime(settime_sec) {
    if (this.playlist.schedule.length == 0)
      return; //nothing we can do

    //find video offset
    const settime_ms = settime_sec * 1000;
    let matchvideo = this.playlist.schedule.findIndex(_ => _.relativeduration_ms > settime_ms);
    if (matchvideo == -1)
      matchvideo = this.playlist.schedule.length - 1; //keep it in range...

    const reltime_ms = settime_ms - this.playlist.schedule[matchvideo].relativestart_ms;
    this.scheduleidx = matchvideo;
    this._cueScheduledVideo(reltime_ms);
  }

  getTotalTime() //returns in seconds
  {
    const totaltime_ms = this.playlist.schedule.length ? this.playlist.schedule[this.playlist.schedule.length - 1].relativeduration_ms : 0;
    return totaltime_ms / 1000;
  }
}
