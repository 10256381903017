/* eslint-disable */
/// @ts-nocheck -- Bulk rename to enable TypeScript validation

/* The videobinder links livesiteclients to elements... and will set up the
   VideoController when needed
*/

import VideoController from "../../pages/testplayer/videocontroller";
import * as bindings from '../util/bindings';

const playbackcorrect = 20; //seconds behind before we correct playback position
const failtimerecheck = 60;
const failtimerandomfactor = 1; // (1 to (1+1=) 2 times the original amount)

const successtimerecheck = 10 * 60; // 1 minute
const successtimerandomfactor = .5; // (1 to (1+.5=)1.5 times the original amount)

export default class VideoBinder extends bindings.BindableBase {
  constructor(livesiteclient, element, options) {
    super(livesiteclient, element);
    this._options = {
      allowfullscreen: true,
      fullscreenelement: null,
      ...options
    };

    this._listeners.push(this._livesiteclient.on('cue-start', cue => this._onCueStart(cue), { filter: cue => cue.cuetype === "whlive:mainvideo" })
      , this._livesiteclient.on('cue-upcoming', cue => this._onCueUpcoming(cue), { filter: cue => cue.cuetype === "whlive:mainvideo" })
      , this._livesiteclient.on('cue-end', cue => this._onCueEnd(cue), { filter: cue => cue.cuetype === "whlive:mainvideo" })
    );

    //we do this here instead of in livesiteclient.es, as livesiteclient.es doesn't know if we're already bound
    const curmainvideocue = this._livesiteclient.getCurrentCues().filter(cue => cue.cuetype == "whlive:mainvideo")[0];
    if (curmainvideocue)
      this._onCueStart(curmainvideocue);
  }

  _unbindElement() {
    if (this.videocontroller)
      this.videocontroller.destroy();
  }

  _onCueStart(cue) {
    let onEnd;
    if (cue.data.onVideoEnd)
      onEnd = cue.data.onVideoEnd;
    this._ensureVideoController().playVideo({ ...cue.data, estimate_ms: cue.estimate_ms, onEnd });
  }
  _onCueUpcoming(cue) {
    this._ensureVideoController().informUpcomingVideo({ cue: cue });
  }
  _onCueEnd(cue) {
    if (this.videocontroller)
      this.videocontroller.stopVideo();
  }
  _ensureVideoController() {
    if (!this.videocontroller) {
      this.videocontroller = new VideoController(this, this._element, this._livesiteclient.isBroadcast(), {
        debug: this._livesiteclient._options.debug,
        allowfullscreen: this._options.allowfullscreen,
        fullscreenelement: this._options.fullscreenelement
      });
      if (this._livesiteclient.isBroadcast())
        this.videocontroller.setForcedTime((videocue, currentTime, duration) => this._getForcedTime(videocue, currentTime, duration));
    }
    return this.videocontroller;
  }
  _onVideoEnd() {
    //FIXME - maar
    // console.error("_onVideoEnd");
  }

  _getForcedTime(videocue, currentTime, duration) {
    // no time control needed for live channels
    if (videocue.islive)
      return null;

    // HLS durations sometimes only have second accuracy. if duration is still unknown (eg LEVEL not parsed yet, just a manifest) or less than 1 seconds away from estimate_ms, trust the provided estimate
    if (videocue.estimate_ms && (isNaN(duration) || Math.abs(videocue.estimate_ms / 1000 - duration) <= 1))
      duration = videocue.estimate_ms / 1000;

    const cuestart = Date.parse(videocue._start);
    const videooffset_ms = videocue.offset_ms || 0;
    const servertime_est = this._livesiteclient._chatplaneconnector._cpconnection.getServerTime();
    let wantposition = ((servertime_est > cuestart ? servertime_est - cuestart : 0) + videooffset_ms) / 1000;
    if (videocue.loop && duration && wantposition > duration) //calculate position in current video loop
    {
      //TODO Can we do this with floating point instead of integer math?
      wantposition = (Math.floor(wantposition * 1000) % Math.floor(duration * 1000)) / 1000;
    }


    const diff = wantposition - currentTime;
    console.log("_getForcedTime", { diff, cuestart, videooffset_ms, servertime_est, wantposition, currentTime, duration });

    if (duration === null || wantposition > duration) {
      // no forced seek in the last second, don't want end to repeat when playback doesn't reach exact end
      return currentTime < duration - 1 ? duration : null;
    }

    if (currentTime == null)
      return wantposition;

    // Keep within 5 seconds of the current position
    if (diff > playbackcorrect || diff < -1 || currentTime < videooffset_ms / 1000)
      return wantposition;

    return null; // keep as it is
  }
}

export function getCurrentTime(livesiteclient) {
  const myplayers = bindings.getBindings(livesiteclient, VideoBinder).filter(binding => binding.videocontroller && binding.videocontroller.activeVideoElement);
  if (!myplayers.length)
    return 0;

  const ct = myplayers[0].videocontroller.activeVideoElement.currentTime;
  return ct;
}
