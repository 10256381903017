/* eslint-disable */
/// @ts-nocheck -- Bulk rename to enable TypeScript validation

import * as dompack from 'dompack';
import getTid from "@mod-tollium/js/gettid";
import { getScreenNameInitials } from "../../shared/util";
import "./conversationlist.scss";
import * as bindings from '../util/bindings';
import ActionsManager from "../actions/actions";
import * as dialogapi from 'dompack/api/dialog';

export default class ConversationListHandler extends bindings.BindableBase {
  constructor(livesiteclient, element, options) {
    super(livesiteclient, element);
    this._options = {
      conversationshandler: null,
      separatearchive: false,
      separateincoming: false,
      ...options
    };

    if (this._options.separateincoming != this._options.separatearchive)
      throw new Error(`Different modes for separateincoming and separatearchive aren't supported`); //no particular reason, just didn't bother testing it

    this._livesiteclient = livesiteclient;
    this._element = element;
    this._element.classList.add("whlive-conversationlist", "whlive-chat--1on1list");
    this.listeners = [];
    this.conversations = new Map;

    const footer = this._element.querySelector(".whlive-conversationlist__footer") || <div class="whlive-conversationlist__footer" />;
    const newchildren = [];

    this.entriesNode = <div class="whlive-conversationlist__entries whlive-conversationlist__entries--answered" />;
    if (this._options.separatearchive) {
      const chattabsnode =
        <div class="whlive-conversationlist__tabs">
          {this._tabnode_mychats = < div class="whlive-conversationlist__tab" onClick={() => this._selectTab("mychats")}>{getTid("live_api:frontend.js.mychats")}</div>}
          {this._tabnode_archived = <div class="whlive-conversationlist__tab" onClick={() => this._selectTab("archived")}>{getTid("live_api:frontend.js.archived")}</div>}
        </div>;
      this._setSelectedTab("mychats");
      newchildren.push(chattabsnode);
    }

    newchildren.push(this.entriesNode);

    if (this._options.separateincoming) {
      this.inactiveNode = <div class="whlive-conversationlist__entries whlive-conversationlist__entries--unanswered" />;
      this._unansweredNode = <div class="whlive-conversationlist__unread">{getTid("live_api:frontend.js.unansweredconversations", 0)}</div>;
      newchildren.push(this._unansweredNode, this.inactiveNode);
    }
    this._element.replaceChildren(...newchildren, footer);

    if (options.conversationshandler) {
      this.conversationshandler = options.conversationshandler;
      this.listeners.push(this._livesiteclient.on("chats", chats => this._onConversations(chats)));
      this.listeners.push(this._livesiteclient.on("chat", chat => this._onNewConversation(chat)));

      this._selectlistener = this.conversationshandler.on("select", ({ conversation }) => this._onSelectedConversation(conversation));
    }

    this.actionsmanager = new ActionsManager(this._element,
      [
        { className: "whlive-chat__forwardconversation", handler: message => this._forwardConversation(message), title: getTid('live_api:frontend.js.chat.forwardconversation') },
        { className: "whlive-chat__closeconversation", handler: message => this._closeConversation(message), title: getTid('live_api:frontend.js.chat.closeconversation') }
      ]);

    setTimeout(() => {
      this._onConversations({ conversations: this._livesiteclient.getConversations() });
      this._refreshList();
    }, 1);
  }

  _setSelectedTab(tab) {
    this._selectedtab = tab;
    this._tabnode_mychats.classList.toggle('whlive-conversationlist__tab--active', this._selectedtab == 'mychats');
    this._tabnode_archived.classList.toggle('whlive-conversationlist__tab--active', this._selectedtab == 'archived');
  }

  _selectTab(tab) {
    if (this._selectedtab == tab)
      return;

    this._setSelectedTab(tab);
    this._refreshList();
  }

  _fillAgentSelect(agentselect, button, teamid) {
    const team = this._livesiteclient.roomconfig.teams.find(team => team.id === teamid);

    const newopts = [];
    const opts = new Map(Array.from(agentselect.options).map(opt => [opt.value, opt]));

    if (team) {
      for (const agent of team.members) {
        if (!agent.userstatus?.canaccept || agent.publicid === this._livesiteclient._chatplaneconnector.publicid)
          continue;

        const id = agent.publicid;
        const node = opts.get(id) ?? <option value={id} />;
        node.textContent = agent.screenname;
        opts.delete(id);
        newopts.push(node);
      }
    }

    button.disabled = !newopts.length;
    const firstopt = opts.get("") ?? <option value={""} />;
    firstopt.textContent = newopts.length ? getTid("live_api:frontend.js.autochoose") : getTid("live_api:frontend.js.noagentsavailable");
    opts.delete("");
    newopts.unshift(firstopt);

    agentselect.append(...newopts);
    for (const node of opts.values())
      node.remove();
  }

  async _forwardConversation({ conversation }) {
    const dialog = dialogapi.createDialog();
    let button;
    const agentselect =
      <select class="wh-form__pulldown" name="agent">
      </select>;

    const teamselect =
      <select class="wh-form__pulldown" name="team" onChange={() => this._fillAgentSelect(agentselect, button, teamselect.value)}>
        <option disabled selected>Select a team</option>
        {this._livesiteclient.roomconfig.teams.map(_ => <option value={_.id}>{_.title}</option>)}
      </select>;

    button = <button class="whlive-dialogbutton" type="button" onClick={() => dialog.resolve({ teamid: teamselect.value, agentid: agentselect.value, message: messagenode.value })}>Forward</button>;

    this._fillAgentSelect(agentselect, button, teamselect.value);

    const messagenode = <textarea class="wh-form__textarea"></textarea>;
    dialog.contentnode.append(<div class="wh-styledinput">
      <h2 class="whlive-dialog__dialogbox__title">Forward</h2>
      <div class="wh-form__fieldgroup wh-form__fieldgroup--pulldown">
        <label class="wh-form__label">Forward this conversation to:</label>
        {teamselect}
      </div>
      <div class="wh-form__fieldgroup wh-form__fieldgroup--pulldown">
        <label class="wh-form__label">Select agent:</label>
        {agentselect}
      </div>
      <div class="wh-form__fieldgroup wh-form__fieldgroup--textarea">
        <label class="wh-form__label">Add a message for the agent:</label>
        {messagenode}
      </div>
      {button}
    </div>);
    const forwarddata = await dialog.runModal();
    if (forwarddata?.teamid) {
      if (forwarddata.message)
        await conversation.sendMessage(forwarddata.message, { audience: ["@agent"] });
      conversation.transferTo({ teamid: forwarddata.teamid, agentid: forwarddata.agentid });
    }
  }

  async _closeConversation({ conversation }) {
    if (await this._livesiteclient.context._runCommonMessageBox("closeconversation") == "yes")
      conversation.closeConversation();
  }

  _unbindElement() {
    this.listeners.forEach(listener => this._livesiteclient.off(listener));
    this.conversations.forEach((_, conversation) => conversation.listeners.forEach(listener => conversation.off(listener))); // Map.forEach((value, key) => { ... } )
    if (this._selectlistener)
      this.conversationshandler.off(this._selectlistener);
  }

  _selectConversation(conversation) {
    if (this.conversationshandler)
      this.conversationshandler.selectConversation(conversation);
    else
      this._onSelectedConversation(conversation);
  }

  _onSelectedConversation(conversation) {
    if (this._selectedconversation === conversation)
      return;

    if (conversation)
      conversation.clearUnread();

    this._selectedconversation = conversation;
    this._refreshList();
  }

  _onConversations({ conversations }) {
    const unused = new Set(this.conversations.keys());

    let firstConversation;
    for (const conversation of conversations) {
      // if (!this.conversations.size)
      // firstConversation = conversation;
      if (firstConversation || !this.conversations.get(conversation))
        this._addConversation(conversation);
      unused.delete(conversation);
    }
    for (const conv of unused)
      this._removeConversation(conv);

    this._refreshList();
  }

  _onNewConversation({ conversation }) {
    // const isFirst = !this.conversations.size;
    this._addConversation(conversation);
    this._refreshList();
  }

  _addConversation(conversation) {
    this.conversations.set(conversation,
      {
        active: false,
        listeners: [
          conversation.on("message", _ => this._refreshList()),
          conversation.on("updateconversation", _ => this._refreshList())
        ]
      });
  }

  _removeConversation(conversation) {
    this.conversations.delete(conversation);
  }

  _refreshList() {
    const conversations = [...this.conversations.keys()].filter(conversation => conversation.chatmode != 'new');
    const archived_conversations = conversations.filter(conversation => conversation.chatmode != 'open');
    const open_conversations = conversations.filter(conversation => conversation.chatmode == 'open');

    let entryNodes;
    if (this._selectedtab == 'archived')
      entryNodes = archived_conversations;
    else if (this._selectedtab == 'mychats')
      entryNodes = open_conversations.filter(conversation => !conversation.unanswered); // get active-but-not-unanswered
    else
      entryNodes = conversations;

    entryNodes = entryNodes.sort((first, second) => this._compareEntries(first, second)) // conversations with the newer messages before older
      .map(conversation => this._buildConversationBubble(conversation)); // create nodes
    this.entriesNode.replaceChildren(...entryNodes);

    if (this._options.separateincoming) {
      entryNodes = open_conversations.filter(conversation => conversation.unanswered) // get active-but-unanswered entries
        .sort((first, second) => this._compareEntries(second, first)) // conversations with the newer messages before older
        .map(conversation => this._buildConversationBubble(conversation)); // create nodes
      this.inactiveNode.replaceChildren(...entryNodes);
    }

    //FIXME this is global state, not per list. it should probably be announced by the chatplaneclient or at some other higher level
    const unreadinfo = {
      unreadconversations: 0,
      unreadmessages: 0,
      unansweredconversations: 0
    };
    for (const convo of conversations) {
      if (convo.unread > 0) {
        unreadinfo.unreadmessages += convo.unread;
        ++unreadinfo.unreadconversations;
      }
      if (convo.unanswered)
        ++unreadinfo.unansweredconversations;
    }

    const unreadinfo_json = JSON.stringify(unreadinfo);
    if (unreadinfo_json != this._last_unreadinfo_json) {
      this._last_unreadinfo_json = unreadinfo_json;
      if (this._unansweredNode)
        this._unansweredNode.textContent = getTid("live_api:frontend.js.unansweredconversations", unreadinfo.unansweredconversations);
      this._livesiteclient.emit("unread", {
        ...unreadinfo,
        conversations: unreadinfo.unreadconversations,
        unactivated: unreadinfo.unansweredconversations  //legacy member names
      });
    }
  }

  _compareEntries(first, second) {
    // Sort entries with newer messages before entries with older messages
    const firstHistory = first.getHistory();
    const firstLastMsg = firstHistory[firstHistory.length - 1];
    const secondHistory = second.getHistory();
    const secondLastMsg = secondHistory[secondHistory.length - 1];
    return firstLastMsg?.createdat > secondLastMsg?.createdat ? -1 : firstLastMsg?.createdat < secondLastMsg?.createdat ? 1 : 0;
  }

  _buildConversationBubble(conversation) {
    const lastMsg = conversation.history[conversation.history.length - 1];

    const classNames = ["whlive-chat__bubble", "whlive-chat__bubble--" + conversation.chatmode];
    if (lastMsg?.mine)
      classNames.push("whlive-chat__bubble--mine");
    if (conversation.unread)
      classNames.push("whlive-chat__bubble--unread");
    if (conversation.unanswered)
      classNames.push("whlive-chat__bubble--unanswered");
    if (conversation === this._selectedconversation)
      classNames.push("whlive-chat__bubble--selected");

    if (conversation.peervisitor?.screenflags)
      classNames.splice(-1, 0, ...conversation.peervisitor.screenflags.map(flag => `whlive-chat__screenflag-${flag}`));

    const bubblenode =
      <div class={classNames.join(" ")} onClick={() => this._selectConversation(conversation)}>
        {this._buildBubbleMessage(conversation, lastMsg)}
      </div>;

    return bubblenode;
  }

  _buildBubbleMessage(conversation, message) {
    const classNames = ["whlive-chat__message"];

    const userNode = <div class="whlive-chat__name">{conversation.peervisitor?.screenname ?? ""}</div>;
    if (conversation.peervisitor?.screenflags)
      classNames.splice(-1, 0, ...conversation.peervisitor.screenflags.map(flag => `${classNames[0]}__screenflag-${flag}`));

    let timeNode = null;
    if (message?.createdat) {
      const createdat = new Date(message.createdat);
      timeNode = <time class="whlive-chat__time" datetime={message.createdat}>{createdat.getHours() + ":" + ("0" + createdat.getMinutes()).slice(-2)}</time>;
    }

    let titleNode;
    if (conversation.team)
      titleNode = <div class="whlive-chat__title">{conversation.team.title}</div>;

    let textNode;

    let actionsNode;
    if (this._livesiteclient.isAgent()) {
      actionsNode = <div class="whlive-chat__actions"></div>;
      classNames.push("whlive-chat__message--actions");
    }

    const messageNode =
      <div class={classNames.join(" ")}>
        {/*TODO: message state (forwarded) */}
        <div class="whlive-chat__avatar" data-whlive-initials={getScreenNameInitials(conversation.peervisitor?.screenname ?? "")}></div>
        <div class="whlive-chat__metadata">
          {userNode}
          {timeNode}
          {actionsNode}
        </div>
        <div class="whlive-chat__unread">{conversation.unread ? conversation.unread : ""}</div>
        {titleNode}
        <div class="whlive-chat__body">
          {textNode = <div class="whlive-chat__text"></div>}
        </div>
      </div>;

    textNode.innerHTML = message?.message ?? "";

    if (actionsNode)
      actionsNode.addEventListener("click", event => this.actionsmanager.showControls(event, { node: messageNode, conversation, message }));

    return messageNode;
  }
}
