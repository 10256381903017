/* eslint-disable */
/// @ts-nocheck -- Bulk rename to enable TypeScript validation

import * as dompack from 'dompack';
import "./videodebugger.scss";
import { videointerface } from "./index";

export default class VideoDebugger {
  constructor(node) {
    this.node = node;
    node.addEventListener("keydown", evt => this.onKey(evt));
  }

  requestStats() {
    if (!this.updaterequested && this.active) {
      this.updaterequested = true;
      requestAnimationFrame(() => this.updateStats());
    }
  }

  getTime() {
    if (!videointerface)
      return '';

    let time = videointerface.getCurrentTime();
    if (!time)
      return '';

    const msecs = ('000' + Math.floor(time % 1000)).slice(-3);
    time = Math.floor(time / 1000);
    const secs = ('0' + Math.floor(time % 60)).slice(-2);
    time = Math.floor(time / 60);
    const minutes = ('0' + Math.floor(time % 60)).slice(-2);
    return (Math.floor(time / 60) % 24) + ":" + minutes + ":" + secs + "." + msecs;
  }

  updateStats() {
    this.updaterequested = false;
    this.clock.textContent = this.getTime();
    this.requestStats();
  }

  toggleDebugger() {
    if (!this.debugnode) {
      //build debugnodes first
      this.debugnode = <div class="whlive-videodebugger">
        <p>currentTime: {this.clock = <span />}</p>
      </div>;
      this.node.prepend(this.debugnode); //needs to be *before* the playerarea so the playerarea can be on top of us
    }

    this.active = !this.active;
    this.node.classList.toggle("whlive-player--debugmode", this.active);

    this.requestStats();
  }

  onKey(evt) {
    if (evt.ctrlKey && evt.shiftKey && evt.key == 'D') {
      evt.preventDefault();
      this.toggleDebugger();
    }
  }
}
