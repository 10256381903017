export default class CoalescingCallback {
  callback;
  maxtimeout;
  _timer?: NodeJS.Timer | null;

  constructor(callback: () => void, options?: { maxtimeout?: number }) {
    this.callback = callback;
    this.maxtimeout = options?.maxtimeout || 1;
  }

  schedule() {
    if (this._timer)
      return;
    this._timer = setTimeout(() => this._runCallback(), this.maxtimeout);
  }

  _runCallback() {
    this._timer = null;
    this.callback();
  }
}
