/* eslint-disable */
/// @ts-nocheck -- Bulk rename to enable TypeScript validation

/* The chatbinder links conversations to chatframes
*/
import * as dompack from 'dompack';
import GroupChatFrame from "../../pages/testchatclient/groupchatframe";
import QuestionsChatFrame from "../../pages/testchatclient/questionsframe";
import PrivateChatFrame from "../../pages/testchatclient/privatechatframe";
import * as bindings from '../util/bindings';

//We should probably remain as a wrapper around the actual ChatFrame so that the frame doesn't have to worry about exposing a too wide API
export default class ChatBinder extends bindings.BindableBase {
  constructor(livesiteclient, element, conversation, options) {
    super(livesiteclient, element);
    this.options = {
      actions: true, //NOTE still filtered by ismoderator right now in the group/question frames..
      ...options
    };
    this.conversation = conversation;

    this._clicklistener = evt => this._onClick(evt);
    this._element.addEventListener("click", this._clicklistener);

    const frameoptions = {
      livesiteclient: this._livesiteclient,
      actions: this.options.actions
    };

    if (conversation._roomtype == 'questions')
      this.chatframe = new QuestionsChatFrame(this._element, this.conversation, null, null, null, frameoptions);
    else if (conversation._roomtype == 'privatechat')
      this.chatframe = new PrivateChatFrame(this._element, this.conversation, null, null, null, frameoptions);
    else {
      if (this.options.privatechat)
        frameoptions.privatechat = this.options.privatechat;

      this.chatframe = new GroupChatFrame(this._element, this.conversation, null, null, null, frameoptions);
    }

    //FIXME manually bind incoming messages and mock setup until ChatBackend and Conversation have merged further
    this.listeners = [
      this.conversation.on("message", msg => this.chatframe._processChat(msg, { fromarchive: msg.fromarchive })), //TODO do we really need the 'fromarchive' flag - why does chatbakcend care?
      this.conversation.on("deletemessage", msg => this.chatframe._processChat(msg)),
      this.conversation.on("banvisitor", msg => this.chatframe._processChat(msg)),
      this.conversation.on("roomstate", msg => this._processRoomState(msg)),
      this.conversation.on("clearallmessages", msg => this.chatframe._clearChatFrame()), //used by the archive
      this.conversation.on("updateconversation", msg => {
        this.chatframe.updateConnectionState();
        this.chatframe.updateDom();
      }) //added for transfer+close in 1-on-1 chats
    ];

    //FIXME IF we are already bound....
    //      otherwise fire a 'closed' state, if any at all.. this.chatframe._processChat({type:"roomstate", state: {mode:"open"}});
    this.chatframe.__mode = "html";
    this._processRoomState(conversation.roomstate);
    conversation.getHistory().forEach(historyitem => this.chatframe._processChat(historyitem));

    // this.conversation.on("roomstate", e=>console.error(e));
  }

  _unbindElement() {
    if (this.chatframe)
      this.chatframe.destroy();
    this._element.removeEventListener("click", this._clicklistener);
    this._element.replaceChildren();
    this.listeners.forEach(listener => this.conversation.off(listener));
  }

  _processRoomState(state) {
    //FIXME a roomstate is also emitted when initial moderator flags etc are set up, but those are not in the state itself
    this.chatframe.updateConnectionState();
    this.chatframe.processRoomState(state); //implies updateDom
  }

  _onClick(evt) {
    //ensure that any links inside the chat are forced to a new window
    const linknode = evt.target.closest('a[href]');
    if (!linknode)
      return;

    //TODO allow some domains to be execpted?
    if (this.options.confirmlinks) {
      dompack.stop(evt);

      const host = new URL(linknode.href).host;
      if (!host)
        return;

      this._livesiteclient.context._runCommonMessageBox("externallink", { host }).then(response => {
        if (response == 'yes')
          window.open(linknode.href, '_blank');
      });
      return;
    }

    linknode.target = "_blank";
  }
}
