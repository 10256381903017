/* eslint-disable */
/// @ts-nocheck -- Bulk rename to enable TypeScript validation

import * as dompack from 'dompack';
import Context from "./src/context/context";

//FIXME someone needs to load these..
import * as testplayer from './pages/testplayer';
import * as testchatclient from './pages/testchatclient';
import { parseVisitorToken } from '../../libliveapi/js/util/tokens';
import ChatPlaneConnection from "@mod-live_api/libliveapi/js/cpconnect/cpconnection";
import { stringifyEvent } from '../../libliveapi/js/util/logging';

// import '@mod-system/js/wh/errorreporting'; //log JS errors to notice log - TODO reintroduce someday perhaps, but integrate with pxl or eg sentry.io then

import './apisite.scss';

dompack.onDomReady(() => {
  if (location.hash == "#player")
    testplayer.init();
  else if (location.hash == "#chatclient")
    testchatclient.init();
});

if (document.currentScript.__onLiveAPILoad) //empty if loaded through frame (legacy iframe mode)
  document.currentScript.__onLiveAPILoad({
    createContext: options => new Context(options),
    pickConnectionOptions: ChatPlaneConnection.pickConnectionOptions,
    parseVisitorToken,
    stringifyEvent
  });
