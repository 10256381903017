/* eslint-disable */
/// @ts-nocheck -- Bulk rename to enable TypeScript validation

import EventSource from '@mod-live_api/libliveapi/js/util/eventsource';
const bindings = new Map;

export function bindElement(livesiteclient, element, constructor, ...options) {
  const existing = bindings.get(element);
  if (existing) //rebinding now destroys the existing instance, otherwise it may become very hard to track the state of eventsources
    existing.unbind();

  const binding = new constructor(livesiteclient, element, ...options);
  bindings.set(element, binding);
  return binding;
}

export function unbindAll(livesiteclient) {
  for (const [element, binding] of bindings) {
    if (binding._livesiteclient == livesiteclient)
      binding.unbind();
  }
}

export class BindableBase extends EventSource {
  constructor(livesiteclient, element) {
    super();
    this._listeners = [];
    this._livesiteclient = livesiteclient;
    this._element = element;
    this._childbindings;
  }

  destroy() {
    console.warn("destroy() is deprecated - use unbind");
  }

  _unbindElement() {
  }

  //Unbind and cleanup a bindable
  unbind() {
    this._listeners.forEach(listener => this._livesiteclient.off(listener));
    this._unbindElement();
    bindings.delete(this._element);
  }
}

export function getBindings(livesiteclient, type) {
  const result = [];
  for (const [element, binding] of bindings) {
    if (binding._livesiteclient == livesiteclient && binding instanceof type)
      result.push(binding);
  }
  return result;
}
