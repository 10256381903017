/* eslint-disable */
/// @ts-nocheck -- Bulk rename to enable TypeScript validation

import ChatPlaneClient, { ChatPlaneClientOptions } from './chatplaneclient';
import ChatPlaneConnection, { ChatPlaneConnectionOptions } from './cpconnect/cpconnection';

interface ChatPlaneContextOptions extends ChatPlaneConnectionOptions {
  visitortoken?: string;
}

/* The Context implements the 'global visitor token' concept
   @param options.dialogtheme Class for dialogs. Defaults to `whlive-dialog--theme-whlive`
*/
export default class ChatPlaneContext {
  visitortoken?: string;
  _clients: Map<ChatPlaneClient, { followglobalvisitor: boolean }>;
  _connectionoptions;

  constructor(options: ChatPlaneContextOptions) {
    this._clients = new Map; // map of ChatPlaneClient => { followglobalvisitor }

    if (options && "visitor" in options)
      throw new Error("Set the option 'visitortoken' not 'visitor'!"); //keep this here at least until 31-10-2021 - spots incorrect code updates

    this._connectionoptions = ChatPlaneConnection.pickConnectionOptions(options);
    if (options?.visitortoken)
      this.setVisitor(options.visitortoken);
  }

  async fetchJSON(url: string) {
    throw new Error(`fetchJSON not implemented, cannot fetch: ${url}`); //needs to be browser or node fetch and grab a JSON
  }

  _createClient(opts?: ChatPlaneClientOptions) {
    return new ChatPlaneClient(this, opts);
  }

  unregisterClient(client: ChatPlaneClient) {
    this._clients.delete(client);
  }

  setVisitor(newvisitor: string) {
    this.visitortoken = newvisitor || "";
    for (const [client, settings] of this._clients)
      if (settings.followglobalvisitor) //client fo
        client.setVisitorToken(newvisitor);
  }

  initClient(url: string, opts?: { visitortoken?: string }) {
    if (opts && "visitor" in opts)
      throw new Error("Set the option 'visitortoken' not 'visitor'!"); //keep this here at least until 31-10-2021 - spots incorrect code updates

    const followglobalvisitor = !(opts && "visitortoken" in opts);
    const usevisitortoken = followglobalvisitor ? this.visitortoken : opts?.visitortoken;
    const connection = this._createClient({
      ...this._connectionoptions,
      ...opts,
      visitortoken: usevisitortoken
    });
    this._clients.set(connection, { followglobalvisitor: followglobalvisitor });
    connection._asyncInit(url);
    return connection;
  }

  lookupPeerByPublicId(publicid: string) {
    for (const [client] of this._clients) {
      const peer = client.lookupPeerByPublicId(publicid);
      if (peer) //we also return the matching client that is a path to the peer
        return { client, peer };
    }
    return null;
  }

  async createConversation({ peerid }: { peerid: string }) //Try to find and chat with the specified peer
  {
    for (const [client] of this._clients)
      if (client._chatinfo?.roomtype == 'chatcontrol')
        return await client.createConversation({ peerid });
    throw new Error(`Cannot find a chatcontrol channel to initiate a conversation on`);
  }

  _requestPrivateChat() //invoked when the lower layers signal us that a connection to the private chat is desired
  {
    const privatechatclients = [...this._clients.keys()].filter(_ => _._chatinfo.roomtype == 'chatcontrol');
    if (privatechatclients.length) {
      privatechatclients.forEach(_ => _._chatplaneconnector?._connectChatPlane());
    } else {
      //TODO respond this back to the invoker over the original groupchat channel so it knows it can stop waiting
      console.error("_requestPrivateChat received but no private chat clients registered");
    }
  }
}
