/* eslint-disable */
/// @ts-nocheck -- Bulk rename to enable TypeScript validation

import * as dompack from 'dompack';
import "./live_api.lang.json";
import getTid from "@mod-tollium/js/gettid";
import * as storage from "../testplayer/storage";
import ActionsManager from "../../src/actions/actions";
import { equalJSONObjects } from "@mod-live_api/libliveapi/js/util/cueparsing";
import { getScreenNameInitials } from "../../shared/util";

const MAX_MSGLOGSIZE = 250;

//TODO split the protocol and rendering parts, as the rendering part is acutally replacable (and could realistically be headless)
export default class ChatFrame {
  constructor(chatbackend, url, visitortoken, chatroominfoinfo, opts) {
    this.backend = chatbackend; //NOTE: is a ConversationBase object
    this.url = url;
    this.state = {};
    this.visitortoken = visitortoken;
    this.options = {
      replayFrom: null,
      replayUntil: null,
      ...opts
    }; //chat configuration
    this.livesiteclient = this.options.livesiteclient;
    this.bubblemap = new Map;
    this.repliedto = new Set;
    this.starred = new Map; // Mapping from id to timestamp
    this.initialreplay = { from: this.options.replayFrom, until: this.options.replayUntil };
    this.loadedarchive = false;
    this.ismoderator = false; //wait for updateConnectionState or updateUser from chatbackend.es

    this.actions = [
      { className: "whlive-chat__replytomessage", handler: ({ message }) => this.replyToMessage(message), title: getTid('live_api:frontend.js.chat.replybutton') },
      { className: "whlive-chat__deletemessage", handler: ({ message }) => this._confirmDeleteMessage(message), title: getTid('live_api:frontend.js.chat.delbutton') },
      { className: "whlive-chat__banvisitor", handler: ({ message }) => this._confirmBanVisitor(message), title: getTid('live_api:frontend.js.chat.mutebutton') }
    ];

    this.updateConnectionState();
  }

  updateConnectionState() {
    if (this.backend.chatplaneconnector) {
      this.isreadonly = this.backend.chatplaneconnector.isReadOnly() || ['transferred', 'closed'].includes(this.backend.chatmode);
      this.isanonymous = this.backend.chatplaneconnector.isAnonymous();
      this.updateUser({
        screenname: this.backend.chatplaneconnector.screenname,
        ismoderator: this.backend.chatplaneconnector.isModerator()
      });

    } else {
      this.isreadonly = this.backend.isreadonly;
      this.isanonymous = this.backend.isanonymous;
    }
  }

  _isModerator() {
    return this.ismoderator;
  }

  getModeratorControls() {
    return [
      <div class="whlive-chat__flexspacer" />,
      <div class="whlive-chat__settings" onClick={() => this._openSettings()}><span class="whlive-chat__settings__control"></span></div>
    ];
  }

  _openSettings() {
    if (!this._isModerator())
      return;

    if (!this.settingsnode)
      this._buildSettingsNode();

    this.chatnode.classList.add("whlive-chat--showsettings");
  }

  _closeSettings() {
    this.chatnode.classList.remove("whlive-chat--showsettings");
  }

  _buildSettingsNode() {
    this.settingsnode =
      <div class="whlive-chat__settingspanel">
        <div class="whlive-chat__closesettings" onClick={() => this._closeSettings()}><span class="whlive-chat__closesettings__control"></span></div>
        <div class="whlive-chat__docs">
          { /* WARNING! these classes/designs are not stable. Please, someone come up with a nicer view!
                        TODO getTid*/ }
          <p>Set chat mode:</p>
          <p>
            <button class="__thisclasswillchange__openbutton" type="button" title="Open the chat for everyone" onClick={() => this._setMode("open")}>Open chat</button> &nbsp;
            <button class="__thisclasswillchange__moderatoronlybutton" type="button" title="Only moderators can talk" onClick={() => this._setMode("moderatoronly")}>Moderator only</button> &nbsp;
            <button class="__thisclasswillchange__closedbutton" type="button" title="Close the chat for all users" onClick={() => this._setMode("closed")}>Close chat</button>
          </p>
        </div>
      </div>;
    this.chatnode.append(this.settingsnode);
  }
  _setMode(newmode) {
    this.backend.sendMessage("/setmode " + newmode);
    this._closeSettings();
  }
  _processChat(message, options) {
    if (!message)
      return;
    // if(this.isarchived && !(options && options.fromarchive))
    // return; //once archived, it's final

    switch (message.type) {
      case "roomstate":
        {
          this.processRoomState(message.state);
          break;
        }
      case "msg":
      case "servermsg":
        {
          //FIXME shouldnt this.backend.messagelog be managed by chatbackend
          this._sendChatEvent("message", message);
        } break;
      case "update":
        {


          //reprocess updated message
          this._sendChatEvent("message", message);
        } break;
      case "delete-msg":
        {
          const msginfo = { id: message.id };
          this._sendChatEvent("deletemessage", msginfo);
        } break;
      case "ban-visitor":
        {
          const msginfo = { id: message.id };
          this._sendChatEvent("banvisitor", msginfo);
        } break;
      case "unban-visitor":
        {
          const msginfo = { id: message.id };
          this._sendChatEvent("unbanvisitor", msginfo);
        } break;
    }
  }

  _clearChatFrame() {
    this.bubblemap = new Map;
    dompack.empty(this.nodes.messages);
  }

  _sendChatEvent(type, detail) {
    if (this.__mode == 'html') //FIXME if html is not set, we (the frame) simply shouldn't exist because we'd be headless
      this._processEmbeddedChat(type, detail);
  }


  _buildBubbleMessage(message, mainMessage) {
    const classNames = ["whlive-chat__message"];

    const userNode = <div class="whlive-chat__name">{message.visitor?.screenname ?? ""}</div>;
    if (message.visitor?.screenflags)
      classNames.splice(-1, 0, ...message.visitor.screenflags.map(flag => `${classNames[0]}__screenflag-${flag}`));

    const createdat = new Date(message.createdat);

    let actionsNode;
    if (mainMessage && this.ismoderator && this.options.actions) {
      actionsNode = <div class="whlive-chat__actions"></div>;
      classNames.push("whlive-chat__message--actions");
    }

    let titleNode;
    /*TODO: something like:
    if (message.team)
      titleNode = <div class="whlive-chat__title">{ message.team }</div>;
    */

    let textNode;

    const messageNode =
      <div class={classNames.join(" ")}>
        {/*TODO: message state (forwarded) */}
        <div class="whlive-chat__avatar" data-whlive-initials={getScreenNameInitials(message.visitor?.screenname ?? "")}></div>
        <div class="whlive-chat__metadata">
          {userNode}
          <time class="whlive-chat__time" datetime={message.createdat}>{createdat.getHours() + ":" + ("0" + createdat.getMinutes()).slice(-2)}</time>
          {mainMessage && this._buildBubbleStatus(message)}
          {actionsNode}
        </div>
        {titleNode}
        <div class="whlive-chat__body">
          {textNode = <div class="whlive-chat__text"></div>}
        </div>
      </div>;

    textNode.innerHTML = message.message;

    return messageNode;
  }

  _buildBubbleStatus(message) {
    const iconsNode = <div class="whlive-chat__icons" />;
    if (this.repliedto.has(message.id))
      iconsNode.append(<span class="whlive-chat__reply"></span>); //TODO: Click to go to reply?
    if (this.starred.has(message.id))
      iconsNode.append(<span class="whlive-chat__star" onClick={() => this.unstarMessage(message)}></span>);
    if (message.votes) {
      //FIXME mousedown might go away once moderator tools are a simply menu button
      iconsNode.append(
        <span class="whlive-chat__vote" data-whlive-votetype="like" onMousedown={e => e.preventDefault()} onClick={evt => this._messageVoteClick(message, evt)}>
          <span class="whlive-chat__votecount">{message.votes.like}</span>
        </span>
      );
    }
    return iconsNode;
  }

  _messageVoteClick(message, evt) {
    dompack.stop(evt);
    if (this.backend.upvote)
      this.backend.upvote(message.id, message.myvote ? null : "like");
    else //FIXME backend.vote is iframe version, kill it
      this.backend.vote(message.id, undefined);//param was never used
  }

  _updateBubbleStatus(message) {
    const toupdate = this.bubblemap.get(message.id);
    if (toupdate) {
      const statusnode = toupdate.bubble.querySelector(".whlive-chat__icons");
      statusnode.replaceWith(this._buildBubbleStatus(message));
    }
  }

  _buildStatusBubble(status) {
    const createdat = new Date(status.createdat);

    return (
      <div class="whlive-chat__status">
        <div class="whlive-chat__text">{status.message}</div>
        <time class="whlive-chat__time" datetime={status.createdat}>{createdat.getHours() + ":" + ("0" + createdat.getMinutes()).slice(-2)}</time>
      </div>);
  }

  _addMessageToChatframe(message) {
    let bubblenode;
    if (message.type == "servermsg")
      bubblenode = this._buildStatusBubble(message);
    else {
      bubblenode = this._buildMessageBubble(message);
      if (this.ismoderator && this.options.actions)
        bubblenode.addEventListener("click", event => this.actionsmanager.showControls(event, { node: bubblenode, message }));
    }

    const existing = this.bubblemap.get(message.id);
    const showmessage = this._showIncomingMessage(message);
    if (showmessage) {
      if (existing) {
        if (existing.bubble.parentNode)
          existing.bubble.parentNode.replaceChild(bubblenode, existing.bubble);
      } else {
        this.nodes.messages.appendChild(bubblenode);
      }
    }

    this.bubblemap.set(message.id, { message: message, bubble: bubblenode });
    if (message.inreplyto) {
      const orgmsg = this.backend.getMessageById(message.inreplyto);
      if (orgmsg) {
        this.repliedto.add(orgmsg.id);
        this._updateBubbleStatus(orgmsg);
      }
    }

    const appended_my_message = !existing && message.mine; //adding own message to end may require eg. scrolling
    this._updatedChatFrame(appended_my_message);
  }

  // Override to filter incoming messages (e.g. don't show new messages on favorites tab)
  _showIncomingMessage(message) {
    return true;
  }

  _removeMessageFromChatframe(messageid) {
    const existing = this.bubblemap.get(messageid);
    if (existing) {
      existing.bubble.remove();
      this.bubblemap.delete(messageid);
      //TODO: Update 'sameuser' for the message following the deleted message
    }
  }

  _processEmbeddedChat(type, message) {
    switch (type) {
      case "message":
        {
          this._addMessageToChatframe(message);

        } break;
      case "deletemessage":
        {
          this._removeMessageFromChatframe(message.id);
        } break;
      case "banvisitor":
        {
          // if(mode == "html") //FIXME why should banning imply deletion of all history?
          // {
          //   for (const node of this.nodes.messages.querySelectorAll(".whlive-chat__message"))
          //   {
          //     if (node.dataset.visitorId === message.id)
          //       node.remove();
          //   }
          // }
        } break;
      case "unbanvisitor":
        {
        } break;
    }
  }
  _processInit(initres) {
    this.visitor = initres.visitor; //TODO we can start using this in more places instead of deriving it from our token as soon as 'normal' chats also guaranteed to start sending visitorinfo

    if (!this.processRoomState(initres.state)) //implies updateDom
      return; //already permanently closed

    this.announceOnline();

    //process any history
    initres.history.forEach(item => this.backend._processChat(item));
  }

  announceOnline() {
    this._sendChatEvent("online", {
      ismoderator: this._isModerator() && this.state.mode != 'archived',
      state: this.state,
      isreadonly: this.isreadonly || this.state.mode == 'archived',
      isanonymous: this.isanonymous
    });
    this.lastreportedstate = JSON.parse(JSON.stringify(this.state));
    this._sendChatEvent("roomstate", this.lastreportedstate);
  }

  processRoomState(roomstate) {
    if (this.isarchived)
      roomstate.mode = "archived";

    this.state = roomstate;
    if (roomstate.mode == "archived") {
      this.setupArchivedRoom(roomstate.archive);
      return false; //stop any further processing
    }

    this._readStarredMessages();

    this.updateDom();
    return true;
  }

  _readStarredMessages() {
    if (!this._roominfo)
      return;
    const starred = storage.getLocal("whlive:starred") || [];
    //ADDME: Remove old favorites?
    for (const entry of starred)
      this.starred.set(entry[0], entry[1]);
  }

  async setupArchivedRoom(archive) {
    if (this.isarchived) //already archived? ignore dupe message
      return;

    this.isarchived = true;
    this.state.mode = "archived"; //TODO there's some redundant setting here, but the immediatey straight-to-archive path doesn't go through procesRoomState
    this.updateDom();
    if (this.url) {
      const archiveurl = new URL(archive, this.url).toString();
      await this.backend.loadArchive(archiveurl);
      this.loadedarchive = true;
      this.announceOnline();
      this.backend.setReplayRange(this.initialreplay.from, this.initialreplay.until);
    }
  }

  replayUntil(from, until) {
    if (!this.loadedarchive)
      this.initialreplay = { from, until };
    else
      this.backend.setReplayRange(from, until);
  }

  async _sendChat(evt) {
    if (evt)
      dompack.stop(evt);
    if (!this.inputnode.value)
      return;

    const options = {};
    if (this._isreplyingto) {
      options.inreplyto = this._isreplyingto.id;
      this._isreplyingto = null;
      this.formNode.classList.remove("whlive-chat__form--replying");
    }

    this.inputnode.disabled = true;

    try {
      await this.backend.sendMessage(this.inputnode.value, options);
      this.inputnode.value = "";
      this.inputnode.parentNode.dataset.value = "";
    } finally {
      this.inputnode.disabled = false;
    }
  }

  _checkInputKey(evt) {
    if (evt.key == "Enter" && !(evt.shiftKey || evt.altKey || evt.ctrlKey || evt.metaKey)) {
      dompack.stop(evt);
      this._sendChat();
    }
  }

  _getInputPlaceholder() {
    return this.state.mode == "moderatoronly"
      ? getTid('live_api:frontend.js.chat.inputplaceholder-moderatoronly')
      : getTid('live_api:frontend.js.chat.inputplaceholder');
  }

  updateUser(user) {
    this.ismoderator = user.ismoderator;
    this.screenname = user.screenname;
  }

  buildDom(container) {
    this.actionsmanager = new ActionsManager(container, this.actions);

    this.nodes = {};

    this.statusarea =
      <div class="whlive-chat__statusarea" hidden />;

    this.messagearea =
      <div class="whlive-chat__messagesarea">
        {this.nodes.messages = <div class="whlive-chat__messages" />}
      </div>;
    //formnode starts out hidden until we're sure we're not archived or readonly
    this.formNode =
      <form class="whlive-chat__form" onSubmit={evt => this._sendChat(evt)} hidden>
        {this.replyNode = <span class="whlive-chat__replyingto"></span>}
        <span class="whlive-chat__input-resizer">
          {this.inputnode = <textarea class="whlive-chat__input" placeholder={this._getInputPlaceholder()} onKeypress={evt => this._checkInputKey(evt)} rows="1" maxlength="1200" />}
        </span>
        <button class="whlive-chat__submit" />
      </form>;
    this.aboutNode =
      <div class="whlive-chat__about">
        <div class="whlive-chat__myscreenname" />
      </div>;

    this.chatnode = container;
    this.chatnode.classList.add("whlive-chat");
    this.chatnode.replaceChildren(this.statusarea, this.messagearea, this.formNode, this.aboutNode);

    // Chat autosizing works by adding an invisible element that takes its contents from the dataset value and forces the
    // resizer parent node to grow when necessary
    // Inspired by https://css-tricks.com/auto-growing-inputs-textareas/#other-ideas
    this.inputnode.addEventListener("input", event => this.inputnode.parentNode.dataset.value = this.inputnode.value);
    this.refreshStatusMessage();
  }

  setStatusMessage(msg) {
    this.statusmessage = msg;
    if (this.statusarea)
      this.refreshStatusMessage();
  }
  refreshStatusMessage() {
    this.statusarea.textContent = this.statusmessage || '';
    this.statusarea.hidden = !this.statusmessage;
  }

  destroy() {
    if (!this.chatnode)
      return;

    this.actionsmanager.destroy();
    this.chatnode.classList.remove("whlive-chat");
    this.chatnode = null;
  }

  updateDom() {
    if (!this.chatnode)
      return; //we've already been cleaned up

    const chatmode = ['transferred', 'closed'].includes(this.backend.chatmode) ? 'closed' : this.state.mode;
    this.chatnode.classList.toggle("whlive-chat--open", chatmode == 'open');
    this.chatnode.classList.toggle("whlive-chat--moderatoronly", chatmode == 'moderatoronly');
    this.chatnode.classList.toggle("whlive-chat--closed", chatmode == 'closed');
    this.chatnode.classList.toggle("whlive-chat--archived", chatmode == 'archived');
    this.chatnode.classList.toggle("whlive-chat--readonly", this.isreadonly || this.state.mode == 'archived');
    this.chatnode.classList.toggle("whlive-chat--anonymous", this.isanonymous);

    this.chatnode.classList.toggle("whlive-chat--moderator", this.ismoderator);
    this.chatnode.querySelector(".whlive-chat__myscreenname").textContent = this.screenname;

    this.formNode.hidden = this.isarchived || this.isreadonly || (this.state.mode != 'open' && !this._isModerator());
    this.aboutNode.hidden = this.isarchived || this.isanonymous;
    this.inputnode.placeholder = this._getInputPlaceholder();

    if (this.lastreportedstate && this.state && !equalJSONObjects(this.state, this.lastreportedstate)) {
      this._sendChatEvent("roomstate", this.state);
      this.lastreportedstate = JSON.parse(JSON.stringify(this.state));
    }
  }

  _confirmDeleteMessage(msg) {
    this.livesiteclient.context._runCommonMessageBox("deletemessage").then(result => result == "yes" ? this.backend.deleteMessage(msg.id) : null);
  }

  _confirmBanVisitor(msg) {
    this.livesiteclient.context._runCommonMessageBox("banvisitor").then(result => result == "yes" ? this.backend.banVisitor(msg.visitor.visitorid) : null);
  }

  _confirmUnbanVisitor(id) {
    //this._sendChatEvent("confirm", { data: { type: "unbanVisitor" }, action: { whLive: "unbanVisitor", id: id } });
  }

  replyToMessage(msg) {
    //find the message
    this._isreplyingto = this.backend.getMessageById(msg.id);
    if (!this._isreplyingto)
      return;

    dompack.empty(this.replyNode);
    const msgNode = <span class="whlive-chat__replyingto-message"></span>;
    msgNode.innerHTML = this._isreplyingto.message;
    this.replyNode.append(
      <span class="whlive-chat__replyingto-close" onClick={() => this.cancelReplyToMessage()}></span>,
      <span class="whlive-chat__replyingto-user">{[
        <span class="whlive-chat__replyingto-reply">{getTid('live_api:frontend.js.chat.replyingto')}</span>,
        this._isreplyingto.visitor.screenname
      ]}
      </span>,
      msgNode);
    this.formNode.classList.add("whlive-chat__form--replying");

    this.inputnode.focus();
  }

  cancelReplyToMessage() {
    if (!this._isreplyingto)
      return;
    this._isreplyingto = null;
    this.formNode.classList.remove("whlive-chat__form--replying");
  }

  starMessage(msg) {
    //find the message
    const tostar = this.backend.getMessageById(msg.id);
    if (!tostar)
      return;

    this.starred.set(tostar.id, Date.now());
    storage.setLocal("whlive:starred", [...this.starred.entries()]);
    this._updateBubbleStatus(tostar);
  }

  unstarMessage(message) {
    this.starred.delete(message.id);
    storage.setLocal("whlive:starred", [...this.starred.entries()]);
    this._updateBubbleStatus(message);
  }
}
