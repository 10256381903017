/* eslint-disable */
/// @ts-nocheck -- Bulk rename to enable TypeScript validation

import * as dompack from "dompack";
const players = new WeakMap;

class PlayerStructure {
  constructor(node) {
    if (node.querySelector(".whlive-player__playerarea"))
      throw new Exception(`Already initialized as a player`);

    this.playernode = node;
    this.playernode.tabIndex = 0; //even if only to allow basic keyboard controls..
    this.videoholder = <div class="whlive-player__videoholder"></div>;
    this.useroverlays = <div class="whlive-player__useroverlays" />;
    this.interfaceholder = <div class="whlive-player__interfaceholder"></div>;

    //TODO waarom bestaat playerarea eigenlijk, of is dat een resize container ?
    node.classList.add("whlive-player");
    node.replaceChildren(<div class="whlive-player__playerarea">
      {this.videoholder}
      {this.useroverlays}
      {this.interfaceholder}
    </div>);
  }
}

export function ensurePlayer(node) {
  let player = players.get(node);
  if (!player) {
    player = new PlayerStructure(node);
    players.set(node, player);
  }
  return player;
}
