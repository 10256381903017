// eslint-disable-next-line @typescript-eslint/no-explicit-any
const vmstorage: any = {}; //vm-only storage, sufficient for tests and as a fallback in case localStorage throws

export default class StorageClient {
  prefix;

  /** @param scope - scope for the storage, defaults to 'global'. All clients who set the same scope will share the storage, even if they create seprate StorageClient classes.*/
  constructor(scope: string) {
    //we prefix our localStorage with 'cps' (chatplanestorage)
    this.prefix = `cps.${scope || 'global'}.`;
  }

  _get(key: string) {
    key = this.prefix + key;

    try {
      if (typeof localStorage !== 'undefined' && localStorage[key])
        return JSON.parse(localStorage[key]);
    } catch (e) {
      //ignoring
    }

    //no (working) localStorage present (blocked or node.js) so use VM-local storage
    return vmstorage?.[key] ?? null;
  }
  _set(key: string, value: unknown) {
    key = this.prefix + key;
    vmstorage[key] = value; //backup in case localStorage fails or overflows

    try {
      if (typeof localStorage !== 'undefined') {
        if (value !== null && value !== undefined)
          localStorage.setItem(key, JSON.stringify(value));
        else
          localStorage.removeItem(key);
      }
    } catch (e) {
      //ignoring
    }
  }

  //TODO should we be async to be able to safely switch to diskbased or idb-based storage in the future without breaking our callers ? but our calls are mostly internal anyway and async takes a lot of debugging effort now..
  get(key: string) {
    return this._get(key);
  }

  update(key: string, newsettings: object) { //transactionally update an object  (set(get,...) might race)
    this._set(key, { ...this._get(key), ...newsettings });
  }

  set(key: string, value: unknown) {
    this._set(key, value);
  }

  getChildren(key: string): Map<string, unknown> { //list all keys starting with `<key>.` as a Map
    key = this.prefix + key + '.';

    const result = new Map;
    try {
      if (typeof localStorage !== 'undefined') {
        Object.entries(localStorage)
          .filter(_ => _[0].startsWith(key))
          .forEach(entry => result.set(entry[0].substr(key.length), JSON.parse(entry[1])));
      }
    } catch (e) {
      //ignoring
    }
    Object.entries(vmstorage)
      .filter(_ => _[0].startsWith(key))
      .forEach(entry => result.set(entry[0].substr(key.length), entry[1]));

    return result;
  }
}
