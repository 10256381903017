export function stringifyEvent(evt: { conversation?: { id: string } | string; conversations?: unknown }): string {
  evt = { ...evt };

  if (evt.conversation && typeof evt.conversation == "object")
    evt.conversation = evt.conversation.id;
  if (evt.conversations && Array.isArray(evt.conversations))
    evt.conversations = evt.conversations.map(_ => _.id);

  return JSON.stringify(evt);
}
