/* eslint-disable */
/// @ts-nocheck -- Bulk rename to enable TypeScript validation

/* global process */

export function parseVisitorToken(vstrtoken: string): string {
  //TODO verify hashes BEFORE json,parse...
  //example token; 'vstr:{"id":"user-1","screenname":"firstuser"}@DF7148CB3BB6D8AEE67DCC33B85F69EC8987F25F'.
  const parsedtoken = vstrtoken.match(/^vstr:(.*)@.{40}$/);
  if (!parsedtoken) {
    console.error("Illegal token format", vstrtoken);
    throw new Error("Illegal token format");
  }

  try {
    const visitorinfo = JSON.parse(parsedtoken[1]);
    if (!("id" in visitorinfo))
      throw new Error("Visitortoken is missing ID");

    //rehydrate with stripped fields..
    return {
      screenname: "",
      tickets: [],
      screenflags: [],
      teams: [],
      agentdata: null,
      ...visitorinfo
    };
  } catch (e) {
    console.error("Corrupted token", vstrtoken, e);
    throw new Error("Corrupted token");
  }
}

function str2ab(str) {
  const buf = new ArrayBuffer(str.length * 2); // 2 bytes for each char
  const bufView = new Uint16Array(buf);
  for (let i = 0, strLen = str.length; i < strLen; i++)
    bufView[i] = str.charCodeAt(i);

  return buf;
}

export async function hashChatId(inid) {
  const crypto = process.env.ASSETPACK_ENVIRONMENT === 'window' ? window.crypto : (await import("crypto")).webcrypto;
  const hashBuffer = await crypto.subtle.digest("SHA-1", str2ab(inid));
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const hashHex = hashArray.map(b => b.toString(36)).join('');
  return hashHex;
}

export function isValidSuppliedId(id) {
  return typeof id == 'string' && id.length > 0 && id.length <= 32; //TODO and is printable ASCII/UFS ... what to require?
}

export async function generateUniqueId() {
  const crypto = process.env.ASSETPACK_ENVIRONMENT === 'window' ? window.crypto : (await import("crypto")).webcrypto;
  const u8array = new Uint8Array(16);
  crypto.getRandomValues(u8array);
  return btoa(String.fromCharCode.apply(null, u8array));
}
