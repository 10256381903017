import EventSource from './eventsource';

export default class CueEventSource extends EventSource {
  constructor() {
    super();
  }
  onCueStart(cuetype: string, callback: (cue: unknown) => void) {
    console.warn(`Use of the onCueXXX apis has been deprecated. Use on with option 'filter: data => data.cuetype === "${cuetype}"'`);
    return this.on("cue-start", cue => cue.cuetype == cuetype ? callback(cue) : undefined);
  }
  onCueUpcoming(cuetype: string, callback: (cue: unknown) => void) {
    console.warn(`Use of the onCueXXX apis has been deprecated. Use on with option 'filter: data => data.cuetype === "${cuetype}"'`);
    return this.on("cue-upcoming", cue => cue.cuetype == cuetype ? callback(cue) : undefined);
  }
  onCueEnd(cuetype: string, callback: (cue: unknown) => void) {
    console.warn(`Use of the onCueXXX apis has been deprecated. Use on with option 'filter: data => data.cuetype === "${cuetype}"'`);
    return this.on("cue-end", cue => cue.cuetype == cuetype ? callback(cue) : undefined);
  }
}
