/* eslint-disable */
/// @ts-nocheck -- Bulk rename to enable TypeScript validation

export function getScreenNameInitials(screenname) {
  // Split name on spaces, remove empty parts
  const parts = screenname.split(" ").filter(_ => _ != "");

  if (parts.length == 1) {
    // If there is one part (e.g. only first name), use the uppercased first and lowercased second letter
    let initials = parts[0][0].toUpperCase();
    if (parts[0].length > 1)
      initials += parts[0][1].toLowerCase();
    return initials;
  } else if (parts.length > 1) {
    // If there are more parts, use the uppercased first letter of the first name and uppercased first letter of the last name
    return parts[0][0].toUpperCase() + parts[parts.length - 1][0].toUpperCase();
  }
  return "";
}
