/* eslint-disable */
/// @ts-nocheck -- Bulk rename to enable TypeScript validation

import * as dompack from "dompack";

import CueManager from "@mod-live_api/libliveapi/js/cuemanager";
import { ArchivedConversation } from "@mod-live_api/libliveapi/js/cpconnect/conversation";
import ChatPlaneClient from "@mod-live_api/libliveapi/js/chatplaneclient";

import VideoBinder from "./player/videobinder";
import { getCurrentTime as videoBinderGetCurrentTime } from "./player/videobinder";
import ChatBinder from "./chat/chatbinder";
import ConversationsHandler from "./conversations/conversations";
import ConversationListHandler from "./conversationlist/conversationlist";
import AgentControl from "./agentcontrol/agentcontrol";
import UserStatusHandler from "./userstatus/userstatus";
import * as ondemand from "../pages/testplayer/ondemand";
import * as bindings from './util/bindings';
import { getScreenNameInitials } from '../shared/util';

/* A LiveSiteClient wraps the 'connection' to the WHLive API. It can connect to on-demand sources (eg a playlist), chat archives (TODO),
   wrap a 'live' chatplane 'chatio/broadcastio' connection(*) and connect on demand (for private/website chat) - the latter two
   are mostly implemented by ChatPlaneConnector. The LiveSiteClient needs to abstract away the possible absence of a ChatPlaneConnector

   TODO Rename to something else, it's not really a Connection all the time */
export default class LiveSiteClient extends ChatPlaneClient {
  constructor(context, options) {
    options = {
      starttime: 0,
      ...options
    };
    if (options.videoElement)
      throw new Error(`expect bindVideoElement, not options.videoElement`);

    super(context, options);

    this._cuemanager = new CueManager(cue => this._onCue(cue));
    this._isbroadcast = false;
  }
  get available() {
    return this._chatplaneconnector?.available ?? null;
  }
  get userstatus() {
    return this._chatplaneconnector?.userstatus ?? null;
  }
  get responsivenesscheckstate() {
    return this._chatplaneconnector?.responsivenesscheckstate ?? null;
  }
  get connectionstatus() {
    return this._chatplaneconnector?.connectionstatus ?? null;
  }
  get roomconfig() {
    return this._chatplaneconnector?.roomconfig ?? null;
  }

  getScreenNameInitials(screenname) {
    return getScreenNameInitials(screenname);
  }

  getConversations() {
    return this._chatplaneconnector?.getConversations() ?? [];
  }

  // get groupchat()
  // {
  //   return this._chatplaneconnector?.groupchat ?? this._groupchat;
  // }
  isBroadcast() {
    return this._isbroadcast;
  }
  isArchived() {
    const conversation = this._chatplaneconnector?.groupchat ?? this._chatplaneconnector?.questions ?? this._groupchat ?? this._questions;
    return Boolean(conversation && conversation.roomstate.mode == 'archived');
  }
  isReadOnly() {
    return this.isArchived() || (this._chatplaneconnector && this._chatplaneconnector.isReadOnly());
  }
  isAnonymous() {
    return this._chatplaneconnector?.isAnonymous();
  }
  isAgent() {
    return this._chatplaneconnector?.isAgent();
  }
  isModerator() {
    return this._chatplaneconnector?.isModerator();
  }
  /** @param(boolean) options.allowfullscreen Defaults to true, set to false to prevent viewers from going full screen */
  bindVideoElement(element, options) {
    return bindings.bindElement(this, element, VideoBinder, options);
  }
  bindAgentControl(element, options) {
    return bindings.bindElement(this, element, AgentControl, options);
  }
  bindConversationsElement(element, options) {
    return bindings.bindElement(this, element, ConversationsHandler, options);
  }
  bindConversationListElement(element, options) {
    return bindings.bindElement(this, element, ConversationListHandler, options);
  }
  bindUserStatusElement(element, options) {
    return bindings.bindElement(this, element, UserStatusHandler, options);
  }
  /** @cell(boolean) options.confirmlinks If set, pop up a dialog whenever clicking a chat link */
  bindChatElement(conversation, element, options) {
    return bindings.bindElement(this, element, ChatBinder, conversation, options);
  }
  getCurrentCues() {
    return this._cuemanager.getCurrentCues();
  }
  setVisitorToken(newtoken) //FIXME this API is currently not automatically tested. test seting visitortoken either before or after starting a conversation
  {
    this._options.visitortoken = newtoken;
    if (this._chatplaneconnector && !this._chatinfo.broadcastio) //for broadcasts, there's no need to update the remote visitor token
      this._chatplaneconnector.setVisitorToken(this._options.visitortoken);
  }
  async _setupForChatinfo() {
    if (this._chatinfo.schedule) //looks like a playlist
    {
      //TODO should we start playing on bind (and have a separate player for every element?) or should they share the elements? unsure about the use cases
      //     we should probably NOT AUTOPLAY ourselves and that will fix most of these issues! ... (but not invoking .play() isn't sufficient for that, we still need to load thumbnails and player chrome!)
      this._vodplayer = new ondemand.VODPlayer(this._chatinfo, this._cuemanager);
      this._vodplayer.setCurrentTime(this._options.starttime);
    } else if (this._chatinfo.mode == 'archived' && ['groupchat', 'questions'].includes(this._chatinfo.roomtype)) {
      this["_" + this._chatinfo.roomtype] =  //set either _groupchat or _questions
        new ArchivedConversation(this._chatinfo.roomtype, this._chatinfo, url => this._context.fetchJSON(url),
          {
            replayfrom: this._options.replayfrom,
            replayuntil: this._options.replayuntil,
            roomtype: this._chatinfo.roomtype,
            baseurl: this._baseurl
          });
      this["_" + this._chatinfo.roomtype].on("roomstate", state => this._gotState({ chatmode: state.mode }));
      this._chatplaneInitPromise.resolve(new Error("Not a chatplane client"));
    } else {
      super._setupForChatinfo();
    }
  }

  async disconnect() {
    //TODO unbind video elements? that would still make sense for vodplayers etc?
    if (this._chatplaneconnector) {
      this._chatplaneconnector.disconnect();
      this._chatplaneconnector = null;
    }

    this._context.unregisterClient(this);
    bindings.unbindAll(this);
    return;
  }

  //Get the estimated serverside Date.now() in ms since epoch (broadcast connections). Return null if this is not a broadcast
  getLiveNow() {
    if (this._isbroadcast)
      return this._chatplaneconnector?._cpconnection.getServerTime() || null;
    return null;
  }

  //Get the estimated video currenttime, calculated over all videos in seconds since the start of the first video. Return null if this is not a VOD
  getCurrentTime() {
    if (!this._vodplayer)
      return null;

    const timeinfo = this._vodplayer.getCurrentTime();
    return timeinfo.time + (timeinfo.addcurrentclock ? videoBinderGetCurrentTime(this) : 0);
  }

  setCurrentTime(reltime) {
    if (!this._vodplayer)
      throw new Error("Can only set time on VOD sources");

    this._vodplayer.setCurrentTime(reltime);
  }

  //Get the estimated total play time for all videos. Return null if this is not a VOD
  getTotalTime() {
    if (!this._vodplayer)
      return null;
    return this._vodplayer.getTotalTime();
  }

  vote(votetoken, pollvote) {
    const visitortoken = this._options.visitortoken;
    if (!visitortoken)
      throw new Error("Cannot vote when visitor isn't set");
    if (!this._isbroadcast)
      throw new Error("Cannot only vote for broadcasts"); // cause we need the connection to the chatplane

    this._chatplaneconnector._cpconnection.request({
      type: "vote",
      vote: pollvote,
      id: votetoken,
      visitortoken
    });
  }

  setUserStatus(newstatus) {
    return this._chatplaneconnector.setUserStatus(newstatus);
  }

  setConnectionStatus(newstatus) {
    return this._chatplaneconnector.setConnectionStatus(newstatus);
  }

  markResponsive() {
    return this._chatplaneconnector.markResponsive();
  }

  _gotDynamicCuesUpdate({ cues }) {
    //update video cues to add their starttime to the cue data. as broadcast starttimes don't normally change, any change should be considered a restart of the video
    cues.forEach(cue => {
      if (cue.cuetype == "whlive:mainvideo")
        cue.data._start = cue.playat || cue.start;
    });
    this._cuemanager.update(cues);
  }
  _onCue(data) {
    //data.event will be one of cue-start/cue-upcoming/cue-end
    this.emit(data.event, data.cue);
  }

}
