/* eslint-disable */
/// @ts-nocheck -- Bulk rename to enable TypeScript validation

import "./dialog.scss";
export { runMessageBox } from 'dompack/api/dialog';
import * as dialogapi from 'dompack/api/dialog';
import * as dompack from 'dompack';

class LiveDialog extends dialogapi.DialogBase {
  constructor(options) {
    super(options);
    this._modalitynode = <div class="whlive-dialog" onClick={evt => this._onModalityClick(evt)}>
      {this.holdernode = <div class="whlive-dialog__positioning">
        <div class="whlive-dialog__dialogbox">
          {this.contentnode = <div class="whlive-dialog__message"></div>}
          {this.buttonsnode = <div class="whlive-dialog__buttons"></div>}
        </div>
      </div>
      }
    </div>;

    if (options?.theme)
      this._modalitynode.classList.add(options.theme);

    //TODO will move to WH!
    options?.signal?.addEventListener("abort", () => { this.resolve(null); });
  }

  _openDialog() {
    document.body.appendChild(this._modalitynode);
  }

  closeDialog() {
    dompack.remove(this._modalitynode);
    super.closeDialog();
  }

  _onModalityClick(evt) {
    if (this.holdernode.contains(evt.target))
      return; //event was targetted at our holder

    dompack.stop(evt);
    if (this.options.allowcancel)
      this.resolve(null);
  }

  _onKeyDown(evt) {
    if (evt.keyCode == 27 && this.options.allowcancel) //allow escape to cancel the dialog
    {
      dompack.stop(evt);
      this.resolve(null);
    }

    if (this.holdernode.contains(evt.target))
      return; //key events targetted to our dialog are okay

    //FIXME this causes open dialogs to even block reloads etc...
    evt.preventDefault();
    evt.stopPropagation();
  }

  afterShow() {
    dompack.registerMissed(this.holdernode);
  }
}

dialogapi.setupDialogs(options => new LiveDialog(options), { messageboxclassbase: "whlive-dialog" });
