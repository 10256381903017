/* eslint-disable */
/// @ts-nocheck -- Bulk rename to enable TypeScript validation

import * as dompack from '@webhare/dompack';
//dist/hls.js should solve some issues with webworker packaging, see https://github.com/video-dev/hls.js/issues/187 en https://github.com/video-dev/hls.js/issues/2910
// import Hls from 'hls.js/dist/hls.js';
///@ts-ignore -- no typings available
import RangeSlider from "rangeslider-pure";
// import * as pxl from "@mod-consilio/js/pxl";
import VideoDebugger from "./videodebugger";
import * as storage from "./storage";
import * as fullscreen from './fullscreen';
import { equalJSONObjects } from "@mod-live_api/libliveapi/js/util/cueparsing";
import * as playerstructure from '../../src/player/playerstructure';
import { flags } from '@webhare/env';
import { createDeferred } from '@webhare/std';

//FIXME can't do this generically, we're killing potential other /.px/ usage... ?
//FIXME pxl.setPxlOptions({recordurl: "/.px"}); //cloudfront can't do /.px/ so unfortunately... consilio should probably adapt or support both

interface VideoRequest {
  estimate_ms?: number;
  height?: number;
  onEnd?: unknown;
  type: string;
  url: string;
  width?: unknown;
  _start: string;
  islive?: boolean;
}

const thisscript: HTMLScriptElement = document.currentScript! as HTMLScriptElement;
if (!thisscript)
  console.error("document.currentScript is not set. Incorrect bundler?");

function promiseHLS() {
  let hlspath = null;
  //TODO skip loading hls.js if this device wouldnt support it (ie iphone)

  //TODO if our esbuild could be taught to add additional assets to every bundle, we wouldnt need all the special hls.js handling
  if (thisscript.getAttribute("src")!.startsWith("/.ap/live_api.apisite/")) //this is a 'local' player
    hlspath = new URL("/.live_api/hlsjs.dist/hls.js", thisscript.src).toString(); //but use the resolved .src to locate our script
  else
    hlspath = new URL("hls.js", thisscript.src).toString(); //but use the resolved .src to locate our script

  return dompack.loadScript(hlspath);
}

const loadhlspromise = promiseHLS();

function tryPreconnect(url: string) {
  const origin = new URL(url).origin;
  if (!document.querySelector(`link[rel=preconnect][href="${origin}"]`))
    document.body.appendChild(<link rel="preconnect" href={origin} />);
}

// Check if changing volume is supported (it's not on iOS)
function canChangeVolume() {
  if (!canChangeVolume.promise) {
    canChangeVolume.promise = new Promise(resolve => {
      const vid = document.createElement("video");
      const vol = vid.volume;
      vid.volume = vid.volume / 2;
      requestAnimationFrame(() => resolve(vid.volume != vol));
    });
  }
  return canChangeVolume.promise;
}


const videoeltdata = new WeakMap;

// Administration data for video element
class VideoEltData {
  constructor() {
    // Resolved when video has been loaded and can be played
    this.videoLoadedPromise = null;

    // Resolved when video has been started (play() has returned)
    this.videoStartedPromise = null;

    // 'hash' for current playing video
    this.videoHash = null;

    // HLS object
    this.hls = null;

    // State change stuff
    this.stateChange = null;
  }
}

// export function preloadVideo(videodata)
// {
//   updateVideos(this.currentvideo, videodata, 0);
// }

function formatTime(time) {
  time = Math.round(time);
  let formatted = ("0" + (time % 60)).substr(-2);
  time = Math.floor(time / 60);
  if (time > 0) {
    const minutes = time % 60;
    time = Math.floor(time / 60);
    if (time > 0)
      formatted = time + ":" + (("0" + minutes).substr(-2)) + ":" + formatted;
    else
      formatted = minutes + ":" + formatted;
  } else
    formatted = "0:" + formatted;
  return formatted;
}

function asyncSleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export default class VideoController {
  currentvideo: VideoRequest | null = null;
  activeImageElement: HTMLImageElement | null = null;
  activeIframeElement: HTMLIFrameElement | null = null;
  activeVideoElement: HTMLVideoElement | null = null;
  nextVideoElement: HTMLVideoElement | null = null;
  stillcontainer: HTMLDivElement = <div class="whlive-player__stillcontainer" />;
  playernode: HTMLElement;
  positionknob: HTMLDivElement | null = null;
  videoElements: HTMLVideoElement[] = [];

  constructor(videobinder, playernode, broadcast, options) {
    this.videobinder = videobinder;
    this.options = { ...options };
    if (flags["whlive-videocontroller"])
      this.options.debug = true;

    //Build a _debugLog that doesn't mess up console line numbers
    if (this.options.debug)
      this._debugLog = Function.prototype.bind.call(console.log, console, "[videocontroller-debug]") as (...args: unknown[]) => void;

    this._pending_raf_videoprogress = false;
    this.initPromise = createDeferred();

    this.laststate = null;
    this.currentmasterplaylist = null;
    this.videoElements = [];
    this.nextVideoElement = null;
    this.playernode = playernode;
    this.fullscreennode = this.options.fullscreenelement || this.playernode;
    this.volumenode = null;
    this.positionnode = null;
    this.positionknob = null;
    this.curtimenode = null;
    this.durationnode = null;
    this.volumecomp = null;
    this.positioncomp = null;
    this._changingVolume = null;
    this._changingPosition = null;
    this.forcedtimefunc = null; //time is forced to absolute values... and a good clue that we're broadcasting
    this.intervaltimecheck = null;

    this.pxl_maxerrs = 10;
    this.pxl_maxseeks = 10;
    this.errorcount = 0;

    this.isFullscreen = false;
    this.toggleFullscreen = null; // callback
    this.playAfterFullscreen = false; // iOS: resume playback after exiting fullscreen

    this.askForAudio = false; //set when our attempt to enable audio was blocked and no user interaction has happened yet

    this._controlCheckTimer = null;
    this._controlTimer = null; // timers for showing/hiding the video controls

    this.isbroadcast = broadcast;
    this._asyncInit(options);
  }

  async _asyncInit(options) {
    options = options || {};

    this.volumenode = <input type="range" class="whlive-player__volume__slider-input" min="0" max="100" step="1" />;
    this.volumenode.style.display = "none";
    this.curtimenode = <div class="whlive-player__position__curtime">0:00</div>;
    this.durationnode = <div class="whlive-player__position__duration">0:00</div>;//FIXME: For live streams, we don't have a duration, show "live"?

    let positionindicator;
    if (!this.isbroadcast) {
      this.positionnode = <input type="range" class="whlive-player__position__slider-input" min="0" max="0" step="1" />;
      positionindicator =
        <div class="whlive-player__control whlive-player__position">
          {[
            this.curtimenode,
            <div class="whlive-player__position__slider">
              {this.positionnode}
            </div>,
            this.durationnode
          ]}
        </div>;
    }

    const controlbarnode =
      <div class="whlive-player__controlbar">
        <div class="whlive-player__control whlive-player__togglesound" onClick={e => this._clickToggleSound(e)} >
          <div class="whlive-player__togglesound__image" />
          <div class="whlive-player__togglesound__text" />
        </div>
        <div class="whlive-player__control whlive-player__volume">
          <div class="whlive-player__volume__slider">
            {this.volumenode}
          </div>
        </div>
        <div class="whlive-player__control whlive-player__playstate" onClick={e => this._clickPlayState(e)}>
          <div class="whlive-player__playstate__image" />
          <div class="whlive-player__playstate__text" />
        </div>
        {positionindicator}
        <div class="whlive-player__control whlive-player__fullscreen" onClick={e => this._clickFullscreen(e)}>
          <div class="whlive-player__fullscreen__image" />
          <div class="whlive-player__fullscreen__text" />
        </div>
        <div class="whlive-player__control whlive-player__islive">
          <div class="whlive-player__islive__image" />
          <div class="whlive-player__islive__text" />
        </div>
      </div>;
    this.player = playerstructure.ensurePlayer(this.playernode);
    this.player.videoholder.replaceChildren(<div class="whlive-player__videocontainer whlive-player__videocontainer--active">
      <video class="whlive-player__videoelement"></video>
    </div>
      , <div class="whlive-player__videocontainer">
        <video class="whlive-player__videoelement"></video>
      </div>
      , this.stillcontainer);

    this.player.interfaceholder.replaceChildren(
      <div class="whlive-player__enablesound" onClick={e => this._clickEnableSound(e)} >
        <div class="whlive-player__enablesound__image" />
        <div class="whlive-player__enablesound__text">Enable sound</div>
      </div>
      , controlbarnode);  //FIXME getTid


    /*

              <div class="whlive__previousvideo" onClick={clickPreviousVideo}>
          <div class="whlive__previousvideo__image" />
          <div class="whlive__previousvideo__text" />
        </div>
        <div class="whlive__nextvideo" onClick={clickNextVideo}>
          <div class="whlive__nextvideo__image" />
          <div class="whlive__nextvideo__text" />
        </div>
*/

    // Replace range inputs with slider controls
    if (this.volumenode)
      canChangeVolume().then(() => this._setupVolumeNode());

    if (this.positionnode) {
      this.positioncomp = new RangeSlider(this.positionnode,
        {
          onSlideStart: () => { this._changingPosition = true; },
          onSlide: e => this._onPositionChanging(e),
          onSlideEnd: e => this._onPositionChanged(e)
        });
      //    initPositionKnobValue();
    }

    new VideoDebugger(this.playernode);

    this._updateStateClasses();

    this.toggleFullscreen = this.options.allowfullscreen ? fullscreen.getFullscreenMode() : false;

    this.videoElements.push(...this.playernode.querySelectorAll("video"));

    //get audio state. true/false = explicitly set by user, null = not really known yet
    const sessionAudioEnabled = storage.getSession("whliveAudioEnabled");

    this._debugLog("initialize video elements", { sessionAudioEnabled });

    // Ensure the videos start muted, playinline attribute is set and
    // callbacks are enabled
    for (const elt of this.videoElements) {
      videoeltdata.set(elt, new VideoEltData);
      elt.muted = sessionAudioEnabled === false; //so if null (unknown) or true (user set), we un-mute. we might someday offer an option to keep the video initially muted unless explicitly set
      elt.setAttribute("playsinline", "");
      elt.addEventListener("ended", e => this._onVideoEnded(e));
      elt.addEventListener("play", e => this._onVideoPlay(e));
      elt.addEventListener("pause", e => this._onVideoPause(e));
      elt.addEventListener("volumechange", e => this._onVideoVolumeChanged(e));
      elt.addEventListener("durationchange", e => this._onVideoDuration(e));
      if (this.toggleFullscreen === "video")
        elt.addEventListener("webkitendfullscreen", e => this._onEndFullscreen(e));
      // FIXME: handle error event! (retries?)
    }

    this._hovercallback = e => this._onVideoHover(e);
    this.playernode.addEventListener("mousemove", this._hovercallback);
    this.playernode.addEventListener("touchmove", this._hovercallback);
    this.fullscreennode.addEventListener(fullscreen.getFullscreenEventName(), e => this.onFullscreenStatus(e));
    controlbarnode.addEventListener("mouseenter", e => this._onControlBarEnter(e));
    controlbarnode.addEventListener("mouseleave", e => this._onControlBarLeave(e));

    this.initPromise.resolve();

    if (options.debug)
      this.statereporter = setInterval(() => this._reportState(), 1000);
  }

  destroy() {
    if (!this.playernode)
      throw new Error(`Already removed`);
    if (this.statereporter)
      clearInterval(this.statereporter);

    this.stopVideo();
    this.playernode.removeEventListener("mousemove", this._hovercallback);
    this.playernode.removeEventListener("touchmove", this._hovercallback);
    this.player.videoholder.replaceChildren();
    this.player.interfaceholder.replaceChildren();
    this.playernode = null;
    this.player = null;
  }

  _debugLog() {
    //if debug is enabled, the constructor will replace us with a proper version
  }

  _setupVolumeNode() {
    //we're delayed until we're sure that volume changing is possible
    this.volumecomp = new RangeSlider(this.volumenode,
      {
        vertical: true,
        onSlideStart: () => { this._changingVolume = true; },
        onSlide: volume => this._onVolumeChanging(volume),
        onSlideEnd: volume => this._onVolumeChanged(volume)
      });
  }

  _onVideoEnded(e) {
    if (!this.activeVideoElement || !this.currentvideo || e.target !== this.activeVideoElement) //dupe event?
      return;

    this.playAfterFullscreen = false; // No need to resume playback
    this._updateStateClasses();

    const endedvideo = this.currentvideo;
    this.currentvideo = null;

    if (endedvideo.onEnd)
      endedvideo.onEnd();

    if (endedvideo.islive && this.currentmasterplaylist) {
      //Check if we're flagged as broadcast
      const broadcastid = this._getPlaylistBroadcastId(this.currentmasterplaylist);
      if (broadcastid) {
        this._debugLog("try to restart livestream, the feed may resume. last broadcast = " + broadcastid);
        this.playVideo(endedvideo);
      } else {
        this._debugLog("livestream has ended");
      }
    }

    setTimeout(() => this._checkVideoDone(), 1);
  }

  _checkVideoDone() {
    if (!this.currentvideo) //noone started a new video
      this.exitFullscreenVideo();
  }

  playVideo(videodata) {
    //FIXME preload and multivideo?  time?
    this._updateVideos(videodata, null, 0);
  }

  informUpcomingVideo(mutation) {
    const timetillvideo = mutation.cue.playat - Date.now();
    if (timetillvideo > 0) {
      const startconnect = Math.random() * Math.min(20000, timetillvideo);
      setTimeout(() => tryPreconnect(mutation.cue.data.url), timetillvideo);
    }
  }

  _reportState() {
    //naming it -debug to show which ones where behind the debug flag
    if (this.activeVideoElement)
      this._debugLog(`${new Date().toISOString()} ct=${this.activeVideoElement.currentTime} paused=${this.activeVideoElement.paused}`);
    else if (this.activeImageElement)
      this._debugLog(`${new Date().toISOString()} img=${this.activeImageElement.src}`);
    else
      this._debugLog(`${new Date().toISOString()} no active element`);
  }

  _clickFullscreen() {
    this._debugLog("clickFullscreen", { fullscreen: this.isFullscreen });
    if (this.toggleFullscreen === "video") {
      // Because webkitDisplayingFullscreen is true in the 'pause' event _after_ the 'webkitendfullscreen' already has fired,
      // so we just keep the state ourselves
      this.isFullscreen = true;
      // The fullscreen action is only visible when the video is not displayed fullscreen, so if this action is called, we
      // can always just go fullscreen
      this.activeVideoElement.webkitEnterFullscreen();
    } else if (this.toggleFullscreen) {
      this.toggleFullscreen(this.fullscreennode);
    }
    this._updateStateClasses();
  }

  onFullscreenStatus(e) {
    this.isFullscreen = fullscreen.isFullscreen(this.fullscreennode);
    this.fullscreennode.classList.toggle("whlive-player--fullscreenroot", this.isFullscreen);
    this._updateStateClasses();
  }

  _clickEnableSound() {
    this.askForAudio = false;
    for (const player of this.videoElements)
      player.muted = false;

    storage.setSession("whliveAudioEnabled", true);
    this._updateStateClasses();
  }

  _clickToggleSound() {
    this.askForAudio = false;
    this.activeVideoElement.muted = !this.activeVideoElement.muted;
    storage.setSession("whliveAudioEnabled", !this.activeVideoElement.muted);
    this._updateStateClasses();
  }

  _updateStateClasses() {
    if (!this.playernode)
      return; //already removed from DOM, this was a lingering call

    const state = this._getCurrentState();
    if (!this.laststate || !equalJSONObjects(this.laststate, state)) {
      this.laststate = state;
      this.videobinder.emit("videostate", state);
      Object.keys(state).forEach(node => this.playernode.classList.toggle("whlive-player--" + node, state[node]));

      if (this.volumenode) {
        this.volumenode.disabled = state.canunmute;
        this.volumecomp?.update();
      }
      if (this.positionnode) {
        this.positionnode.disabled = state.isbroadcast || !this.activeVideoElement;
        this.positioncomp.update();
      }
    }

    if (this.volumecomp) {
      const volume = this.activeVideoElement && this.activeVideoElement.volume;
      if (volume !== undefined && !this._changingVolume && this.activeVideoElement)
        this.volumecomp.update({ value: Math.round(this.activeVideoElement.volume * 100) }, false);
    }
  }
  _onVideoPlay(e) {
    // If this is a fullscreen iPhone video, set this.playAfterFullscreen to true when the video started playing
    if (this.toggleFullscreen === "video" && this.isFullscreen)
      this.playAfterFullscreen = true;

    this._updateStateClasses();
    this._onVideoProgress(); //also starts a raf timer
  }
  async _onVideoPause(e) {
    // If this is a fullscreen iPhone video, set this.playAfterFullscreen to false when the video stops playing
    if (this.toggleFullscreen === "video" && this.isFullscreen)
      this.playAfterFullscreen = false;
    // If we just exited fullscreen while playing, resume playback
    else if (this.playAfterFullscreen) {
      this.playAfterFullscreen = false;
      await this.activeVideoElement.play();
    }
    this._updateStateClasses();
  }

  _onVideoVolumeChanged(e) {
    if (!this.activeVideoElement)
      return;

    if (!this._changingVolume && this.volumecomp)
      this.volumecomp.update({ value: Math.round(this.activeVideoElement.volume * 100) }, false);

    this._updateStateClasses();
  }

  _onPositionChanging(position) {
    const settime = position / 100;
    this._seekTo(settime, "positionchange");

    setTimeout(() => this._updateStateClasses(), 1); //apparently need to delay for the .ended property to go away
  }


  async _playPauseVideo(video, setplaying) {
    this._debugLog("playPauseVideo start, setplaying:" + setplaying);
    this.askForAudio = false; //succesfully started, so no reason to ask for anything

    for (let i = 0; i < 3; ++i) {
      if (this.forcedtimefunc && setplaying && this.activeVideoElement) {
        const newtime = this.forcedtimefunc(this.currentvideo, this.activeVideoElement.currentTime, this.activeVideoElement.duration);
        if (newtime != null)
          this._seekTo(newtime, "_playPauseVideo forcedtime");
      }

      if (setplaying) {
        try {
          await this.startVideoElementPlay(video); //video.play wrapper with mock support
          this._checkForcedTime();
          this._debugLog("playPauseVideo video.play() resolved succesfully");
          break;
        } catch (e) {
          this._debugLog("playPauseVideo error", e.toString());
          if (!video.muted) {
            this._debugLog("playPauseVideo, trying muted play");

            // assume playing failed due to autoplay with audio restrictions
            storage.setSession("whliveAudioEnabled", null);
            this.askForAudio = true; //we got blocked! ask user to enable audio for us
            for (const elt of this.videoElements)
              elt.muted = true;
            --i;
            continue;
          }
          this._debugLog(`failed pause, iter ${i}`, video, setplaying, e);
        }
      } else {
        try {
          await video.pause();
          this._debugLog("playPauseVideo going to pause");
          break;
        } catch (e) {
          this._debugLog(`failed pause, iter ${i}`, video, setplaying, e);
        }
      }

      // Wait a bit before next try
      await asyncSleep(100);
    }

    this._updateStateClasses();
  }

  async _setVideoPlayState(video, playing) {
    const eltdata = videoeltdata.get(video);
    eltdata.targetplayingstate = playing;
    this._debugLog("setVideoPlayState targetplayingstate", eltdata.targetplayingstate);

    if (eltdata.stateChange) {
      while (eltdata.stateChange.pending !== null) {
        if (eltdata.stateChange.pending === playing)
          return;
        await eltdata.stateChange.promise;
        this._debugLog("setVideoPlayState looping for targetplayingstate", eltdata.targetplayingstate);
      }
    }

    // make sure callback references this (new) object, eltdata.stateChange is cleared on stop
    const stateChange =
    {
      pending: playing,
      promise: this._playPauseVideo(video, eltdata.targetplayingstate).then(() => { stateChange.pending = null; })
    };

    eltdata.stateChange = stateChange;
    await eltdata.stateChange.promise;
  }

  _onPause(event) {
    this._updateStateClasses();
    if (this.activeVideoElement && this.isbroadcast && !this.activeVideoElement.ended) //why did we pause ? we don't expose a PAUSE control during live?
      this._setVideoPlayState(this.activeVideoElement, true); //broadcasting.. the show must go on!
  }

  _onPlay(event) {
    this._updateStateClasses();
  }

  private stopStillContainer() {
    this.playernode.classList.remove("whlive-player--framecontrol");
    this.stillcontainer.classList.remove("whlive-player__stillcontainer--active");
    this.stillcontainer.replaceChildren();
    this.activeIframeElement = null;
    this.activeImageElement = null;
  }

  // This code hacks into the replaced component, so it will break if the internal structure of the slider replacement changes
  _initPositionKnobValue() {
    const sliderKnob = dompack.qR(this.positioncomp.node, ".wh-slider-knob");
    this.positionknob = <div class="whlive-player__position__slider-knob"></div>;
    sliderKnob.after(this.positionknob);
    sliderKnob.addEventListener("mouseenter", event => this.positionknob.classList.add("whlive-player__position__slider-knob--visible"));
    sliderKnob.addEventListener("mouseleave", event => this.positionknob.classList.remove("whlive-player__position__slider-knob--visible"));
  }

  _onVideoHover(event) {
    // Use this._controlCheckTimer to check only once every 100ms
    if (!this._controlCheckTimer) {
      this._controlCheckTimer = setTimeout(() => {
        this._showVideoControls();
        this._controlCheckTimer = null;
      }, 100);

      // If the controls aren't visible now, show them immediately (so the user doesn't have to wait 100ms)
      if (!this.playernode.classList.contains("whlive-player--showcontrols"))
        this._showVideoControls();
    }
  }

  _showVideoControls() {
    if (this._shoulddisplaycontrols) //dupe _showVideoControls call
      return;

    // Show the controls if not visible yet, and tell ourselves we're managing the controls  (so if someone externally sets whlive-player--showcontrols we levae it alone)
    this._shoulddisplaycontrols = true;
    this._manageshowcontrols = !this.playernode.classList.contains("whlive-player--showcontrols");
    if (this._manageshowcontrols)
      this.playernode.classList.add("whlive-player--showcontrols");

    // Reset control hide timer
    clearTimeout(this._controlTimer);
    // Automatically hide controls after 1250ms
    this._controlTimer = setTimeout(() => this._hideVideoControls(), 1250);
  }

  _hideVideoControls() {
    if (!this._shoulddisplaycontrols) //we're already hidden
      return;

    if (this._manageshowcontrols) //we added it above, so we can now remove it
      this.playernode.classList.remove("whlive-player--showcontrols");

    this._shoulddisplaycontrols = false;
  }

  _onControlBarEnter(event) {
    this._showVideoControls();
  }

  _onControlBarLeave(event) {
    // Set the control hide timer again
    this._controlTimer = setTimeout(() => this._hideVideoControls(), 1250);
    // Enable onVideoHover
    this._controlCheckTimer = null;
  }

  _isVideoPlaying() {
    return Boolean(this.activeImageElement  //FIXME mock pause support on images for VOD
      || this.activeIframeElement
      || (this.activeVideoElement && !this.activeVideoElement.paused));
  }
  _isVideoLooping() {
    return Boolean(this.activeVideoElement && this.activeVideoElement.loop);
  }

  _getCurrentState() {
    // If toggleFullscreen is set to "video", we're responsible for entering and exiting the video's fullscreen mode on iOS
    const canGoFullscreen = (this.toggleFullscreen === "video" && this.activeVideoElement && this.activeVideoElement.webkitSupportsFullscreen)
      || (this.toggleFullscreen !== "video" && Boolean(this.toggleFullscreen));
    const isplaying = this._isVideoPlaying();

    /// "muted" is shown when we're playing video but our intentional attempt to start audio failed due to the browser blocking it. It's generally bound to a big "Enable sound!" button
    const askforaudio = Boolean(this.askForAudio && this.activeVideoElement && !this.activeVideoElement.paused && this.activeVideoElement.muted);
    return { /// "muted" is shown when we're playing video but our intentional attempt to start audio failed due to the browser blocking it. It's generally bound to a big "Enable sound!" button
      askforaudio: askforaudio,
      muted: askforaudio, //--muted is deprecated! this was confusing versus --canunmute
      hascontent: Boolean(this.activeVideoElement || this.activeImageElement || this.activeIframeElement),
      hasvideo: Boolean(this.activeVideoElement && this.activeVideoElement.src),
      videoend: Boolean(this.activeVideoElement && this.activeVideoElement.ended),
      playing: isplaying,
      paused: Boolean(this.activeVideoElement && this.activeVideoElement.paused && !this.activeVideoElement.ended),
      isbroadcast: this.isbroadcast,
      islive: isplaying && this.isbroadcast,
      islivestream: Boolean(this.currentvideo && this.currentvideo.islive),
      canmute: Boolean(this.activeVideoElement && !this.activeVideoElement.muted),
      /// canunmute is shown whenever audio is mutd. It's generally used only for the control bar
      canunmute: Boolean(this.activeVideoElement && this.activeVideoElement.muted),
      canvolume: Boolean(this.activeVideoElement && this.volumenode),
      //Allow canplay when in broadcast just to be sure, even though we should never get into this state
      canplay: Boolean(this.activeVideoElement && this.activeVideoElement.paused && (!this.activeVideoElement.ended || !this.isbroadcast)),
      canpause: isplaying && !this.isbroadcast,
      canreqfs: Boolean(this.activeVideoElement && canGoFullscreen), //don't allow to reqfs if we're already full screen
      isfullscreen: Boolean(this.isFullscreen)
    };
  }

  _clickPlayState() {
    const el = videoeltdata.get(this.activeVideoElement);
    this._debugLog("clickPlayState", {
      playing: el && el.playing, //FIXME this is a diffrent playing... ?!
      paused: this.activeVideoElement ? this.activeVideoElement.paused : null,
      isVideoPlaying: this._isVideoPlaying()
    });

    if (this._isVideoPlaying() && this.isbroadcast)
      return;  //don't pause broadcasts
    if (!el)
      return; //no video is active

    el.playing = !this._isVideoPlaying();
    this._setVideoPlayState(this.activeVideoElement, el.playing);
  }

  _clickNextVideo() {
  }

  _clickPreviousVideo() {
  }


  exitFullscreenVideo() {
    if (this.toggleFullscreen === "video") {
      if (this.isFullscreen)
        this.activeVideoElement.webkitExitFullscreen();
    } else {
      if (fullscreen.isFullscreen(this.fullscreennode))
        this._clickFullscreen();
    }
  }

  stopVideo() {
    this._updateVideos(null, null, 0);
  }

  _getPlaylistBroadcastId(playlist) {
    const broadcastline = playlist.split('\n').filter(_ => _.startsWith('#EXT-X-SESSION-DATA:DATA-ID="BROADCAST-ID",VALUE="'))[0];
    if (broadcastline)
      return broadcastline.split('"')[3] || null;
    else
      return null;
  }

  _onVolumeChanging(volume) {
    if (this.activeVideoElement)
      this.activeVideoElement.volume = volume / 100;
  }

  _onVolumeChanged(volume) {
    this._onVolumeChanging(volume);
    this._changingVolume = false;
  }


  _onPositionChanged(position) {
    this._onPositionChanging(position);
    this._changingPosition = false;
  }

  _onVideoDuration(e) {
    if (this.activeVideoElement && this.positioncomp && !this._changingPosition)
      this.positioncomp.update({ max: Math.round(this.activeVideoElement.duration * 100) }, false);
    this.durationnode.textContent = this.activeVideoElement ? formatTime(this.activeVideoElement.duration) : "";
    this._onVideoProgress();
  }

  _onVideoProgress(e) {
    this.curtimenode.textContent = this.activeVideoElement ? formatTime(this.activeVideoElement.currentTime) : "";

    if (this.activeVideoElement && this.positioncomp && !this._changingPosition)
      this.positioncomp.update({ value: Math.round(this.activeVideoElement.currentTime * 100) }, false);

    if (!this._pending_raf_videoprogress && this._isVideoPlaying()) {
      this._pending_raf_videoprogress = true;
      requestAnimationFrame(e => this._onVideoProgressFrame());
    }
  }

  _onVideoProgressFrame() {
    this._pending_raf_videoprogress = false;
    this._onVideoProgress();
  }

  /** Load the video, with preload support
      @param curvideo Video that must be played
      @param nextvideo Optional video to preload
      @param currenttime Seek position (not supported)
  */
  async _updateVideos(curvideo: VideoRequest, nextvideo, currenttime) {
    await this.initPromise.promise;

    // save the old elements, for debugging
    const org_active = this.activeVideoElement;
    const org_next = this.nextVideoElement;

    // save the current video (so preloadVideo can use it)
    this.currentvideo = curvideo;
    this.currentmasterplaylist = null; //wait for a new playlist

    if (curvideo) {
      document.documentElement.classList.remove("whlive-player--videoend");

      // get the 'hash' for the video that should be playing now
      const enc = JSON.stringify({ url: curvideo.url, instanceid: curvideo.instanceid });

      let needstart = false;
      if (this.nextVideoElement && videoeltdata.get(this.nextVideoElement).videoHash === enc) {
        // this.nextVideoElement has preloaded the requested video
        if (this.activeVideoElement) {
          // another video is playing, stop it
          this.activeVideoElement.onpause = null;
          this.activeVideoElement.onplay = null;
          this._setVideoPlayState(this.activeVideoElement, false);
          videoeltdata.get(this.activeVideoElement).videoHash = null;
          videoeltdata.get(this.activeVideoElement).playing = false;

        }

        this.stopStillContainer();

        // next is ready for playing
        this.activeVideoElement = this.nextVideoElement;
        needstart = true;
      } else {
        // next video isn't playing of preloading, hard reload
        if (this.activeVideoElement) {
          // another video is playing, stop it
          this.activeVideoElement.onpause = null;
          this.activeVideoElement.onplay = null;
          this._setVideoPlayState(this.activeVideoElement, false);
          videoeltdata.get(this.activeVideoElement).videoHash = null;
          videoeltdata.get(this.activeVideoElement).playing = null;

          this.activeVideoElement = null;
        }

        this.stopStillContainer();
        if (curvideo.type == "text/html") {
          this.activeIframeElement = <iframe src={curvideo.url} class="whlive-player__stillcontainer__iframe" allowfullscreen="allowfullscreen" allow="autoplay" />;
          this.stillcontainer.append(this.activeIframeElement!);
          this.stillcontainer.classList.add("whlive-player__stillcontainer--active");
          this.playernode.classList.add("whlive-player--framecontrol");
        } else if (curvideo.type.startsWith("image/")) {
          this.activeImageElement = <img class="whlive-player__stillcontainer__image" src={curvideo.url} />;
          this.stillcontainer.append(this.activeImageElement!);
          this.stillcontainer.classList.add("whlive-player__stillcontainer--active");
        } else {
          this.stopStillContainer();

          // video not loaded yet, select a new active element
          this.activeVideoElement = this.videoElements[0];
          this.activeVideoElement.onpause = e => this._onPause();
          this.activeVideoElement.onplay = e => this._onPlay();
          videoeltdata.get(this.activeVideoElement).videoHash = enc;
          videoeltdata.get(this.activeVideoElement).playing = false;

          // // init the video, start preloading at the current time position
          let ofs;
          if (this.forcedtimefunc)
            ofs = this.forcedtimefunc(this.currentvideo, null, null);
          else
            ofs = (this.currentvideo.offset_ms || 0) / 1000;

          this._initVideoElt(this.activeVideoElement, curvideo, { eltname: "active", ofs });
          needstart = true;
        }
      }

      if (needstart) {
        const eltdata = videoeltdata.get(this.activeVideoElement);
        eltdata.videoStartedPromise = eltdata.videoLoadedPromise.then(() => {
          this._debugLog("-- ready promise resolved, waiting for canplay");
          const curelt = this.activeVideoElement;

          // update the time from scratch again, hopefully we have the right position in the buffer
          if (this.forcedtimefunc) {
            const newtime = this.forcedtimefunc(this.currentvideo, null, this.activeVideoElement.duration);
            if (newtime != null)
              this._seekTo(newtime, "_updateVideos forcedtime");
          }

          return this._setVideoPlayState(this.activeVideoElement, true).then(e => {
            this._debugLog("-- play initiated");

            if (curelt === this.activeVideoElement && videoeltdata.get(this.activeVideoElement).videoHash === enc)
              videoeltdata.get(this.activeVideoElement).playing = true;
          }, e => {
            // FIXME: handle play errors
            this._debugLog("play error: ", e, this.activeVideoElement ? this.activeVideoElement.muted : null);
          });
        });
      }
    } else if (this.activeVideoElement) {
      this.exitFullscreenVideo(); //terminate any fullscreen mod
      // no video should be played now, stop the currently playing video
      this.activeVideoElement.pause();
      this.activeVideoElement.removeAttribute("src"); //Disconnect from the source, clears the video at least on Chrome

      const eltdata = videoeltdata.get(this.activeVideoElement);
      eltdata.videoHash = null;
      eltdata.playing = false;

      if (eltdata.hls) {
        this._debugLog("destroy existing player");
        eltdata.hls.detachMedia(this.activeVideoElement);
        eltdata.hls.destroy();
        eltdata.hls = null;
      }

      this.activeVideoElement = null;
    } else {
      this.stopStillContainer();
    }

    this._updateStateClasses();

    /* Simplify debugging the player element. When using eg https://my.webhare.dev/.live_api/assets/play.html#playlist=//play.test.webhare.live/pl/KM/B-/SwOJ_3ed-4Z3WK2YZzS_teT6aKeHDTHsI42HNms/video.json
       you can access these as:
       - whlive.elements[0]._playerframe.contentWindow._Hls
       - whlive.elements[0]._playerframe.contentWindow._LiveActiveElement
       - whlive.elements[0]._playerframe.contentWindow._Video

       eg: whlive.elements[0]._playerframe.contentWindow._Video.hls.bandwidthEstimate */
    window._LiveActiveElement = this.activeVideoElement; //TODO This is useful for debugging now.. but remove at some point?
    window._Video = videoeltdata.get(this.activeVideoElement);

    // preload is requested and there are multiple video elements to choose from
    if (nextvideo && this.videoElements.length > 1) {
      // choose a free video element
      if (!this.nextVideoElement || this.nextVideoElement === this.activeVideoElement)
        this.nextVideoElement = this.videoElements[0] === this.activeVideoElement ? this.videoElements[1] : this.videoElements[0];

      // calc the video hash, see if it is already preloaded
      const enc = JSON.stringify({ url: nextvideo.url, instanceid: nextvideo.instanceid });
      const eltdata = videoeltdata.get(this.nextVideoElement);
      if (eltdata.videoHash !== enc) {
        // not preloaded, initiate preload
        eltdata.videoHash = enc;
        eltdata.playing = false;
        this._initVideoElt(this.nextVideoElement, nextvideo, { eltname: "next" });
      } else if (this.nextVideoElement.currentTime != 0) {
        // current time isn't 0, seek back
        this._debugLog("player", 'set next currenttime to 0');
      }
    } else if (this.nextVideoElement) {
      // no preload requested, clear the preload
      if (this.nextVideoElement && this.nextVideoElement !== this.activeVideoElement)
        videoeltdata.get(this.nextVideoElement).videoHash = null;
      this.nextVideoElement = null;
    }

    // make sure only the active element is shown
    for (const elt of this.videoElements)
      elt.parentNode.classList.toggle("whlive-player__videocontainer--active", elt === this.activeVideoElement);

    this._updateForcedTimeCheck();
  }

  _updateForcedTimeCheck() {
    if (!(this.activeVideoElement && this.forcedtimefunc) !== !this.intervaltimecheck) {
      if (this.intervaltimecheck) {
        clearInterval(this.intervaltimecheck);
        this.intervaltimecheck = null;
      } else
        this.intervaltimecheck = setInterval(() => this._checkForcedTime(), 5000);
    }
  }

  _checkForcedTime() {
    /* no need to sync for loops? but if we find a use case, perhaps explicitly setting or only after a given
       minimum video length? (no point in correcting if the loop is smaller than the epxected desync time)
    */
    if (this._isVideoPlaying() && !this._isVideoLooping() && this.forcedtimefunc) {
      const newtime = this.forcedtimefunc(this.currentvideo, this.activeVideoElement.currentTime, this.activeVideoElement.duration);
      if (newtime != null) {
        if (this.pxl_maxseeks > 0) {
          --this.pxl_maxseeks;
          this._debugLog(`forced seek from`, this.activeVideoElement.currentTime, 'to', newtime);
          // pxl.sendPxlEvent("live_api:player__forcedseek",
          //   { ds_vid: this.currentvideo.url
          //   , dn_curtime: this.activeVideoElement.currentTime
          //   , dn_duration: this.activeVideoElement.duration
          //   , dn_newtime: newtime || -1
          //   });
          this._seekTo(newtime, "_checkForcedTime");
        }
      }
    }
  }

  setForcedTime(timefunc) {
    this.forcedtimefunc = timefunc;
    this._updateForcedTimeCheck();
  }

  _seekTo(position, reason) {
    if (this.activeVideoElement) {
      this._debugLog(`seekTo from ${this.activeVideoElement.currentTime} to ${position} (${reason})`);
      this.activeVideoElement.currentTime = position;
    } else {
      this._debugLog(`seekTo request to ${position} ignored - no active video (${reason})`);
    }
  }

  getCurrentTime() {
    return this.activeVideoElement ? this.activeVideoElement.currentTime : 0;
  }

  setCurrentTime(reltime) {
    this._seekTo(reltime, "setCurrentTime");
  }

  // special playlist post processing function
  _processPlaylist(playlist) {
    this.currentmasterplaylist = playlist;
    return playlist;
  }

  _initVideoElt(video, vid, { eltname = "", ofs = 0 }) {
    //console.log("player", `Switch ${eltname} to ${vid.url} of type ${vid.type}`);

    const eltdata = videoeltdata.get(video);
    video.loop = vid.loop == true;

    eltdata.videoLoadedPromise = loadhlspromise.then(() => new Promise(resolve => {
      // https://github.com/video-dev/hls.js/issues/2473 - we must recreate hls for every stream
      if (eltdata.hls) {
        this._debugLog("destroy existing player");
        eltdata.hls.detachMedia(video);
        eltdata.hls.destroy();
        eltdata.hls = null;
      }

      // check if native play is possible
      if (video.canPlayType(vid.type)) {
        // init the video src
        video.src = vid.url;
        eltdata.stateChange = null;

        // resolve the videoLoadedPromise when the loadedmetadata event has fired
        const resolvefunc = () => {
          video.removeEventListener('loadedmetadata', resolvefunc);
          resolve();
        };
        video.addEventListener('loadedmetadata', resolvefunc);
      } else if (window.Hls.isSupported()) {
        // https://github.com/video-dev/hls.js/blob/master/docs/API.md
        const thisvideocontroller = this;
        eltdata.hls = new window.Hls({
          capLevelToAPlayerSize: true,  //the adaptive algorithm with limit levels usable in auto-quality by the HTML video element dimensions (width and height)
          //Hls wants a constructor... but we need to bind it to this instance
          debug: this.options.debug,
          pLoader: class pLoader extends window.Hls.DefaultConfig.loader {
            constructor(config) {
              super(config);
              const load = this.load.bind(this);
              this.load = function (context, config, callbacks) {
                if (context.type == 'manifest') {
                  const onSuccess = callbacks.onSuccess;
                  callbacks.onSuccess = function (response, stats, context) {
                    response.data = thisvideocontroller._processPlaylist(response.data);
                    onSuccess(response, stats, context);
                  };
                }
                load(context, config, callbacks);
              };
            }
          }
        });
        this._initHLSErrorHandling(video, eltdata.hls, vid);
        eltdata.hls.loadSource(vid.url);
        eltdata.hls.attachMedia(video);

        // resolve the videoLoadedPromise when the manifest_parsed event fires
        eltdata.hls.once(window.Hls.Events.MANIFEST_PARSED, () => resolve());
      }

      this._seekTo(ofs || 0, "videoLoadedPromise");
    }));
  }

  async startVideoElementPlay(video) {
    if (window.__throwOnUnmutedPlay) {
      if (!video.muted)
        throw new Error("__throwOnUnmutedPlay: blocking autoplay while muted");

      window.__throwOnUnmutedPlay = false;  //you muted. sufficient for current tests... ideally we'd need to 'polyfill mock' all of .muted, clicks, play etc
    }
    return await video.play();
  }

  _onEndFullscreen(e) {
    // This function is only called on iPhone after the video exits fullscreen. Set this.isFullscreen to false as
    // webkitDisplayingFullscreen is still true at this point
    this.isFullscreen = false;
    // If the video was playing when fullscreen was exited, it will pause. To resume playing as it pauses, set this.playAfterFullscreen
    // to the current playing state.
    this.playAfterFullscreen = this._isVideoPlaying();
  }

  _initHLSErrorHandling(elt, hls, vid) {
    let manifest_loaded;
    const recover_wait_init = 1000, recover_wait_max = 10000;
    let recover_wait = recover_wait_init;

    // when the manifest isn't loaded, we need to do a loadsource on network errors
    hls.on(window.Hls.Events.MANIFEST_PARSED, () => {
      manifest_loaded = true;
      recover_wait = recover_wait_init;
    });

    hls.on(window.Hls.Events.ERROR, async (event, data) => {
      ++this.errorcount;
      // only log fatal errors we recover on for now
      if (this.pxl_maxerrs > 0) {
        --this.pxl_maxerrs;
        // pxl.sendPxlEvent("live_api:player__error",
        //   { db_fatal: data.fatal
        //   , ds_type: data.type
        //   , ds_detail: data.detail
        //   , dn_errorcount: this.errorcount
        //   });
      } else {
        setTimeout(() => this.pxl_maxerrs = 5, 30000); //reenable some errors in 30 seconds
      }

      this._debugLog(`HLS: ${data.fatal ? "fatal error" : "non-fatal error"}`, event, data);
      if (data.fatal) {
        switch (data.type) {
          case window.Hls.ErrorTypes.NETWORK_ERROR:
            {
              // try to recover network error
              this._debugLog(`HLS: fatal network error encountered, try to recover (manifest_loaded: ${manifest_loaded ? 1 : 0}, wait ${recover_wait})`);
              await asyncSleep(recover_wait);
              this._debugLog(`sleep done`);

              recover_wait = Math.min(recover_wait * 2, recover_wait_max);
              if (manifest_loaded)
                hls.startLoad();
              else
                hls.loadSource(vid.url);
            } break;
          case window.Hls.ErrorTypes.MEDIA_ERROR:
            {
              this._debugLog("HLS: fatal media error encountered, try to recover");
              hls.recoverMediaError();
            } break;
          default:
            {
              this._debugLog("HLS: cannot recover");
            } break;
        }
      }
    });
  }
}
