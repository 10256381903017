/* eslint-disable */
/// @ts-nocheck -- Bulk rename to enable TypeScript validation

import * as dompack from 'dompack';

// The Fullscreen API is still prefixed in some browsers
let isFullscreenEnabled, fullscreenVideo, fullscreenElement, requestFullscreen, exitFullscreen, fullscreeneventname;
for (const prefix of ["", "webkit"]) {
  const fullscreenEnabledProp = prefix ? `${prefix}FullscreenEnabled` : "fullscreenEnabled";
  if (!(fullscreenEnabledProp in document))
    continue;
  // Note: This returns false in Firefox when the player is shown on a page within an iframe (maybe setting the "fullscreen"
  //       allow on the iframe enables this?)
  isFullscreenEnabled = document[fullscreenEnabledProp];

  if (isFullscreenEnabled) {
    fullscreenElement = prefix ? `${prefix}FullscreenElement` : "fullscreenElement";
    requestFullscreen = prefix ? `${prefix}RequestFullscreen` : "requestFullscreen";
    exitFullscreen = prefix ? `${prefix}ExitFullscreen` : "exitFullscreen";
    fullscreeneventname = `${prefix}fullscreenchange`;
  }
  break;
}
// iOS doesn't support the Fullscreen API, but it supports displaying a video element fullscreen
if (!isFullscreenEnabled && "webkitSupportsFullscreen" in document.createElement('video')) {
  isFullscreenEnabled = true;
  fullscreenVideo = true;
}

function toggleFullscreen(videoelement) {
  console.log("requesting fullscreen", { requestFullscreen, exitFullscreen, fullscreenElement });
  let req = null;
  if (document[fullscreenElement] != null) {
    req = document[exitFullscreen]();
  } else if (videoelement.classList.contains("whlive-mock-fullscreen-element")) {
    videoelement.classList.remove("whlive-mock-fullscreen-element");
    dompack.dispatchDomEvent(videoelement, fullscreeneventname);
  } else {
    if (dompack.debugflags["whlive-mockfullscreen"]) {
      videoelement.classList.add("whlive-mock-fullscreen-element");
      dompack.dispatchDomEvent(videoelement, fullscreeneventname);
    } else {
      req = videoelement[requestFullscreen]();
    }
  }

  //https://developer.mozilla.org/en-US/docs/Web/API/Element/requestFullScreen#browser_compatibility - safari doesn't return a promise, others do
  if (req && req.then)
    req.then(_ => console.log("resolved fullscreen", _), _ => console.log("catch fullscreen", _));
  else
    console.log("direct response from fullscreen", req);
}

export function getFullscreenEventName() {
  return fullscreeneventname;
}

export function isFullscreen(node) {
  return document[fullscreenElement] === node || node.classList.contains("whlive-mock-fullscreen-element");
}

export function getFullscreenMode() {
  let fullscreen;
  if (isFullscreenEnabled) {
    if (fullscreenVideo) {
      fullscreen = "video"; // Signal to the video element to handle fullscreen directly (on iOS)
    } else {
      fullscreen = toggleFullscreen;
    }
  }

  return fullscreen;
}
