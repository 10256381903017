/* eslint-disable */
/// @ts-nocheck -- Bulk rename to enable TypeScript validation

import GroupChatFrame from "../../pages/testchatclient/groupchatframe";

//TODO don't derive from groupchatframe, factor out the common code
export default class PrivateChatFrame extends GroupChatFrame {
  updateConnectionState() {
    super.updateConnectionState();

    const otheruser = this.backend.participants.find(user => !user.isself);
    if (!this._isoffline && otheruser?.offline) {
      this._isoffline = true;
      this.setStatusMessage(`The other participant is offline, waiting for reconnection…`);
    } else if (this._isoffline && !(otheruser?.offline)) {
      this._isoffline = false;
      this.setStatusMessage(null);
    }
  }
}
