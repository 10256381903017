/* eslint-disable */
/// @ts-nocheck -- Bulk rename to enable TypeScript validation

import * as dompack from 'dompack';
import "./actions.scss";

export default class ActionsManager {
  constructor(owner, actions) {
    this.ownernode = owner;

    this.controlNode =
      <div class="whlive-chat__controls">
        {actions.map(action => <span tabIndex="0" class={action.className} onClick={evt => this._onClick(evt, action)}>{action.title}</span>)}
      </div>;
    this.containerNode = <div class="whlive-chat__controls-container">{this.controlNode}</div>;


    //FIXME
    // if(this.ismoderator && !this.controlNode.parentNode) //delay insertion until we're sure we're a mod. this is probably going to be outside-DOM absolute position in the future anyway
    //   this.ownernode.append(this.controlNode);

    this._checkCloseMenu = event => {
      if (this.ownernode.classList.contains("whlive-chat--controls") && !event.target.closest(".whlive-chat__controls"))
        this.hideControls();
    };

    this.ownernode.addEventListener("mousedown", this._checkCloseMenu);
    this.ownernode.addEventListener("wheel", this._checkCloseMenu);
  }

  destroy() {
    this.hideControls();
    this.ownernode.removeEventListener("mousedown", this._checkCloseMenu);
    this.ownernode.removeEventListener("wheel", this._checkCloseMenu);
  }

  _onClick(evt, action) {
    dompack.stop(evt);

    const msg = this._controlMessage;
    this.hideControls(); //resets this._controlMessage

    if (!msg)
      return;

    action.handler(msg);
  }

  showControls(event, activebubble) {
    if (event)
      dompack.stop(event);

    if (!this.containerNode.parentNode)
      this.ownernode.appendChild(this.containerNode);

    const actionpos = activebubble.node.querySelector(".whlive-chat__actions")?.getBoundingClientRect();
    if (actionpos) {
      const containerpos = this.containerNode.getBoundingClientRect();
      const control = this.controlNode.getBoundingClientRect();
      const left = (actionpos.left + (actionpos.width / 2) + control.width > window.innerWidth ? window.innerWidth - control.width - containerpos.left : (actionpos.left - containerpos.left) + (actionpos.width / 2));
      const top = (actionpos.top + actionpos.height + control.height > window.innerHeight ? window.innerHeight - control.height - containerpos.top : (actionpos.top - containerpos.top) + actionpos.height);
      this.controlNode.style.left = `${left}px`;
      this.controlNode.style.top = `${top}px`;
    }
    this._controlMessage = activebubble;
    this._controlMessage.node.classList.add("whlive-chat__bubble--actionsopened");
    this.ownernode.classList.add("whlive-chat--controls");
  }

  hideControls() {
    this._controlMessage?.node.classList.remove("whlive-chat__bubble--actionsopened");
    this._controlMessage = null;
    this.ownernode.classList.remove("whlive-chat--controls");
  }

}
