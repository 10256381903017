/* eslint-disable */
/// @ts-nocheck -- Bulk rename to enable TypeScript validation

import * as socketclient from 'socket.io-client';
import EventSource from '../util/eventsource';
import { createDeferred } from '../util/utils';

export interface ChatPlaneConnectionOptions {
  debugname?: string;
  forcewebsocket?: boolean;
  debug?: boolean;
  reloadgrace_ms?: number;
  disconnectgrace_ms?: number;
  responsiveness_responsetime_ms?: number;
  responsiveness_maxinactive_ms?: number;
  token?: string;
}

//TODO reuse earlier visitorids if we have that in localStorage for the current ws/url
//TODO what if we have a visitortoken? are visitiorids even worth it then?

export default class ChatPlaneConnection extends EventSource {
  _setchannelresult;
  _firstresolve?: ((setchannelresult: unknown) => void) | null;
  wsinfo;
  _clientid?: string;
  options: ChatPlaneConnectionOptions = {};
  debugname?: string;
  socket?: socketclient.Socket;
  _suspendqueue?: Array<() => void> | null;
  drift?: { servertime: number; reference: number };

  constructor() {
    super();
    this._setchannelresult = new Promise(resolve => this._firstresolve = resolve);
  }

  connect(ws, clientid: string, options?: ChatPlaneConnectionOptions) {
    if (!ws.channeltype || !ws.item || !ws.item.startsWith("item:") || !ws.item.match(/channeltype/))
      throw new Error("Missing vital broadcastpointer fields. You may need to republish the playlists?"); //old format?

    this.wsinfo = ws;
    this._clientid = clientid;
    this.options = { ...options };
    this.debugname = (options?.debugname ? options.debugname + ':' : '');

    const opts: { path: string; reconnection: boolean; transports?: string[] } = {
      path: "/cp/io",
      reconnection: true
    };

    if (this.options.forcewebsocket)
      opts.transports = ['websocket'];

    this.socket = socketclient.connect(ws.url, opts);
    this.socket.on('connect', () => this._process(() => this._onConnect()));
    this.socket.on('message', msg => this._process(() => this._receive(msg)));
    this.socket.on('disconnect', reason => this._process(() => this._onDisconnect(reason)));


    //NOTE if we reenable this.. the data for many of these events is unsafe to transmit through our EventSource
    //this.socket.on('error', error => this.emit("error", { error }));
    //this.socket.on('connect_timeout', connect_timeout => this.emit("connect_timeout", connect_timeout));
    //this.socket.on('reconnect', reconnect => this.emit("reconnect", reconnect));
    //this.socket.on('reconnect_attempt', reconnect_attempt => this.emit("reconnect_attempt", reconnect_attempt));
    //this.socket.on('reconnecting', reconnecting => this.emit("reconnecting", reconnecting));
    //this.socket.on('reconnect_error', reconnect_error => this.emit("reconnect_error", reconnect_error));
    //this.socket.on('reconnect_failed', reconnect_failed => this.emit("reconnect_failed", reconnect_failed));
  }

  suspend() //suspend all event processing (useful when debugging)/testing
  {
    if (this._suspendqueue)
      throw new Error(`Socket already suspended`);

    this._suspendqueue = [];
    if (this.options.debug)
      console.log(`[client ${this.debugname}] Suspending event processing`);
  }
  resume() //resume event processing, flush any events captured during suspension
  {
    if (!this._suspendqueue)
      throw new Error(`Socket to resume wasnot suspended`);

    if (this.options.debug)
      console.log(`[client ${this.debugname}] Resuming event processing, flush ${this._suspendqueue.length} events`);

    const toflush = this._suspendqueue;
    this._suspendqueue = null;
    //put them back as timeouts on the queue so any suspend() from an event callback will work properly and so exceptions will not be cauht gby us
    toflush.forEach(callback => setTimeout(() => this._process(callback), 0));
  }
  _process(callback: () => void) {
    if (this._suspendqueue)
      this._suspendqueue.push(callback);
    else
      callback();
  }

  async _onConnect() {
    this.debugname = (this.options?.debugname ? this.options.debugname + ':' : '') + this.socket?.id;

    const handshake = {
      url: this.wsinfo.channeltype,
      item: this.wsinfo.item,
      token: this.options.token,
      clientid: this._clientid,
      ...ChatPlaneConnection.pickConnectionOptions(this.options)
    };

    if (this.options.debug)
      console.log(`[client ${this.debugname}] Connected, send handshake`, handshake);

    //TODO timeout on this message  ?
    const scresult = await new Promise(resolve =>
      this.socket?.emit('setchannel', handshake, resolve));

    if (scresult.now) //received a time update
    {
      this.drift = {
        servertime: new Date(scresult.now).getTime(),
        reference: Date.now()
      };
    }

    if (this.options.debug)
      console.log(`[client ${this.debugname}] setChannel response`, scresult);

    if (scresult.status == "error")
      throw new Error(`setChannel failed: ${scresult.errormessage}`);

    if (this._firstresolve) {
      delete scresult.status;
      this._firstresolve(scresult);
      this._firstresolve = null;
    }

    this.emit("connected", scresult);
  }

  _onDisconnect(reason: string) {
    if (this.options.debug)
      console.log(`[client ${this.debugname}] Disconnected`, reason);

    this.emit("disconnect");

    if (reason === 'io server disconnect') {
      // the disconnection was initiated by the server, you need to reconnect manually
      setTimeout(() => this.socket?.connect(), Math.random() * 30000);
    }
    // else the socket will automatically try to reconnect (IS THIS TRUE?)
  }

  getServerTime() {
    if (!this.drift)
      return Date.now();

    //at 'this.drift.reftime' it was 'this.drift.now' on the server
    /* note that we used to take performance.now at some point, but
      - it's not cross browser/nodejs
      - we don't even need millisecond resolution, let alone microsecond resolution
      - it only gives us one Date.now to mock during tests */
    const curreftime = Date.now();
    return this.drift.servertime + curreftime - this.drift.reference;
  }

  /** Returns a promise
      @cell return.visitorid
  */
  getSetChannelResult() {
    return this._setchannelresult;
  }

  send(msg: unknown, callback: (result: unknown) => void) {
    if (this.options.debug)
      console.log(`[client ${this.debugname}] Send messsage`, msg);

    this.socket?.emit('message', msg, callback);
  }

  _receive(msg: { type: string }) {
    if (this.options.debug)
      console.log(`[client ${this.debugname}] Received messsage`, msg);

    this.emit('message', msg); //consider getting rid of this in favor of direct routing?
    this.emit('cp.' + msg.type, msg); //eg send type 'directmsg' as cp.directmsg
  }

  // Send and wait for response
  async request(msg: unknown): Promise<object> {
    const rawresponse = await new Promise(resolve => this.send(msg, resolve));
    const response = rawresponse as { status?: string; errormessage?: string };
    if (response.status == "ok") {
      delete response.status;
      return response;
    }
    throw new Error(response.errormessage);
  }

  async disconnect() {
    const defer = createDeferred<{ reason: socketclient.Socket.DisconnectReason }>();
    //let disconnectresolve: ({ reason: socketclient.Socket.DisconnectReason }) => void
    //let disconnectpromise = new Promise<{ reason: socketclient.Socket.DisconnectReason }>(resolve => disconnectresolve = resolve);
    this.socket?.on("disconnect", defer.resolve);
    this.socket?.disconnect();
    return await defer.promise;
  }

  static connectionoptions = ["reloadgrace_ms", "disconnectgrace_ms", "responsiveness_responsetime_ms", "responsiveness_maxinactive_ms", "forcewebsocket", "debug"];

  static pickConnectionOptions(options?: ChatPlaneConnectionOptions) {
    if (!options)
      return {};

    const retval: ChatPlaneConnectionOptions = {};
    for (const opt of ChatPlaneConnection.connectionoptions)
      if (opt in options)
        (retval as any)[opt] = (options as any)[opt];
    return retval;
  }
}
