/* eslint-disable */
/// @ts-nocheck -- Bulk rename to enable TypeScript validation

import * as dompack from 'dompack';
import getTid from "@mod-tollium/js/gettid";
import ChatFrame from "./chatframe";

function sortByOldest(lhs, rhs) {
  if (lhs.message.createdat > rhs.message.createdat)
    return 1;
  if (lhs.message.createdat < rhs.message.createdat)
    return -1;
  return lhs.message.id < rhs.message.id ? 1 : -1;
}

export default class GroupChatFrame extends ChatFrame {
  constructor(container, chatbackend, url, visitor, chatroominfoinfo, opts) {
    super(chatbackend, url, visitor, chatroominfoinfo, { ...opts });

    //add 'favorite' after 'reply'
    this.actions.splice(1, 0, { className: "whlive-chat__starmessage", handler: ({ message }) => this.starMessage(message), title: getTid('live_api:frontend.js.chat.starbutton') });

    //FIXME do we even need to look for 'privatechat' ? technically we can also discover this using the context, and the privatechat might not be online yet when the room loaded, disabling these features!
    //      but we probably still need a true/false here because we need to know that any privatechat we detected is actually shared with this group. or can we assume that with a shared context...
    if (opts.privatechat) {
      //Add private chat actions. They will still necessarily be filtered based on being moderator (otherwise you currently cant see controls) AND being a chat agent
      this.actions.push({ className: "whlive-chat__privatechatreply", visibleon: () => { return true; }, handler: ({ message }) => this._doPrivateChatReply(message), title: getTid('live_api:frontend.js.chat.privatechatreply') }
        , { className: "whlive-chat__privatechatforward", visibleon: () => { return false; }, handler: ({ message }) => this._doPrivateChatForward(message), title: getTid('live_api:frontend.js.chat.privatechatforward') }
      );
    }

    this.buildDom(container);
    this.scrollingup = false;
    this.chatnode.classList.add("whlive-chat--groupchat");
    this._filterMessages("");
  }

  buildDom(container) {
    super.buildDom(container);
    this.nodes.messages.addEventListener("scroll", evt => this._onMessagesScroll(evt));
    this.messagearea.append(<div class="whlive-chat__backtobottom" onClick={() => this.scrollToBottom()} />);

    this.chattabs =
      <div class="whlive-chat__tabarea whlive-chat__groupchattabs" hidden>
        <div class="whlive-chat__tab whlive-chat__groupchattab whlive-chat__groupchattab__all" data-tab="" onClick={() => this._filterMessages("")}><span class="whlive-chat__tab__text whlive-chat__groupchattab__text">{getTid('live_api:frontend.js.chat.tab_all')}</span></div>
        <div class="whlive-chat__tab whlive-chat__groupchattab whlive-chat__groupchattab__starred" data-tab="starred" onClick={() => this._filterMessages("starred")}><span class="whlive-chat__tab__text whlive-chat__groupchattab__text">{getTid('live_api:frontend.js.chat.tab_starred')}</span></div>
        {this.getModeratorControls()}
      </div>;
    this.messagearea.before(this.chattabs);
  }

  async _doPrivateChatReply(msg) {
    console.log("_doPrivateChatReply", msg);
    const conversation = await this.backend._client._client.context.createConversation({ peerid: msg.publicid });
    conversation.sendMessage("I want to chat with you");
    // let conversation = this.options.
  }

  _doPrivateChatForward(msg) {
    console.error("_doPrivateChatForward", msg);
  }

  updateUser(user) {
    super.updateUser(user);
  }

  updateDom() {
    super.updateDom();
    if (!this.chattabs.hidden && this.ismoderator) //tabs are about to appear... so apply filter
      this._filterMessages(this.filter);
    this.chattabs.hidden = !this.ismoderator;
  }

  _onMessagesScroll() {
    //if you scroll up, we stop autoscroll until you scroll back down again
    //TODO do we need a tolerance before we consider you scrolling ?
    const scrollingup = (this.nodes.messages.scrollTop + this.nodes.messages.offsetHeight) < this.nodes.messages.scrollHeight;
    if (scrollingup === this.scrollingup)
      return; //nothing changed

    this.scrollingup = scrollingup;
    this.chatnode.classList.toggle("whlive-chat--scrollingup", this.scrollingup);
  }

  scrollToBottom() {
    this.nodes.messages.scrollTop = this.nodes.messages.scrollHeight;
  }

  _filterMessages(filter) {
    dompack.qSA(this.chatnode, ".whlive-chat__tab").forEach(node => {
      node.classList.toggle("whlive-chat__tab--active", node.dataset.tab == filter);
      node.classList.toggle("whlive-chat__groupchattab--active", node.dataset.tab == filter);
    });
    this.filter = filter;
    this._applyMessageFilter();
    this.scrollToBottom();
  }

  _applyMessageFilter() {
    let bubbles = Array.from(this.bubblemap.values());

    if (this.filter == "starred")
      bubbles = bubbles.filter(bubble => this.starred.has(bubble.message.id)).sort(sortByOldest);
    else
      bubbles = bubbles.sort(sortByOldest);

    const currentorder = dompack.qSA(this.nodes.messages, ".whlive-chat__bubble");
    let matchorder = currentorder.length == bubbles.length;
    for (let i = 0; matchorder && i < currentorder.length; ++i)
      matchorder = currentorder[i] == bubbles[i].bubble;

    if (matchorder) //don't bother the DOM
      return;

    bubbles.forEach(bubble => this.nodes.messages.appendChild(bubble.bubble));
    while (this.nodes.messages.firstChild && this.nodes.messages.firstChild != bubbles[0]?.bubble) //old node must still present there
      this.nodes.messages.removeChild(this.nodes.messages.firstChild);
  }

  _showIncomingMessage(message) {
    // If the favorites tab is currently active, only show messages that are favorited
    return this.filter != "starred" || this.starred.has(message.id);
  }

  _buildMessageBubble(message) {
    const prevMsg = this.backend.getPreviousMessageForId(message.id);
    const sameuser = prevMsg && message.publicid === prevMsg.publicid;

    let bubblenode;
    let inreplyto;

    if (message.inreplyto)
      inreplyto = this.backend.getMessageById(message.inreplyto);

    const classNames = ["whlive-chat__bubble"];
    if (inreplyto)
      classNames.push("whlive-chat__bubble--isreply");
    if (message.mine)
      classNames.push("whlive-chat__bubble--mine");
    if (sameuser)
      classNames.push("whlive-chat__bubble--sameuser");
    if (message.replied)
      classNames.push("whlive-chat__bubble--hasreply");
    if (message.visitor?.screenflags)
      classNames.splice(-1, 0, ...message.visitor.screenflags.map(flag => `whlive-chat__screenflag-${flag}`));

    if (inreplyto) {
      bubblenode =
        <div class={classNames.join(" ")}>
          <div class="whlive-chat__bubble-original">
            {this._buildBubbleMessage(inreplyto)}
          </div>
          <div class="whlive-chat__bubble-reply">
            {this._buildBubbleMessage(message, true)}
          </div>
        </div>;

      // Add a 'has a reply' status icon to the original message
      const orgbubble = this.bubblemap.get(message.inreplyto);
      if (orgbubble) {
        if (orgbubble.message.mine)
          bubblenode.classList.add("whlive-chat__bubble--minehasreply");
        orgbubble.bubble.querySelector(".whlive-chat__icons").prepend(<span class="whlive-chat__reply" onClick={() => console.log("scroll to reply")} />);
      }
    } else {
      bubblenode =
        <div class={classNames.join(" ")}>
          {this._buildBubbleMessage(message, true)}
        </div>;
    }

    return bubblenode;
  }

  _updatedChatFrame(appended_my_message) {
    if (!this.scrollingup || appended_my_message) //we always scroll down for our own message
      this.nodes.messages.scrollTop = this.nodes.messages.scrollHeight;
  }
}

