//Here go any functions that are not videoplayer specific AT ALL!

//deep compare for JS objects that came from JSON
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function equalJSONObjects(x: any, y: any) {
  if (typeof x !== typeof y)
    return false;
  if ((x === null) != (y === null))
    return false;
  if (x === y)
    return true;
  if (isNaN(x) && isNaN(y) && typeof x === 'number' && typeof y === 'number')
    return true;
  if (Array.isArray(x)) {
    if (x.length != y.length)
      return false;
    for (let i = 0, e = x.length; i < e; ++i)
      if (!equalJSONObjects(x[i], y[i]))
        return false;
    return true;
  }
  for (const p of Object.keys(x)) {
    //are these needed if both objects are JSON objects ?  because eslint doesn't like it
    // if (y.hasOwnProperty(p) !== x.hasOwnProperty(p))
    //   return false;
    if (typeof y[p] !== typeof x[p])
      return false;
    if (typeof (x[p]) == "object") {
      if (!equalJSONObjects(x[p], y[p]))
        return false;
    } else {
      if (x[p] !== y[p])
        return false;
    }
  }
  for (const p in y)
    if (!(p in x))
      return false;
  return true;
}
