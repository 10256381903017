/* eslint-disable */
/// @ts-nocheck -- Bulk rename to enable TypeScript validation

import * as dompack from 'dompack';
import * as bindings from '../util/bindings';
import "./conversations.scss";

export default class ConversationsHandler extends bindings.BindableBase {
  constructor(livesiteclient, element, options) {
    super(livesiteclient, element);
    options = {
      conversationlist: null,
      ...options
    };

    this._element.classList.add("whlive-conversations", "whlive-chat--1on1chat");

    this.listeners = [
      this._livesiteclient.on("chats", chats => this._onConversations(chats)),
      this._livesiteclient.on("chat", chat => this._onNewConversation(chat))
    ];

    this.conversations = new Map;

    this._element.replaceChildren();
    this._createdbindings = new Map;

    if (options.conversationlist) {
      const conversationlistoptions = { conversationshandler: this };
      for (const forward of ['separatearchive', 'separateincoming'])
        if (forward in options)
          conversationlistoptions[forward] = options[forward];

      this._list = this._livesiteclient.bindConversationListElement(options.conversationlist, conversationlistoptions);
      this._listlisteners = [this._list.on("select", evt => this._onListSelect(evt))];

    }

    this._ondocumentfocus = event => this._onDocumentFocus(event);
    window.addEventListener("focus", this._ondocumentfocus);

    setTimeout(() => {
      this._onConversations({ conversations: this._livesiteclient.getConversations() });
    }, 1);
  }

  _unbindElement() {
    //FIXME shouldn't we be cleaning up our childnode bindings too ?
    if (this._list) {
      this._listlistener.forEach(_ => this._list.off(_));
      this._list.unbind();
    }
    this.listeners.forEach(listener => this.conversation.off(listener));
    this.conversations.forEach((_, conversation) => conversation.listeners.forEach(listener => conversation.off(listener))); // Map.forEach((value, key) => { ... } )
    window.removeEventListener("focus", this._ondocumentfocus);
  }

  _onDocumentFocus() {
    if (this._selectedconversation) //mark unread messages as read
      this._selectedconversation.clearUnread();
  }

  _onListSelect({ conversation }) {
    this.selectConversation(conversation);
  }

  //Like client.createConversation but reuse already existing 'new' conversations
  async startConversation({ teamid, agentdata = null } = {}) {
    let conversation = Array.from(this.conversations.keys()).find(_ => _.chatmode == 'new' && _._teamid == teamid);
    if (!conversation) {
      conversation = await this._livesiteclient.createConversation({ teamid, agentdata });
      this._ensureConversationNode(conversation);
    }

    this.selectConversation(conversation);
    return conversation;
  }

  selectConversation(conversation) {
    if (this._selectedconversation === conversation) //no change
      return;

    this.emit("select", { conversation });

    const activeElement = this.conversations.get(conversation)?.node;
    for (const element of this._element.childNodes) {
      element.classList.toggle("whlive-conversations__conversation--active", element == activeElement);
      element.classList.toggle("whlive-conversations__conversation--inactive", element != activeElement);
    }

    // this._livesiteclient.emit("activeconversation", { conversation: this._selectedconversation });
    if (activeElement) {
      const boundto = this._createdbindings.get(activeElement);
      boundto?.chatframe?.scrollToBottom();
    }

    this._selectedconversation = conversation;
  }

  _onConversations({ conversations }) {
    for (const conversation of conversations)
      this._ensureConversationNode(conversation);
  }

  _onNewConversation({ conversation }) {
    this._ensureConversationNode(conversation);
  }

  _ensureConversationNode(conversation) {
    if (this.conversations.has(conversation))
      return; //already created this one, possibly beacuse we maually did the _onNewConvesration

    const chatdiv = <div class="whlive-conversations__conversation" />;
    chatdiv.classList.add("whlive-conversations__conversation--inactive");

    const bound = this._livesiteclient.bindChatElement(conversation, chatdiv, { actions: false }); //no need for controls yet in private conversations
    this._createdbindings.set(chatdiv, bound);
    this._element.append(chatdiv);
    this.conversations.set(conversation,
      {
        node: chatdiv,
        listeners: [
          conversation.on("message", message => this._onMessage(conversation, message)),
          conversation.on("updateconversation", _ => this._onUpdateConversation(conversation))
        ]
      });
  }

  _onMessage(conversation, message) {
    // If this conversation has unread messages, show a notification for the incoming message
    if (conversation.unread && conversation.id && (conversation !== this._selectedconversation || !document.hasFocus()))
      this._livesiteclient.emit("shownotification", { conversation: conversation.id, name: conversation.peervisitor?.screenname, title: conversation.team?.title, text: message.message });
  }

  _onUpdateConversation(conversation) {
    // If this conversation has no unread messages, clear all notifications for the conversation
    if (!conversation.unread && conversation.id)
      this._livesiteclient.emit("clearnotifications", { conversation: conversation.id });
  }
}
