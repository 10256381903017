/* eslint-disable */
/// @ts-nocheck -- Bulk rename to enable TypeScript validation

import ChatPlaneConnection from './cpconnect/cpconnection';
import Conversation from './cpconnect/conversation';
import EventSource from './util/eventsource';
import StorageClient from "./util/storageclient";
import CoalescingCallback from './util/coalescingcallback';
import * as tokens from './util/tokens';
import type ChatPlaneClient from './chatplaneclient';

interface ChatPlaneConnectorOptions {
  visitortoken?: string;
  forcewebsocket?: boolean;
  storage?: StorageClient;
}

/* A ChatPlaneConnector wraps the 'connection' to the chatplane. The ChatPlaneConnector needs to abstract away the possible
   absence of a ChatPlaneConnection for ondemand chat */
export default class ChatPlaneConnector extends EventSource //TODO we should probably merge with the chatbackend ? but then we shouldn't have a backend per track
{
  _chatinfo;
  _chatinfostr;
  _client;
  _conversations;
  _connectionstatus;
  _cpconnection?: ChatPlaneConnection | null;
  _forcewebsocket;
  _isagent;
  _ismoderator;
  _isreadonly;
  _mustconnect;
  _options;
  _parsedtoken;
  _permanentdisconnect;
  _state: {
    isagent?: boolean;
    chatmode?: "open";
    available?: unknown;
  };
  _storage: StorageClient;
  _responsivenesscheck: null;
  _responsivenesscheckstate;
  _updatechatscallback;
  _userstatus;
  __visitorid__?;
  groupchat?: Conversation;
  participants?;
  publicid?;
  questions?: Conversation;
  roomconfig;
  screenflags?;
  screenname: string;
  visitortoken!: string | null;

  /** @param chatinfo describes type and websocket information. usually retrieved from the whlive play cdn
   */
  constructor(client: ChatPlaneClient, chatinfo, options: ChatPlaneConnectorOptions) {
    super();
    this._state = {};
    this._userstatus = null;
    this._responsivenesscheck = null;
    this._connectionstatus = null;
    this._isagent = false;
    this._ismoderator = false;
    this._client = client;
    this._chatinfo = chatinfo;
    this._chatinfostr = JSON.stringify(chatinfo);
    this._permanentdisconnect = false;
    this._updatechatscallback = new CoalescingCallback(() => this._updateChats());
    this.setVisitorToken(options?.visitortoken || null); //make sure we don't pass undefined! as that will prevent _mustconnect from being set

    this.roomconfig = null;
    if (this._chatinfo.roomtype == 'chatcontrol')
      this._state.isagent = this._isagent;

    this._isreadonly = this._chatinfo.roomtype != 'chatcontrol'; //in a chatcontrol, we can always write
    this._forcewebsocket = options?.forcewebsocket;
    this._conversations = new Map<string, Conversation>;
    this._options = { ...options };

    if (options?.storage)
      this._storage = options.storage;
    else
      this._storage = new StorageClient(this._parsedtoken?.id); //creates 'global' storage if id unset

    //FIXME expire old conversations... perhaps by time (LONG) and perhaps by having setChannelResult return a definitive list of our conversations and deleting the ones not mentiomne dthere?

    if (this._chatinfo.roomtype == 'groupchat')
      this.groupchat = new Conversation(this, 'groupchat', { ...options, roomtype: 'groupchat' });
    else if (this._chatinfo.roomtype == 'questions')
      this.questions = new Conversation(this, 'questions', { ...options, roomtype: 'questions' });

    this.screenname = this._parsedtoken?.screenname || '';

    const reconnect = this._mustconnect || [...this._storage.getChildren("conversation")].some(_ => _[1].active); //if it looks like you were chatting, reconnect

    /* TODO this still assumes a 'global' chatmode which is used by groupchat and questions, but is overloaded when dealing
            with private conversations which all have their own chatmode. so we should probably start moving away from the
            central _state.chatmode ? */
    if (reconnect) //FIXME even for chatcontrol, don't connect if it doesn't look like we're an agent
    {
      this._state.chatmode = "open";
      this._connectChatPlane();
    } else //this is a user which may initiate chat (TODO Resume connection if a chat was already open, we need a Storage API for that)
    {
      this._state.chatmode = "open"; //TODO i think for non agents we still need a 'closed' if no agent is available or the chat is explciitly closed?
      //give caller a chance to register 'on's
      setTimeout(() => this._processRoomState());
    }
  }

  updateChatInfo(chatinfo) {
    const asstr = JSON.stringify(chatinfo);
    if (asstr === this._chatinfostr)
      return;

    this._chatinfostr = asstr;
    this._chatinfo = chatinfo;
    this._finalizeRoomState();
  }

  get available() {
    return this._chatinfo.roomtype === 'chatcontrol' ? !this._chatinfo.offline : null;
  }

  get state() {
    return this._state;
  }

  get userstatus() {
    return this._userstatus;
  }

  get responsivenesscheckstate() {
    return this._responsivenesscheckstate;
  }

  get connectionstatus() {
    return this._connectionstatus;
  }

  getConversations() //no getter, this is not that cheap..
  {
    return [...this._conversations.values()];
  }

  setVisitorToken(token: string | null) {
    if (this._cpconnection)
      throw new Error("Setting visitortoken after CP connection is established - this isn't supported. Recreate the player!");

    if (token === this.visitortoken)
      return;

    this.visitortoken = token;
    this._parsedtoken = this.visitortoken ? tokens.parseVisitorToken(this.visitortoken) : null;
    this._ismoderator = Boolean(this._parsedtoken && this._parsedtoken.tickets.includes(this._chatinfo.ticket_moderator));
    this._isagent = this._chatinfo.roomtype == 'chatcontrol' && (this._ismoderator || Boolean(this._parsedtoken && this._parsedtoken.tickets.includes(this._chatinfo.ticket_agent)));
    //TODO actually reconnect if _mustconncet changes, but that is only relevant if we can become agents while connected.
    this._mustconnect = this._chatinfo.roomtype != 'chatcontrol' //groupchats require immediate connections (as we shouldn't get here if archived - TODO no need to connect if replayrange falls within archived files)
      || this._isagent;  //agents always need to connect to indicate readiness/receive existing conversations
  }

  async _connectChatPlane() {
    if (this._permanentdisconnect)
      throw new Error("We are already disconnected");

    if (!this._cpconnection) {
      this._cpconnection = new ChatPlaneConnection; //construct first so others can safely wait for the getSetChannelResult() (even if that's an internal api... should probably merge that into _connectChatPlane reutrn value)

      let clientid = this._storage.get("clientid");
      if (!clientid) {
        clientid = await tokens.generateUniqueId();
        this._storage.set("clientid", clientid);
      }

      const connectopts = {
        token: this.visitortoken,
        debugname: this._parsedtoken?.screenname ?? clientid,
        ...ChatPlaneConnection.pickConnectionOptions(this._options)
      };

      this._cpconnection.connect(this._chatinfo.io ?? this._chatinfo.chatio ?? this._chatinfo.broadcastio, clientid, connectopts);

      this._cpconnection.on('connected', e => this._onConnected(e));
      this._cpconnection.on('cp.msg', e => this._gotMessage(e));
      this._cpconnection.on('cp.servermsg', e => this._gotMessage(e));
      this._cpconnection.on('cp.update', e => this._gotUpdate(e));
      this._cpconnection.on('cp.update-conversation', e => this._gotUpdateConversation(e));
      this._cpconnection.on('cp.delete-msg', e => this._gotDeleteMessage(e));
      this._cpconnection.on('cp.ban-visitor', e => this._gotBanVisitor(e)); //FIXME ban/unban should probably be processed in the chatplane, not broadcasted ?
      this._cpconnection.on('cp.unban-visitor', e => this._gotUnbanVisitor(e)); //FIXME ban/unban should probably be processed in the chatplane, not broadcasted ?
      this._cpconnection.on('cp.roomstate', e => this._processRoomState(e));
      this._cpconnection.on('cp.roomstats', e => this._processRoomStats(e));
      this._cpconnection.on('cp.roomconfig', e => this._processRoomConfig(e));
      this._cpconnection.on('cp.userstatus', e => this._processUserStatus(e));
      this._cpconnection.on('cp.requestprivatechat', e => this._requestPrivateChat(e));

      if ((globalThis as any).__livedbg_newcpconnection)
        (globalThis as any).__livedbg_newcpconnection({ conn: this._cpconnection, chatinfo: this._chatinfo, clientid, token: this.visitortoken });
    }

    return await this._cpconnection.getSetChannelResult();
  }

  isConnected() //whether the chatplane connected. mostly a debugging tool as we should be connecting and disconnecting transparently on demamnd
  {
    return Boolean(this._cpconnection);
  }

  isAgent() {
    return this._isagent;
  }

  isModerator() {
    return this._ismoderator;
  }

  isReadOnly() {
    return this._isreadonly;
  }

  isAnonymous() {
    return !this.screenname;
  }

  _onConnected(result) {
    //TODO combine agent and moderator with generic state updates? not sure if agent and moderator will remain aligned or not .currently isagent is only set for chatcontrol rooms but is otherwise equal to moderator
    // this._ismoderator = !!result.ismoderator;
    this._isreadonly = Boolean(result.isreadonly);
    // if(this._chatinfo.roomtype == 'chatcontrol' && !!result.isagent !== this._isagent)
    // this._isagent = !!result.isagent;

    this.publicid = result.publicid;
    this.__visitorid__ = result.visitorid; //FIXME drop visitorid, legacy field
    //this.screenname = result?.visitor?.screenname ?? ''; //TODO do we need to do anything with the received screenname?
    this.screenflags = result?.visitor?.screenflags ?? [];
    this.participants = result.stats?.participants;

    this._processRoomState(result);

    // honour user status when set before connection established
    if (this._isagent) {
      //i think we re-reflect urerstatus on a reconnection... with this code, that might cause subtle bugs...
      if (this._userstatus) {
        const msg = { type: "setuserstatus", userstatus: this._userstatus };
        this._cpconnection?.request(msg);
      } else
        this._processUserStatus(result);

      if (this._connectionstatus) {
        const msg = { type: "setconnectionstatus", connectionstatus: this._connectionstatus };
        this._cpconnection?.request(msg);
      }
    }

    if (result.stats)
      this._processRoomStats(result);
    if (this._chatinfo.roomtype == 'groupchat')
      this.groupchat?._channelConnected(result);
    else if (this._chatinfo.roomtype == 'questions')
      this.questions?._channelConnected(result);
  }

  _gotUpdate(msg) {
    //updates an existing message. only used for votes right now (TODO shouldn't it carry a conversationid?)
    if (!this.questions)
      throw new Error("Got a cp.update but we only support that for quetsion rooms");

    this.questions._processUpdate(msg);
  }

  //TODO _gotMessage and/or other events should probably share their entry code as they all need to allocate conversations on demand ?

  _gotMessage({ eventtype, ...msg }: { eventtype: string; conversationid?: string })  //extract eventtype, we don't want that in the history
  {
    eventtype; //shuts up linter warning

    const csid = msg.conversationid;
    delete msg.conversationid;

    if (!csid)
      throw new Error("Got a cp.msg but without routing info");

    let cs = this._conversations.get(csid);
    if (cs) {
      cs._processMessage(msg);
    } else {
      cs = new Conversation(this, csid, { roomtype: 'privatechat', unanswered: true, announceincoming: true });
      cs._processMessage(msg);
    }
  }

  async _gotUpdateConversation({ conversationid, ...msg }: { conversationid: string }) {
    if (!conversationid)
      throw new Error("Got a cp.update-conversation but without routing info");

    let cs = this._conversations.get(conversationid);
    const wasactive = cs?.isActive();
    if (!cs) {
      const opts = { updatemsg: msg, unanswered: true, roomtype: 'privatechat', announceincoming: true };
      const conversationinfo = this._storage.get("conversation." + conversationid);
      if (conversationinfo) // do we happen to know about this conversation ?
        opts.unanswered = conversationinfo.unanswered;

      cs = new Conversation(this, conversationid, opts);
      if (conversationinfo?.lastread) //sync lastread
        cs._lastread = conversationinfo.lastread;
    }

    cs._processConversationUpdate(msg);
    if (wasactive && !cs.isActive())
      this._updatechatscallback.schedule();
  }

  _gotDeleteMessage(msg) {
    //FIXME other chat channels should support it too...
    this._conversations.get("groupchat")?._processDeleteMessage(msg);
    this._conversations.get("questions")?._processDeleteMessage(msg);
  }

  _gotBanVisitor(msg) {
    const cs = this._conversations.get("groupchat"); //FIXME other chat channels should support it too...
    if (cs)
      cs._processBanVisitor(msg);
  }

  _gotUnbanVisitor(msg) {
    const cs = this._conversations.get("groupchat"); //FIXME other chat channels should support it too...
    if (cs)
      cs._processUnbanVisitor(msg);
  }

  _processRoomState(update?) {
    if (update?.state?.cues)
      this.emit("cueupdate", { cues: update.state.cues });

    if (update?.state?.mode) //chatroom updates...
    {
      //legacy modes
      if (this.groupchat)
        this.groupchat._processRoomState(update.state);
      else if (this.questions)
        this.questions._processRoomState(update.state);
      // else
      // console.log("where to send this _processRoomState?", update);

      this._state.chatmode = update.state.mode;
    }
    this._finalizeRoomState();
  }

  _finalizeRoomState() {
    this._state.available = this.available;
    this.emit("state", this._state);
  }

  _processRoomStats(update) {
    this.emit("stats", update.stats);
  }

  _processRoomConfig(configupdate) {
    if (!configupdate.roomconfig) {
      /* This is a 'modern' config update and requires some rebuilding... some state is only received incrementaly

         The client expects a full configuration like this:
            { teams:  [ { id: 'team-a'
                         , title: 'A-team'
                         , members: [ { publicid: "A^ALIAS-hannibal", screenname: "USER-hannibal", userstatus: { canaccept: true } }
                                    , { publicid: "A^ALIAS-murdock", screenname: "USER-murdock", userstatus: { canaccept: false } }
                                      ]
                         }
                       , { id: 'team-b'
                         , title: 'B-team'
                         , members: []
                         }
                       ]
            , memberofteams: [ "team-a" ]
            }
        }
      ], await hannibal_queue.pop(), "hannibal should be available");
      */

      if (!this.roomconfig)
        this.roomconfig = { teams: [] };

      //TODO don't transmit a full roomconfig to the client but let it access an API on our side, then we can retain teams as a Map() etc
      if (configupdate.teams) //a new team list. note that this will also transmit a full agent list
      {
        this.roomconfig.teams = configupdate.teams.map(team => ({ ...team, members: [] }));
        for (const agent of configupdate.agents)
          for (const teamid of agent.teams) {
            const team = this.roomconfig.teams.find(_ => _.id == teamid);
            // console.log(team, teamid, this.roomconfig.teams, configupdate.teams);
            if (!team.members.find(_ => _.id == agent.publicid))
              team.members.push({ publicid: agent.publicid, screenname: agent.screenname, userstatus: agent.userstatus });
          }
      } else {
        if (configupdate.agents)
          configupdate.agents.forEach(agent => {
            for (const team of this.roomconfig.teams) {
              const memberidx = team.members.findIndex(_ => _.publicid == agent.publicid);
              if (memberidx != -1) //if member, remove
                team.members.splice(memberidx, 1);

              if (agent.teams.some(_ => _ == team.id)) //if should be a member, readd, updating screenname and status
                team.members.push({ publicid: agent.publicid, screenname: agent.screenname, userstatus: agent.userstatus });
            }
          });
      }

      for (const team of this.roomconfig.teams)
        team.members.sort((lhs, rhs) => lhs.publicid.localeCompare(rhs.publicid));

      this.roomconfig.memberofteams = this.roomconfig.teams.filter(team => team.members.find(member => member.publicid == this.publicid)).map(team => team.id);
    } else {
      this.roomconfig = configupdate.roomconfig; //legacy format
    }

    this.emit("roomconfig", { roomconfig: this.roomconfig });
  }

  _processUserStatus(update) {
    this._userstatus = update.userstatus;
    this._responsivenesscheckstate = update.responsivenesscheckstate;
    this.emit("userstatus", { userstatus: this._userstatus, responsivenesscheckstate: this._responsivenesscheckstate });
  }

  _requestPrivateChat() {
    //Someone wants to contact us over private chat.
    this._client?._context._requestPrivateChat();
  }

  _updateChats() {
    const conversations = this.getConversations();
    this.emit("chats", { conversations });
    //do we still have a reason to connect?
    if (!this._mustconnect && this._cpconnection && !conversations.some(_ => _.isActive()))
      this._doDisconnect();
  }

  _addConversation(conversation: Conversation) {
    this._conversations.set(conversation._conversationid, conversation);
    this._updatechatscallback.schedule();
  }

  _removeConversation(conversation: Conversation) {
    this._conversations.delete(conversation._conversationid);
    this._updatechatscallback.schedule();
  }

  _doDisconnect() //releaes the connection, temporarily (no active chats) or permanently
  {
    if (this._cpconnection) {
      this._cpconnection.disconnect();
      this._cpconnection = null;
    }
  }
  async disconnect() {
    this._permanentdisconnect = true;
    if (this._cpconnection)
      await this._cpconnection.disconnect();
  }

  /** Send feedback to a broadcast */
  async sendFeedback(data) {
    if (this._permanentdisconnect)
      throw new Error("We are already disconnected");
    if (this._chatinfo.roomtype != 'broadcast')
      throw new Error(`Feedback is only accepted on broadcast channels`);
    return await this._cpconnection?.request(data);
  }

  findPeer(peerid: string) {
    for (const conversation of this._conversations) {
      //TODO convos should maintain a mapping of peers for efficiency and they need it anyway for faster chatplanes (not retransmitting known visitors all the time)
      for (let pos = conversation[1].history.length - 1; pos >= 0; --pos) {
        if (conversation[1].history[pos].publicid == peerid)
          return { publicid: conversation[1].history[pos].publicid, ...conversation[1].history[pos].visitor };
      }
    }
    return null;
  }

  /** Request a chat and return the session.
      @param peer Peer visitor record to chat with
      @param peer.publicid Public id of user to connect to
      @param teamid Team to connect to
      @param agentdata Agent data
      @return The new session. */
  createConversation({ peer, teamid, agentdata = null } = {}) {
    if (this._permanentdisconnect)
      throw new Error("We are already disconnected");

    const opts = { teamid, peer, roomtype: 'privatechat', agentdata };
    const cs = new Conversation(this, null, opts);
    this.emit("chat", { conversation: cs });
    return cs;
  }

  async setUserStatus({ ...newstatus }) {
    if (!this._isagent)
      throw new Error(`Only agents can set a user status`);

    await this._connectChatPlane();
    this._userstatus = newstatus;

    const msg = { type: "setuserstatus", userstatus: newstatus };
    await this._cpconnection?.request(msg);

    this.emit("userstatus", this._userstatus);
  }

  async setConnectionStatus({ ...newstatus }) {
    if (!this._isagent)
      throw new Error(`Only agents can set a connection status`);

    await this._connectChatPlane();
    this._connectionstatus = newstatus;

    const msg = { type: "setconnectionstatus", connectionstatus: newstatus };
    await this._cpconnection?.request(msg);
  }

  async markResponsive() {
    await this._cpconnection?.request({ type: "markresponsive" });
  }

  //AGENT api - mostly for testing - FIXME remove, testesrs should use initClient
  getAgentByName(screenname: string) {
    if (!this.roomconfig)
      return null;

    for (const team of this.roomconfig.teams) {
      const member = team.members.find(_ => _.screenname == screenname);
      if (member)
        return member;
    }
    return null;
  }
  // FIXME remove, testesrs should use initClient
  getTeam(id: string) {
    return this.roomconfig?.teams.find(_ => _.id == id);
  }
}
