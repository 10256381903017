/* eslint-disable */
/// @ts-nocheck -- Bulk rename to enable TypeScript validation

import EventSource from '../util/eventsource';
import * as tokens from '../util/tokens';
import twitter from "twitter-text";
import type ChatPlaneConnector from '../chatplaneconnector';

export function parseMessage(intext: string) {
  //TODO: add a confirmation to the tests that we're not preprocessed
  if (intext[0] == '/')
    return [intext]; //no preprocessing

  const parts = [];
  let startpos = 0;
  const twitterparts = twitter.extractUrlsWithIndices(intext);
  if (twitterparts.length == 0)
    return [intext];

  console.log("extractUrlsWithIndices '%s' result: %o", intext, twitterparts);
  for (const part of twitterparts) {
    if (startpos < part.indices[0])
      parts.push(intext.substr(startpos, part.indices[0] - startpos));

    parts.push({ a: intext.substr(part.indices[0], part.indices[1] - part.indices[0]) });
    startpos = part.indices[1];
  }
  if (startpos < intext.length)
    parts.push(intext.substr(startpos, intext.length));

  return parts;
}

interface ConversationBaseOptions {
  archiveparts?;
  baseurl?: string;
  onfetchjson?: (url: string) => Promise<object>;
  roomtype?;
  announceincoming?: boolean;
}

//splitting off for use by archivedconversation
export class ConversationBase extends EventSource {
  _agentdata;
  _announceincoming;
  _archiveparts;
  _baseurl;
  _closed;
  _conversationid;
  _emittedmessage?: boolean;
  _lastread;
  _onfetchjson;
  _participants;
  _roomstate;
  _roomtype;
  _team;
  _transferred?;
  _unanswered?;
  currentrange?: { from?: number | null; until?: number | null };
  history;
  loadedarchive?;
  loadedarchiveparts?;
  peervisitor;
  visitorid?: string;

  constructor(conversationid: string, options?: ConversationBaseOptions) {
    super();
    this._archiveparts = options?.archiveparts ?? [];
    this._baseurl = options?.baseurl;
    this._onfetchjson = options?.onfetchjson;
    this._conversationid = conversationid;
    this._roomstate = null;
    this._roomtype = options?.roomtype || 'groupchat';
    this._participants = [];
    this._team = null;
    this._agentdata = null;
    this._announceincoming = Boolean(options?.announceincoming);
    this._lastread = "";
    this._closed = false;
    this.history = [];
    this.peervisitor = null; //The visitor to show in the conversation bubble
  }

  get chatmode() {
    return this._transferred ? "transferred" : this._closed ? "closed" : this._conversationid ? "open" : "new";
  }

  isActive() {
    return this._conversationid && !this._transferred && !this._closed;
  }

  get unread() {
    //count messages since the last read
    return this.history.filter(_ => _.type == 'msg' && !_.mine && _.createdat > this._lastread).length;
  }
  get unanswered() {
    return this._unanswered;
  }
  get roomstate() {
    return this._roomstate;
  }
  get id() {
    return this._conversationid;
  }
  get participants() {
    return this._participants;
  }
  get team() {
    return this._team;
  }
  get agentdata() {
    return this._agentdata;
  }

  getHistory() {
    if (!this.currentrange)
      return this.history; //no range set yet

    return this.history.filter(_ => Date.parse(_.createdat) >= this.currentrange.from && (this.currentrange.until === null || Date.parse(_.createdat) < this.currentrange.until));
  }

  _processRoomState(roomstate) {
    this._roomstate = roomstate;
    this.emit("roomstate", this._roomstate);
  }

  getMessageById(messageid: string) {
    return this.history.find(_ => _.id == messageid);
  }

  getPreviousMessageForId(messageid: string) {
    const idx = this.history.findIndex(_ => _.id == messageid);
    if (idx > 0)
      return this.history[idx - 1];
  }

  getMessageReplies(msgid: string) {
    return this.history.filter(_ => _.inreplyto == msgid);
  }

  // Clear unread indicator
  clearUnread() {
    //Find the most recent message (they should be sorted by createdat) and take that as our lastread
    this._lastread = [...this.history].reverse().find(_ => _.createdat)?.createdat ?? "";
  }

  async _loadArchiveParts(partlist: Array<{ messagestartdate: number; messagelimitdate: number | null; lastupdate; url: string }>) {
    this.history = [];
    const promises = [];
    for (const part of partlist)
      promises.push(this._loadArchivePart(part));
    await Promise.all(promises);
  }

  async _loadArchivePart({ messagestartdate, messagelimitdate, lastupdate, url }: { messagestartdate: number; messagelimitdate: number | null; lastupdate; url: string }) {
    if (!this._baseurl)
      throw new Error("Archive unavailable, not initialized with a baseurl");

    const fetchurl = new URL(url, this._baseurl);
    if (lastupdate)
      fetchurl.searchParams.set('v', lastupdate);

    const partdata = await this._onfetchjson(fetchurl.toString());

    this.history =
      [
        ...this.history.filter(message => (Date.parse(message.createdat) < messagestartdate)),
        ...partdata.messages.filter(msg => this._preprocessMessage(msg)),
        ...(messagelimitdate !== null ? this.history.filter(message => (Date.parse(message.createdat) > messagelimitdate)) : [])
      ];
  }

  async setReplayRange(from?: null | string | number | Date, until?: null | string | number | Date) {
    from = from === null || from === undefined ? null : typeof from == "string" ? Date.parse(from) : Number(from) || 0;
    until = until === null || until === undefined ? null : typeof until == "string" ? Date.parse(until) : Number(until) || 0;

    let playfrom: null | number | undefined = from;

    if (this._archiveparts.length && !this.loadedarchive && !this.loadedarchiveparts) {
      await this._loadArchiveParts(this._archiveparts);
      this.loadedarchiveparts = true;
    }

    //If we're not continuing from the last point, clear the chat
    if (!this.currentrange ||
      (this.currentrange.from !== from ||
        (until != null && (this.currentrange.until == null || this.currentrange.until > until)))) {
      this.emit("clearallmessages");
    } else if (this.currentrange) {
      playfrom = this.currentrange.until;
    }

    this.emit("roomstate", this._roomstate);
    this.currentrange = { from, until };

    //FIXME are we sure we want to actually emit the messages 1-by-1 for setReplayRange ?
    for (const message of this.getMessages(playfrom, until))
      this._emitMessage(message);
  }

  _enrichMessage(msg) {
    //FIXME do we test/are able to properly return 'mine' ? arent we watching archives anonymously often?
    //TODO should we be storing this in history or just throw this in where we also add 'fromarchive' above?
    return ({ ...msg, mine: msg.type == "msg" && msg.visitor.visitorid === this.visitorid });
  }

  _emitMessage(msg) {
    if (this.currentrange) {
      // ignore all messages of type 'msg' when outside or replay range
      // still want bans, deleted messages etc to go through
      if (msg.type == "msg" || msg.type == "servermsg") {
        const date = Date.parse(msg.createdat);
        if (this.currentrange.from != null && date < this.currentrange.from)
          return;
        if (this.currentrange.until != null && date >= this.currentrange.until)
          return;
      }
    }

    this._emittedmessage = true;
    this.emit("message", msg);
  }

  *getMessages(from: number, until: number | null) {
    for (const message of this.history) {
      const date = Date.parse(message.createdat);
      if (date >= from && (until === null || date < until))
        yield this._enrichMessage(message);
    }
  }
}

interface ConversationOptions extends ConversationBaseOptions {
  teamid?: string;
  peer?;
  agentdata?;
  unanswered?: boolean; // FIXME: doesn't seem to be used
}

export default class Conversation extends ConversationBase {
  _client;
  _straighttoarchive;
  _teamid;
  _peer;
  _initialagentdata;
  _istyping;

  constructor(client: ChatPlaneConnector, conversationid: string, options?: ConversationOptions) {
    if (client && !options?.baseurl)
      options = { ...options, baseurl: client._client?._baseurl };

    super(conversationid, options);
    this._client = client; //NOTE 'client' is a ChatPlaneConnector
    this._straighttoarchive = false; //used for tests to verify whether we took the straigh-to-cdn route (skipping chatplanes)
    this._teamid = options?.teamid ?? null;
    this._peer = options?.peer ?? null;
    this._initialagentdata = options?.agentdata ?? null;
    this._istyping = false;

    const initialopen = !['groupchat', 'questions'].includes(conversationid);
    this._roomstate = { mode: initialopen ? 'open' : 'closed' };

    let hasself = true;
    if (options?.updatemsg) {
      this._processConversationUpdate(options.updatemsg);
      hasself = this._participants.some(p => p.isself);
    }

    if (this._conversationid && hasself)
      this._client._addConversation(this);

    this.updateCachedState();
  }

  clearUnread() {
    super.clearUnread();
    this.updateCachedState();
  }

  _spokenSinceLastTransfer() {
    for (let i = this.history.length - 1; i >= 0; --i) {
      if (this.history[i].mine && !this.history[i].audience?.length)
        return true;
      if (this.history[i].type == 'servermsg' && this.history[i].servermsg.type == 'transfer-conversation')
        return false;
    }
    return false;
  }

  updateCachedState() {
    ///recalculate unanswered state
    this._unanswered = Boolean(!this._transferred
      && !this._closed
      && this.history.length //there must be traffic
      && !this._spokenSinceLastTransfer());

    if (this._conversationid) {
      const current = this._client._storage.get('conversation.' + this._conversationid);
      const update = { lastread: this._lastread, unanswered: this._unanswered, active: this._participants.length > 1 };
      const storageupdate = !current || current.lastread != update.lastread || current.unanswered != update.unanswered || current.active != update.active;
      if (storageupdate) {
        this._client._storage.update('conversation.' + this._conversationid, update);
        //TODO perhaps the .update should trigger the event and feed that back into our emitters so we can work cross-tab
      }
    }

    if (this._announceincoming) {
      this._client.emit("incomingchat", { conversation: this });
      this._announceincoming = false;
    }

    this.emit("updateconversation", { conversation: this });
    this._client.emit("updateconversation", { conversation: this });
  }

  get chatplaneconnector() //FIXME should we expose this ? an ArchivedConversation cannot offer this
  {
    return this._client;
  }

  async _sendIntoConversation(msg) {
    await this._client._connectChatPlane();
    await this._client._cpconnection.getSetChannelResult(); //ensure connection is complete

    if (!this._conversationid) {
      this._conversationid = 'C^' + (await tokens.generateUniqueId());
      this._client._addConversation(this);
    }
    msg.conversationid = this._conversationid;

    //TODO move timeout management to chatplane client. and find a way to cancel outgoing messages if still possbile in socketio?
    const timeoutpromise = new Promise((resolve, reject) => setTimeout(() => reject(new Error("timeout")), 6000));
    return await Promise.race([this._client._cpconnection.request(msg), timeoutpromise]);
  }

  /** @cell option.audience List of groups to restrict this message to. '@agent', '@moderator', empty for all
  */
  async sendMessage(message, options) {
    const msg: any = {
      type: "msg",
      text: message,
      msg: parseMessage(message)
    };
    if (options && options.inreplyto)
      msg.inreplyto = options.inreplyto;
    if (this._teamid)
      msg.initialteam = this._teamid;
    if (this._peer)
      msg.initialpeer = this._peer;
    if (this._initialagentdata)
      msg.agentdata = this._initialagentdata;
    if (options?.audience?.length)
      msg.audience = options.audience;

    // 'sendmessage' event is usually used for analytics. this is not a socketio send!
    this._istyping = false;
    this._client.emit("sendmessage", { conversation: this, message, inreplyto: msg.inreplyto || null });

    await this._processMessageResponse(await this._sendIntoConversation(msg));
    if (this._unanswered && !options?.audience?.length) //if the message was limited it shouldn't clear _unanswered
      this.updateCachedState(); //will clear _unanswered
  }

  //moderator actions
  async deleteMessage(id: string) {
    if (!id)
      throw new Error("Missing message id");

    //FIXME timeout handling, just like sendMessage
    await this._client._cpconnection.request({ type: "delete-msg", id: id });
    //if the above didn't throw, assume deletion was succesful
    //NOTE we'll get it bounced back from the server, no need to execute locally yet! this._processDeleteMessage({id:id});
  }
  async banVisitor(publicid: string) {
    if (!publicid)
      throw new Error("Missing visitor publicid");

    //FIXME timeout handling, just like sendMessage
    return await this._client._cpconnection?.request({ type: "ban-visitor", id: publicid });
  }
  async unbanVisitor(publicid: string) {
    if (!publicid)
      throw new Error("Missing visitor publicid");

    //FIXME timeout handling, just like sendMessage
    return await this._client._cpconnection?.request({ type: "unban-visitor", id: publicid });
  }
  async setIsTyping(istyping: boolean) {
    if (this._istyping !== istyping) {
      this._istyping = istyping;
      await this._client._cpconnection?.request({ type: "istyping", conversationid: this._conversationid, istyping: this._istyping });
    }
  }

  _handleRequestPrivateChatResponse(response) {
    console.error("_handleRequestPrivateChatResponse", response);
  }

  _processMessageResponse(response) //this is used to specifically enrich direct responses (they don't contain info we already have)
  {
    if (!response.type)
      return; //no real data in confirmation

    if (response.type == "msg") //we're receiving an echo of our message. enrich with visitorinfo and we can 'normally' process it
    {
      response.publicid = this._client.publicid;
      response.visitor = {
        screenflags: this._client.screenflags,
        visitorid: this._client.__visitorid__ || "",
        screenname: this._client.screenname
      };
    }

    this._processMessage(response);
  }

  _preprocessMessage(msg) {
    if (msg.type == "msg") //enrich and normalize messages so they structurally match _processMessageResponse
    {
      msg.mine = msg.publicid === this._client.publicid;
      if (msg.visitor && !msg.visitor.screenflags)
        msg.visitor.screenflags = [];
    }
    return msg;
  }

  _processMessage(msg) {
    msg = this._preprocessMessage(msg);

    //If we don't have a peer yet, just take whatever the first message with one has. This allows 'Your server' to be a counterparty
    if (this._roomtype == 'privatechat' && msg.type == 'msg' && !msg.mine && !this.peervisitor && msg.visitor)
      this.peervisitor = msg.visitor;

    if (msg.inreplyto) {
      const replytomsg = this.history.find(_ => _.id == msg.inreplyto);
      if (replytomsg && !replytomsg.replied) {
        replytomsg.replied = true;
        this._emitMessage(replytomsg); //locally re-emit the message if needed
      }
    }

    const existingitem = this.history.findIndex(_ => _.id == msg.id);
    if (existingitem >= 0)
      this.history.splice(existingitem, 1);

    this.history.push(msg);
    this._emitMessage(msg);
    this._client._client?.emit("message", { conversation: this, isupdate: existingitem >= 0, ...msg });
  }

  _processConversationUpdate(msg) {
    const wasmember = this._participants.some(e => e.isself);
    let ismember = wasmember;

    if (msg.participants) //contains participant info
    {
      if (this._roomtype == 'privatechat') {
        const notme = msg.participants.filter(e => !e.isself);
        if (notme.length == 1) //got exactly one peer
        {
          this.peervisitor = {
            screenname: notme[0].screenname,
            screenflags: notme[0].screenflags
          };
        }
      }
      ismember = msg.participants.find(e => e.isself);
      this._participants = msg.participants;
    }
    if ("team" in msg)
      this._team = msg.team;
    if ("agentdata" in msg)
      this._agentdata = msg.agentdata;

    if (msg.history) {
      if (this._emittedmessage) {
        this.emit("clearallmessages");
        this._emittedmessage = false;
      }
      this.history = [];
      msg.history.forEach(msg => this._processMessage(msg));
    }
    if (msg.closed)
      this._closed = true;

    if (wasmember && !ismember) {
      this._transferred = true;
    } else if (ismember && !wasmember) {
      this._unanswered = true;
      this._transferred = false;
    }

    this.updateCachedState();
  }

  _processDeleteMessage(msg) {
    // this.history.push(msg); //FIXME wipe the message from history, and test that
    this.history = this.history.filter(_ => _.id != msg.id);
    this.emit("deletemessage", msg);
  }

  _processBanVisitor(msg) {
    this.emit("banvisitor", msg);
  }

  _processUnbanVisitor(msg) {
    this.emit("unbanvisitor", msg);
  }

  _channelConnected(channelresult) {
    this._processRoomState(channelresult.state);
    channelresult.history.forEach(msg => this._processMessage(msg));
  }

  /** @param id Message id
      @param vote 'like' */
  async upvote(id: string, vote: string) {
    //TODO perhaps upvotes will eventually be a Conversation thing (agreeing with chat messages)
    //FIXME should we send an event like sendmessage does??

    const existing = this.getMessageById(id);
    if (!existing)
      throw new Error(`Cannot find question ` + id);

    if (this._roomtype != 'questions')
      throw new Error(`This is not a questions room`);

    const msg = { type: "vote", id, vote };
    const response = await this._sendIntoConversation(msg);
    existing.votes = response.votes;
    existing.myvote = vote;
    this.emit("message", existing);
  }

  async transferTo({ teamid, agentid }: { teamid: string; agentid: string }) {
    if (this._roomtype != 'privatechat')
      throw new Error(`This is not a 'privatechat' conversation`);
    if (typeof teamid != 'string')
      throw new Error(`Invalid teamid`);
    if (agentid !== undefined && typeof agentid != 'string')
      throw new Error(`Invalid teamid`);

    const msg = { type: "transfer-conversation", teamid, agentid };
    await this._sendIntoConversation(msg);
  }

  //permanently end the chat.
  async closeConversation() {
    const msg = { type: "close-conversation" };
    await this._sendIntoConversation(msg);
  }

  _processUpdate(msg) {
    const existing = this.getMessageById(msg.id);
    if (existing && msg.votes) {
      existing.votes = msg.votes;
      this.emit("message", existing);
    }
  }
}

interface ArchivedConversationOptions extends ConversationBaseOptions {
  replayfrom?: number;
  replayuntil?: number;
}

/* FIXME little difference, consider merging it back completely with the conversation code. > */
export class ArchivedConversation extends ConversationBase {
  _straighttoarchive;
  _chatinfo;
  initialreplay: { from?: number; until?: number };
  _onfetchjson: (url: string) => Promise<object>;

  constructor(conversationid: string, chatinfo: { archive: string }, onfetchjson: (url: string) => Promise<object>, options?: ArchivedConversationOptions) {
    super(conversationid, options);
    this._roomstate = { mode: 'archived' };
    this._straighttoarchive = true; //used for tests to verify whether we took the straigh-to-cdn route (skipping chatplanes)
    this._onfetchjson = onfetchjson;
    this._chatinfo = chatinfo;
    this.initialreplay = { from: options?.replayfrom, until: options?.replayuntil };
    this._asyncInit(chatinfo);
  }
  async _asyncInit(chatinfo: { archive: string }) {
    await this.loadArchive(chatinfo.archive);
    this.setReplayRange(this.initialreplay.from, this.initialreplay.until);
  }
  async loadArchive(archiveurl: string) {
    archiveurl = new URL(archiveurl, this._baseurl).toString();

    this.history = [];
    this.history = (await this._onfetchjson(archiveurl)).messages;
  }
}
