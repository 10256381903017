/* eslint-disable */
/// @ts-nocheck -- Bulk rename to enable TypeScript validation

import { equalJSONObjects } from './util/cueparsing';

function timeboxSubCues(cues, parentstart) {
  return cues.map(cue => ({
    ...cue,
    start: parentstart + (cue.start || 0),
    playat: parentstart + (cue.start || 0)
  }));
}

//flatten cues inside videos, fixing their time offsets
function getFlatCues(cues) {
  cues = fixCues(cues);
  for (const topitem of cues) {
    if (topitem.cues && topitem.cues.length)
      cues.push(...timeboxSubCues(fixCues(topitem.cues), topitem.playat || topitem.start));
  }
  return cues;
}

//convert ISO timestamps
function fixCues(cues) {
  return cues.map(cue => ({
    ...cue,
    start: cue.start ? new Date(cue.start).getTime() : null,
    playat: (cue.playat || cue.start) ? new Date(cue.playat || cue.start).getTime() : null,
    stop: cue.stop ? new Date(cue.stop).getTime() : null
  }));
}

function prepareExternalCues(mutations) {
  return mutations.map(mutation => ({
    ...mutation,
    mgrstate: null //don't leak our state
  }));
}

export default class CueManager {
  constructor(oncue) {
    this.oncue = oncue;
    this.timecallback = null;

    this._activecues = {};

    //the last submitted list of cues, may contain past, present or future cues.
    this._watchedcues = [];
    this._cuetimeoutid = 0;
  }

  update(setcues) {
    this._watchedcues = fixCues(getFlatCues(setcues));
    this._checkActiveCues();
  }

  _getCurrentCues() {
    //*we* need to reason in server time, as that is the same for all clients
    const now = this.timecallback ? this.timecallback() : Date.now();
    const ourdrift = this.timecallback ? Date.now() - now : 0; //if Date.now > now, we are ahead of the server
    const nexts = [];
    const cues = [];

    for (let cue of this._watchedcues) {
      if (cue.start > now) //future cue!
      {
        nexts.push(cue.start);
        continue; //nothing we can do with this cue yet
      }

      if (cue.playat > now) {
        nexts.push(cue.playat);
      }

      if (cue.duration_ms) {
        const stop = cue.playat + cue.duration_ms;
        if (stop <= now)
          continue; //cue is over, don't even add

        nexts.push(stop);
      }

      //we expect our callers to be using Date.now, so playat and start will be in that frame of reference
      cue = {
        ...cue,
        playat_server: cue.playat,
        start_server: cue.start,
        playat: cue.playat + ourdrift,
        start: cue.start + ourdrift,
        mgrstate: { upcoming: cue.playat > now }
      };
      cues.push(cue);
    }

    return {
      now,
      next: nexts.length ? Math.min(...nexts) : 0,
      cues
    };
  }

  _cueTimeoutCheck() {
    this._cuetimeoutid = 0;
    this._checkActiveCues();
  }

  //schedule or clear the next cuetimeout check
  _scheduleNextCueCheck(current) {
    //Reset current time, apply new one
    if (this._cuetimeoutid) {
      clearTimeout(this._cuetimeoutid);
      this._cuetimeoutid = 0;
    }
    if (current.next) {
      this._cuetimeoutid = setTimeout(() => this._cueTimeoutCheck(), current.next - current.now);
    }
  }

  //check if there activecues. invoked by cutTimeoutCheck or when we are externally invoked!
  _checkActiveCues() {
    const current = this._getCurrentCues();
    this._scheduleNextCueCheck(current);

    const mutations = [];
    const seencues = [];

    console.log("player:cuemanager", "current cues", current.cues, JSON.stringify(current.cues));
    //add and update keys from the new set
    for (const cue of current.cues) {
      const cuekey = cue.cuetype + (cue.instance ? "$" + cue.instance : ""); //TODO instance ids? which cues have more than one of a type ?
      seencues.push(cuekey);

      //consider only the data. a change to eg 'start' caused by ondemand's recalibration shouldnt trigger a change
      if (this._activecues[cuekey]
        && this._activecues[cuekey].mgrstate.upcoming == cue.mgrstate.upcoming
        && equalJSONObjects(cue.data, this._activecues[cuekey].data)) {
        console.log("ignoring non-update", cue, this._activecues[cuekey]);
        continue;
      }

      mutations.push({ event: cue.mgrstate.upcoming ? "cue-upcoming" : "cue-start", cue });
      this._activecues[cuekey] = cue;
    }

    //detect missed. do 'ends' before starts
    const deletedcuekeys = Object.keys(this._activecues).filter(cuekey => !seencues.includes(cuekey));
    for (const cuekey of deletedcuekeys) {
      mutations.unshift({ event: "cue-end", cue: this._activecues[cuekey] });
      delete this._activecues[cuekey];
    }

    prepareExternalCues(mutations).forEach(mut => this.oncue(mut));
  }

  getCurrentCues() {
    return prepareExternalCues(this._getCurrentCues().cues);
  }
}
