/* eslint-disable */
/// @ts-nocheck -- Bulk rename to enable TypeScript validation

import CueEventSource from "./util/cueeventsource";
import ChatPlaneConnector from "./chatplaneconnector";
import ChatPlaneConnection from './cpconnect/cpconnection';
import StorageClient from './util/storageclient';
import { createDeferred } from './util/utils';
import type ChatPlaneContext from './chatplanecontext';
import type CueManager from "cuemanager";

export type ChatPlaneClientOptions = {
  visitortoken?: string;
  storagescope?: string;
  replayfrom?: unknown;
  replayuntil?: unknown;
};

/* A ChatPlaneClient wraps the 'connection' to the chatplane and arranges the ChatPlaneConnector on-demand connections */

export default class ChatPlaneClient extends CueEventSource {
  _options;
  _context;
  _chatplaneInitPromise;
  _chatplaneconnector?: ChatPlaneConnector;
  _baseurl?: string;
  _chatinfo?;
  _isbroadcast?: boolean;
  _cuemanager?: CueManager;
  _groupchat?;
  _questions?;

  constructor(context: ChatPlaneContext, options?: ChatPlaneClientOptions) {
    super();
    this._options = {
      visitortoken: "",
      ...options
    };
    this._context = context;
    this._chatplaneInitPromise = createDeferred<null>();
  }
  get context() {
    return this._context;
  }
  get roomconfig() {
    return this._chatplaneconnector?.roomconfig ?? null;
  }

  async _asyncInit(url: string) {
    this._baseurl = url;
    this._chatinfo = await this.context.fetchJSON(url);
    return await this._setupForChatinfo();
  }

  async _setupForChatinfo() {
    if (this._chatinfo.roomtype == 'chatcontrol'
      || this._chatinfo.broadcastio //broadcastio is the legacy broadcast channel
      || (['groupchat', 'questions'].includes(this._chatinfo.roomtype) && this._chatinfo.mode == 'online')) {
      const chatplaneopts = {
        visitortoken: this._options.visitortoken,
        onfetchjson: (url: string) => this.context.fetchJSON(url),
        archiveparts: this._chatinfo.archiveparts,
        ...ChatPlaneConnection.pickConnectionOptions(this._options)
      };

      if (this._options.storagescope) {
        console.error("storagescope should be set at the context level (where you set the visitor!)");
        chatplaneopts.storage = new StorageClient(this._options.storagescope);
      }

      this._chatplaneconnector = new ChatPlaneConnector(this, this._chatinfo, chatplaneopts);

      this._chatplaneconnector.on("incomingchat", conversation => this._handleIncomingConversation(conversation));
      this._chatplaneconnector.on("cueupdate", cues => this._gotDynamicCuesUpdate(cues));
      this._chatplaneconnector.on("stats", stats => this._gotStats(stats));
      this._chatplaneconnector.on("state", state => this._gotState(state));
      this._chatplaneconnector.on("chats", chats => this._gotChats(chats)); // for ongoing conversations
      this._chatplaneconnector.on("chat", chat => this._gotChat(chat)); // for new, currently unconnected conversations
      this._chatplaneconnector.on("userstatus", data => this._gotUserStatus(data)); // for user status change in other concurrent connections
      this._chatplaneconnector.on("roomconfig", data => this._gotRoomConfig(data)); // for room config changes (teams, memberofteams)
      this._chatplaneconnector.on("sendmessage", msg => this.emit("sendmessage", msg));
      this._chatplaneconnector.on("updateconversation", data => this.emit("updateconversation", data));

      if (this._chatinfo.roomtype == 'chatcontrol')
        this._scheduleNextPoll();

      //TODO should this be done by the chatplane on init? or should we have a pre-connect hook (ie before stats is tranmistted to final users) to inject these settings?
      if (this._chatinfo.roomtype == 'groupchat') {
        this.openConversation('groupchat').then(groupchat => groupchat.setReplayRange(this._options.replayfrom, this._options.replayuntil));
      }

      if (this._chatinfo.broadcastio) {
        this._isbroadcast = true;
        this._cuemanager.timecallback = () => this.getLiveNow();
      }
      this._chatplaneInitPromise.resolve(null);
    }
  }

  _scheduleNextPoll() {
    let frequency = this._chatinfo.pollfrequency ?? 15000;

    //kepe in range 0.5sec .. 5 minutes
    if (frequency < 500)
      frequency = 500;
    else if (frequency > 300000)
      frequency = 300000;

    setTimeout(() => this._doNextPoll(), frequency);
  }

  async _doNextPoll() {
    if (!this._chatplaneconnector)
      return; //disconnect()ed

    try {
      this._chatinfo = await this.context.fetchJSON(this._baseurl as string);

      if (this._chatplaneconnector) //it's still there!
        this._chatplaneconnector.updateChatInfo(this._chatinfo);
    } catch (e) {
      console.log("Failed to poll for new chatinfo", e);
    }
    this._scheduleNextPoll(); //schedule the poll right away,
  }

  async openConversation(id: string) {
    //FIXME proper poller.. need to wait for chatplaneclient to open and then wait for the chat to appear
    for (; ;) {
      let conversation;
      if (id === 'groupchat')
        conversation = this._chatplaneconnector?.groupchat ?? this._groupchat;
      else if (id === 'questions')
        conversation = this._chatplaneconnector?.questions ?? this._questions;
      else
        throw new Error(`Waiting for '${id}' not yet supported`); //TODO what's the use case until we have multi-track chat rooms  ?

      if (conversation)
        return conversation;

      await new Promise(resolve => setTimeout(resolve, 100));
    }
  }

  /** @param teamid ID of specific team we want to talk with
      @param peerid Public id of user to connect to
  */
  async createConversation({ peerid, teamid, agentdata = null }: { peerid?: string; teamid?: string; agentdata?: unknown } = {}) {
    const exception = await this._chatplaneInitPromise.promise;
    if (exception) //we can't just reject the deferred promise..... as that triggers uncaught promise rerors
      throw new Error(exception);

    let requestpeer;
    if (peerid) {
      requestpeer = this._context.lookupPeerByPublicId(peerid);
      if (!requestpeer)
        throw new Error(`Peer '${peerid}' is not known to us`);
      if (!requestpeer.client._chatplaneconnector?.isConnected()) //TODO shouldn't we (re)connect? can this happen
        throw new Error(`We are not connected to peer client`);
    }

    const conversation = this._chatplaneconnector.createConversation({ peer: requestpeer?.peer, teamid, agentdata });
    if (requestpeer)
      requestpeer.client._chatplaneconnector._cpconnection.request({
        type: "requestprivatechat",
        publicid: peerid
      }).then(response => conversation._handleRequestPrivateChatResponse(response));
    return conversation;
  }

  _gotStats(stats) {
    //TODO store last stats and add an API to immediately query it?
    this.emit("stats", stats);
  }

  _gotState(state) {
    //TODO store last stats and add an API to immediately query it?
    setTimeout(() => this.emit("state", state)); //crude race fix - delay so we're sure that any attached chatbackend has had a chance to update its classes. priority order for listeners might have sufficed
  }

  _gotChats(chats) {
    this.emit("chats", chats);
  }

  _gotChat(chat) {
    this.emit("chat", chat);
  }

  _gotUserStatus(data) {
    this.emit("userstatus", data);
  }

  _gotRoomConfig(data) {
    this.emit("roomconfig", data);
  }

  _handleIncomingConversation({ conversation }) {
    this.emit("incomingchat", { conversation });
  }

  lookupPeerByPublicId(publicid: string) {
    return this._chatplaneconnector?.findPeer(publicid) ?? null;
  }

  //AGENT api - mostly for testing
  getAgentByName(screenname: string) {
    if (!this.roomconfig)
      return null;

    for (const team of this.roomconfig.teams) {
      const member = team.members.find(_ => _.screenname == screenname);
      if (member)
        return member;
    }
    return null;
  }
  getTeam(id: string) {
    return this.roomconfig.teams.find(_ => _.id == id);
  }
}
