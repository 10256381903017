/* eslint-disable */
/// @ts-nocheck -- Bulk rename to enable TypeScript validation

import * as dompack from 'dompack';
import * as tokens from '@mod-live_api/libliveapi/js//util/tokens';

import * as bindings from '../util/bindings';
import "./agentcontrol.scss";

export default class AgentControl extends bindings.BindableBase {
  constructor(livesiteclient, element, options) {
    super(livesiteclient, element);
    options = { ...options };
    this._openTeams = new Set();
    this._element.classList.add("whlive-agentcontrol", "whlive-chat--avatar-initials");


    //legacy classes, fix these
    let screenname = '';
    if (this._livesiteclient._options.visitortoken)
      screenname = tokens.parseVisitorToken(this._livesiteclient._options.visitortoken).screenname;

    this._nodes = {
      list: <div class="whlive-agentcontrol__list
                                      whlive-chat--theme-whlive
                                      whlive-chat--avatar-before-message
                                      whlive-chat--list-unread-counts"></div>,
      status: <div class="whlive-agentcontrol__status">
        <div class="whlive-agentcontrol__status-name">{screenname}</div>
        <div class="whlive-agentcontrol__status-status"></div>
      </div>,
      header: <div class="whlive-agentcontrol__header utlive--chat-theme">
        <div class="whlive-agentcontrol__header-avatar"></div>
        <div class="whlive-agentcontrol__header-name"></div>
        <div class="whlive-agentcontrol__header-title"></div>
      </div>,
      chats: <div class="whlive-agentcontrol__chats
                                        whlive-chat--theme-whlive
                                        whlive-chat--align-content-follow
                                        whlive-chat--avatar-before-message
                                        whlive-chat--avatar-outside-bubble
                                        whlive-chat--avatar-hide-mine"></div>,
      teams: <div class="whlive-agentcontrol__myteams"></div>
    };
    this._element.replaceChildren(this._nodes.list
      , this._nodes.header
      , this._nodes.chats
      , <div class="whlive-agentcontrol__teamscolumn">
        {this._nodes.status}
        {this._nodes.teams}
      </div>
    );

    this._listeners.push(this._livesiteclient.on("roomconfig", state => this._updateRoomConfig(state.roomconfig)));
    this._nodes.teams.addEventListener("toggle", event => this._toggleTeam(event));

    this._livesiteclient.waitOn("state").then(state => {
      const conversations = this._livesiteclient.bindConversationsElement(this._nodes.chats, { conversationlist: this._nodes.list, separatearchive: true, separateincoming: true });
      conversations.on("select", event => this._conversationOpened(this._nodes.header, event));

      this._livesiteclient.bindUserStatusElement(this._nodes.status.querySelector(".whlive-agentcontrol__status-status"));
    });
    this._listeners.push(this._livesiteclient.on("userstatus", () => this._statusUpdated(this._nodes.status)));
    this._statusUpdated(this._nodes.status);
    this._conversationOpened(this._nodes.header, {});
    if (this._livesiteclient.roomconfig) //already have room configuration
      this._updateRoomConfig(this._livesiteclient.roomconfig);
  }

  _unbindElement() {
    //FIXME shouldn't we be cleaning up our childnode bindings too ?
  }

  async _statusUpdated(node) {
    node.classList.toggle("isactive", this._livesiteclient.userstatus && this._livesiteclient.userstatus.canaccept);

    if (this._livesiteclient.responsivenesscheckstate == "waitresponse" || this._livesiteclient.responsivenesscheckstate == "unresponsive") {
      if (!this._responsivenesscheckdialogabort) {
        this._responsivenesscheckdialogabort = new AbortController;
        if (await this._livesiteclient.context._runCommonMessageBox("responsivenesscheck", { allowcancel: false, signal: this._responsivenesscheckdialogabort.signal }) == "yes")
          this._livesiteclient.markResponsive();

        this._responsivenesscheckdialogabort = null;
      }
    } else if (this._responsivenesscheckdialogabort) {
      this._responsivenesscheckdialogabort.abort();
    }
  }

  _toggleTeam(team, event) {
    if (event.target.open)
      this._openTeams.add(team.id);
    else
      this._openTeams.delete(team.id);
  }

  _renderTeam(team) {
    const members = team.members.sort((lhs, rhs) => lhs.screenname.localeCompare(rhs.screenname));
    const anyaccepting = members.some(_ => _.userstatus.canaccept);

    return <details class={{
      "whlive-agentcontrol__team": true,
      "whlive-agentcontrol__team--canaccept": anyaccepting
    }} onToggle={event => this._toggleTeam(team, event)} open={this._openTeams.has(team.id) ? "" : null}>
      <summary class={{ "whlive-agentcontrol__teamtitle": true }}>{team.title}</summary>
      <ul class="whlive-agentcontrol__teammembers">
        {members.map(member => <li class={{
          "whlive-agentcontrol__teammember": true,
          "whlive-agentcontrol__teammember--canaccept": member.userstatus.canaccept
        }}>{member.screenname}</li>)}
      </ul>
    </details>;
  }

  _updateRoomConfig(roomconfig) {
    const teams = roomconfig.teams.sort((lhs, rhs) => lhs.title.localeCompare(rhs.title))
      .forEach(team => team.ismemberof = roomconfig.memberofteams.includes(team.id));

    this._nodes.teams.replaceChildren( //who needs react...
      <h2>My teams</h2>
      , ...roomconfig.teams.filter(team => team.ismemberof)
        .map(team => this._renderTeam(team))
      , <h2>Other teams</h2>
      , ...roomconfig.teams.filter(team => !team.ismemberof)
        .map(team => this._renderTeam(team))

    );
  }

  _conversationOpened(headerNode, { conversation }) {
    const visitor = conversation?.participants.filter(visitor => !visitor.isself).shift();
    if (!visitor)
      headerNode.style.display = "none";
    else {
      const avatarNode = headerNode.querySelector(".whlive-agentcontrol__header-avatar");
      for (const className of [...avatarNode.classList.values()])
        if (className.startsWith("whlive-chat__screenflag-"))
          avatarNode.classList.remove(className);
      for (const flag of visitor.screenflags)
        if (flag.startsWith("color"))
          avatarNode.classList.add(`whlive-chat__screenflag-${flag}`);
      avatarNode.dataset.whliveInitials = this._livesiteclient.getScreenNameInitials(visitor.screenname);
      headerNode.querySelector(".whlive-agentcontrol__header-name").textContent = visitor.screenname;
      headerNode.querySelector(".whlive-agentcontrol__header-title").textContent = conversation.team?.title;
      headerNode.style.display = "";
    }
  }

}
